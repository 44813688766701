import * as React from "react";
import {Redirect, Route, RouteComponentProps, Switch} from "react-router";
import {Loader, Menu} from "semantic-ui-react";
import RouterMenuItem from "../../components/routerMenuItem";
import {Agency, AgencySettings, AuthAgency, ApiResult} from '@bryxinc/lunch/models';

import BryxApi from '@bryxinc/lunch/utils/ManagementApi';
import {GeneralTab} from "./generalTab";
import {ResponseOptionsTab} from "./responseOptionsTab";
import {StreamsTab} from "./streamsTab";

import {withContext, WithTranslation, WithLocal, WithApi} from '@bryxinc/lunch/context';

export interface AgencyProps extends RouteComponentProps, WithTranslation, WithLocal, WithApi<BryxApi> {
    selectedAgency: AuthAgency;
}

type AgencyPageStatus = "loading" | "ready";

interface AgencyState {
    status: AgencyPageStatus;
    activeAgency: Agency | null;
}

export class AgencyPage extends React.Component<AgencyProps, AgencyState> {
    constructor(props: AgencyProps) {
        super(props);
        this.state = AgencyPage.getInitialState();
    }

    static getInitialState(): AgencyState {
        return {
            status: "loading",
            activeAgency: null,
        };
    }

    componentDidMount() {
        this.loadAgency(this.props);
    }

    UNSAFE_componentWillReceiveProps(nextProps: Readonly<AgencyProps>) {
        if (this.props.selectedAgency.id != nextProps.selectedAgency.id) {
            this.setState(AgencyPage.getInitialState());
            this.loadAgency(nextProps);
        }
    }

    private loadAgency(props: AgencyProps) {
        this.setState({status: "loading"});
        this.props.api.getAgency(props.selectedAgency.id, (result: ApiResult<Agency>) => {
            if (result.success == true) {
                this.setState({
                    status: "ready",
                    activeAgency: result.value,
                });
            } else {
                this.props.local.logWarn(`Failed to load agency: ${result.debugMessage || result.message}`);
                this.setState({
                    status: "ready",
                    activeAgency: null,
                });
            }
        });
    }

    private onSettingsChanged(newSettings: AgencySettings) {
        this.setState((prevState: AgencyState) => {
            if (prevState.activeAgency) {
                prevState.activeAgency.settings = newSettings;
            }

            return prevState;
        });
    }

    render() {
        const loadingDiv = (
            <div className="underHorizNavContent">
                <Loader active/>
            </div>
        );

        return (
            <div id="agencyContent" className="pageContent horizontalNavAndContent">
                <div id="horizontalNav" className="Component_HorizontalNav">
                    <Menu attached="top" tabular>
                        <RouterMenuItem exact to="/agency">{this.props.t("agency.tabs.general")}</RouterMenuItem>
                        <RouterMenuItem exact
                                        to="/agency/streams">{this.props.t("agency.tabs.streams")}</RouterMenuItem>
                        <RouterMenuItem exact
                                        to="/agency/response-options">{this.props.t("agency.tabs.responseOptions")}</RouterMenuItem>
                    </Menu>
                </div>

                <Switch>
                    <Route exact path="/agency" render={() => this.state.activeAgency ?
                        <GeneralTab activeAgency={this.state.activeAgency}
                                    onSettingsChanged={this.onSettingsChanged.bind(this)}
                                    onReload={() => this.loadAgency(this.props)}
                                    {...this.props}/> :
                        loadingDiv}/>
                    <Route exact path="/agency/streams" render={() => this.state.activeAgency ?
                        <StreamsTab activeAgency={this.state.activeAgency} {...this.props}/> : loadingDiv}/>
                    <Route exact path="/agency/response-options" render={() => this.state.activeAgency ?
                        <ResponseOptionsTab activeAgency={this.state.activeAgency} {...this.props}/> : loadingDiv}/>

                    <Redirect to="/agency"/>
                </Switch>
            </div>
        );
    }
}

export default withContext(AgencyPage, 'api', 'local', 'i18n');
