import * as React from 'react';
import {Menu, Icon} from 'semantic-ui-react';
import {range} from '@bryxinc/lunch/utils/functions';

export interface PaginationProps {
    items: number;
    activePage: number;
    onSelect: (pageNumber: number) => void;
    style?: React.CSSProperties;
}

export class Pagination extends React.Component<PaginationProps> {
    private static readonly maxUnellipsizedItems = 6;

    render() {
        const leftArrow = (
            <Menu.Item as='a'
                       icon
                       disabled={this.props.activePage == 1}
                       onClick={() => {
                           if (this.props.activePage == 1) {
                               // `disabled` does not actually disable onClick
                               return;
                           }
                           this.props.onSelect(this.props.activePage - 1);
                       }}>
                <Icon name='chevron left'/>
            </Menu.Item>
        );

        const rightArrow = (
            <Menu.Item as='a'
                       icon
                       disabled={this.props.activePage == this.props.items}
                       onClick={() => {
                           if (this.props.activePage == this.props.items) {
                               // `disabled` does not actually disable onClick
                               return;
                           }
                           this.props.onSelect(this.props.activePage + 1);
                       }}>
                <Icon name='chevron right'/>
            </Menu.Item>
        );

        const ellipsis = (key: string) => <Menu.Item as='a' disabled key={key}>...</Menu.Item>;

        const pageNumberToItem = (pageNumber: number) => {
            return (
                <Menu.Item as='a'
                           key={pageNumber}
                           active={this.props.activePage == pageNumber}
                           onClick={() => {
                               this.props.onSelect(pageNumber);
                           }}>{pageNumber}</Menu.Item>
            );
        };

        let centerItems;
        if (this.props.items <= Pagination.maxUnellipsizedItems) {
            centerItems = range(1, this.props.items + 1).map((n: number) => {
                return pageNumberToItem(n);
            });
        } else if (this.props.activePage <= 3) {
            centerItems = [
                pageNumberToItem(1),
                pageNumberToItem(2),
                pageNumberToItem(3),
                pageNumberToItem(4),
                ellipsis("e1"),
                pageNumberToItem(this.props.items),
            ];
        } else if (this.props.activePage >= this.props.items - 2) {
            centerItems = [
                pageNumberToItem(1),
                ellipsis("e1"),
                pageNumberToItem(this.props.items - 3),
                pageNumberToItem(this.props.items - 2),
                pageNumberToItem(this.props.items - 1),
                pageNumberToItem(this.props.items),
            ];
        } else {
            centerItems = [
                pageNumberToItem(1),
                ellipsis("e1"),
                pageNumberToItem(this.props.activePage - 1),
                pageNumberToItem(this.props.activePage),
                pageNumberToItem(this.props.activePage + 1),
                ellipsis("e2"),
                pageNumberToItem(this.props.items),
            ];
        }

        return (
            <Menu pagination style={this.props.style}>
                {leftArrow}
                {centerItems}
                {rightArrow}
            </Menu>
        );
    }
}
