import createLog from 'localstorage-logger';
import * as React from 'react';
import * as ReactDom from 'react-dom';

import MainRouter from './components/MainRouter';
import {ApiContext, LocalContext} from '@bryxinc/lunch/context';
import {Config} from '@bryxinc/lunch/models/config';
import BryxApi from '@bryxinc/lunch/utils/ManagementApi';
import BryxLocalStorage from '@bryxinc/lunch/utils/BryxLocalStorage';

import {I18nextProvider} from 'react-i18next';
import i18n from './i18n';

import {BadgeManager} from './utils/badgeManager';

// Creating config from environment
declare const VERSION: string;

/**
 * Get config.
 * @param d NODE_ENV
 * @param l LOCALE
 * @param b BASE_URL
 * @return config
 */
function getConfig(d: string | undefined, l: string | undefined, b: string): Config {
    switch (d) {
        case 'dev':
            console.log('Dev config loaded');
            createLog({
                logName: 'bryx-dev-log',
                maxLogSizeInBytes: 500 * 1024, // 500KB
            });
            return {
                baseUrl: b,
                serverType: 'dev',
                serverLocale: (l == "us" || l == "ca" ? l : "us"),
                site: 'management',
                version: VERSION,
            };
        case 'k8s':
            console.log('k8s config loaded');
            createLog({
                logName: 'bryx-dev-log',
                maxLogSizeInBytes: 500 * 1024, // 500KB
            });
            return {
                baseUrl: b,
                serverType: 'k8s',
                serverLocale: (l == "us" || l == "ca" ? l : "us"),
                site: 'management',
                version: VERSION,
            };
        case 'production':
            console.log('Prod config loaded');
            createLog({
                logName: 'bryx-prod-log',
                maxLogSizeInBytes: 500 * 1024, // 500KB
            });
            return {
                baseUrl: b,
                serverType: 'prod',
                serverLocale: (l == "us" || l == "ca" ? l : "us"),
                site: 'management',
                version: VERSION,
            };
        case 'test':
            return {
                baseUrl: b,
                serverType: 'test',
                serverLocale: (l == "us" || l == "ca" ? l : "us"),
                site: 'management',
                version: '0.0.0',
            };
        default:
            throw new Error(`Bad deployment: ${d} -- No config for this deployment`);
    }
}

export const config = getConfig(process.env.NODE_ENV, process.env.LOCALE, process.env.BASE_URL || '');
console.log(`NODE_ENV = ${process.env.NODE_ENV}`);
console.log(`LOCALE = ${process.env.LOCALE}`);
console.log(config);

const mainRouter = React.createElement(MainRouter);
const i18nProviderElement = React.createElement(
    I18nextProvider,
    {i18n: i18n},
    mainRouter,
);

export const local = new BryxLocalStorage(config);
const localProvider = React.createElement(
    LocalContext.Provider,
    {value: local},
    i18nProviderElement,
);
export const api = new BryxApi(config, local/*, testResponses*/);
const apiProvider = React.createElement(
    ApiContext.Provider,
    {value: api},
    localProvider,
);

export const badgeManager = new BadgeManager(api, local);

ReactDom.render(
    apiProvider,
    document.getElementById('content'),
);

