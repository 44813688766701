import * as React from "react";
import {
    Button,
    Form,
    Header,
    Loader,
    Message,
    Modal,
    Popup,
    Table,
} from "semantic-ui-react";
import {PaginatedTable} from "../../components/paginatedTable";
import {
    ApiResult,
    PageResult,
    SortingConfig,
    Client,
    AgencyGroupTypeEnum,
    GroupMembershipStatus,
    GroupMembershipStatusSortingOptions,
    MembershipStatusFilterEnum,
    ResponseOptionTypeEnum,
} from "@bryxinc/lunch/models";

import {RespondOptionSelectItem} from "./respondToJoinRequestModal";
import {SelectPermissions} from "./selectPermissions";
import {SelectResponseOptions} from "./selectResponseOptions";

import {RouteComponentProps} from "react-router";
import BryxApi from "@bryxinc/lunch/utils/ManagementApi";
import {
    withContext,
    WithTranslation,
    WithLocal,
    WithApi,
} from "@bryxinc/lunch/context";

export type EditClientGroupsModalViewStatus =
    | { key: "hidden" }
    | {
    key: "shown";
    agencyId: string;
    client: Client;
    groupType: AgencyGroupTypeEnum;
};

interface EditClientGroupsModalProps
    extends RouteComponentProps,
        WithTranslation,
        WithLocal,
        WithApi<BryxApi> {
    viewStatus: EditClientGroupsModalViewStatus;
    onUpdateGroups: () => void;
    onClose: () => void;
}

interface EditClientGroupsModalState {
    responseOptionStatus:
        | { key: "loading" }
        | { key: "ready"; responseOptions: RespondOptionSelectItem[] }
        | { key: "error"; message: string };
    actionStatus:
        | { key: "ready" }
        | { key: "loading" }
        | { key: "error"; message: string };
    filter: MembershipStatusFilterEnum;
    currentActionGroupId: string | null;
    canSend: boolean;
    canRespond: boolean;
}

export class EditClientGroupsModal extends React.Component<EditClientGroupsModalProps,
    EditClientGroupsModalState> {
    private paginatedTable: PaginatedTable<GroupMembershipStatus> | null = null;

    constructor(props: EditClientGroupsModalProps) {
        super(props);
        this.state = EditClientGroupsModal.getInitialState();
    }

    private static getInitialState(): EditClientGroupsModalState {
        return {
            responseOptionStatus: {key: "loading"},
            actionStatus: {key: "ready"},
            filter: MembershipStatusFilterEnum.all,
            currentActionGroupId: null,
            canSend: false,
            canRespond: false,
        };
    }

    componentDidMount() {
        this.loadResponseOptions();
    }

    UNSAFE_componentWillReceiveProps(nextProps: EditClientGroupsModalProps) {
        if (
            this.props.viewStatus.key == "hidden" &&
            nextProps.viewStatus.key == "shown"
        ) {
            this.setState(EditClientGroupsModal.getInitialState(), () =>
                this.loadResponseOptions(),
            );
        }
    }

    private loadResponseOptions() {
        if (this.props.viewStatus.key != "shown") {
            return;
        }
        this.setState({responseOptionStatus: {key: "loading"}});
        this.props.api.getAgencyResponseOptions(
            this.props.viewStatus.agencyId,
            null,
            [ResponseOptionTypeEnum.positive, ResponseOptionTypeEnum.negative],
            (result) => {
                if (result.success == true) {
                    this.setState({
                        responseOptionStatus: {
                            key: "ready",
                            responseOptions: SelectResponseOptions.getWrappedResponseOptions(
                                result.value,
                            ),
                        },
                    });
                } else {
                    this.props.local.logWarn(
                        `Failed to get agency response options: ${
                            result.debugMessage || result.message
                        }`,
                    );
                    this.setState({
                        responseOptionStatus: {key: "error", message: result.message},
                    });
                }
            },
        );
    }

    private loadItems(
        limit: number,
        activePage: number,
        searchString: string | null,
        sortConfig: SortingConfig<GroupMembershipStatusSortingOptions>,
        callback: (result: ApiResult<PageResult<GroupMembershipStatus>>) => void,
    ) {
        if (this.props.viewStatus.key == "shown") {
            this.props.api.getGroupMembershipsForClient(
                this.props.viewStatus.agencyId,
                this.props.viewStatus.client.id,
                this.props.viewStatus.groupType,
                this.state.filter,
                limit,
                activePage,
                sortConfig,
                searchString,
                callback,
            );
        }
    }

    private onActionClick(groupId: string) {
        this.setState((prevState: EditClientGroupsModalState) => {
            if (prevState.responseOptionStatus.key == "ready") {
                const options = prevState.responseOptionStatus.responseOptions.map(
                    (r) => r.responseOption,
                );
                prevState.responseOptionStatus.responseOptions =
                    SelectResponseOptions.getWrappedResponseOptions(options);
            }
            prevState.actionStatus = {key: "ready"};
            prevState.currentActionGroupId = groupId;
            prevState.canRespond = false;
            prevState.canSend = false;
            return prevState;
        });
    }

    private onUpdateGroups() {
        if (this.paginatedTable != null) {
            this.paginatedTable.reload();
        }
        this.props.onUpdateGroups();
    }

    private closeAction() {
        this.setState({
            currentActionGroupId: null,
        });
    }

    private changeFilter(filterEnum: MembershipStatusFilterEnum) {
        this.setState(
            {
                filter: filterEnum,
            },
            () => {
                if (this.paginatedTable != null) {
                    this.paginatedTable.resetTable();
                }
            },
        );
    }

    private toggleResponseOption(option: RespondOptionSelectItem) {
        this.setState((prevState) => {
            if (prevState.responseOptionStatus.key == "ready") {
                prevState.responseOptionStatus.responseOptions.forEach((o) => {
                    if (o.responseOption.id == option.responseOption.id) {
                        o.selected = !o.selected;
                    }
                });
            }
            return prevState;
        });
    }

    private addGroup(groupId: string) {
        const {viewStatus} = this.props;
        const {canSend, canRespond, responseOptionStatus} = this.state;
        if (viewStatus.key == "shown" && responseOptionStatus.key == "ready") {
            const selectedResponseOptions = responseOptionStatus.responseOptions
                .filter((r) => r.selected)
                .map((r) => r.responseOption);
            this.setState({
                actionStatus: {key: "loading"},
            });

            if (viewStatus.groupType == AgencyGroupTypeEnum.dispatch) {
                this.props.api.addClientToDispatchGroup(
                    viewStatus.agencyId,
                    groupId,
                    viewStatus.client.id,
                    selectedResponseOptions,
                    canSend,
                    canRespond,
                    (result) => {
                        if (result.success == true) {
                            this.setState(
                                {
                                    actionStatus: {key: "ready"},
                                    currentActionGroupId: null,
                                },
                                () => this.onUpdateGroups(),
                            );
                        } else {
                            this.props.local.logWarn(
                                `Failed to add member to dispatch group: ${
                                    result.debugMessage || result.message
                                }`,
                            );
                            this.setState({
                                actionStatus: {key: "error", message: result.message},
                            });
                        }
                    },
                );
            } else {
                this.props.api.addClientToMessagingGroup(
                    viewStatus.agencyId,
                    groupId,
                    viewStatus.client.id,
                    canSend,
                    (result) => {
                        if (result.success == true) {
                            this.setState(
                                {
                                    actionStatus: {key: "ready"},
                                    currentActionGroupId: null,
                                },
                                () => this.onUpdateGroups(),
                            );
                        } else {
                            this.props.local.logWarn(
                                `Failed to add member to messaging group: ${
                                    result.debugMessage || result.message
                                }`,
                            );
                            this.setState({
                                actionStatus: {key: "error", message: result.message},
                            });
                        }
                    },
                );
            }
        }
    }

    private removeGroup(groupId: string) {
        const {viewStatus} = this.props;
        const {responseOptionStatus} = this.state;

        if (viewStatus.key == "shown" && responseOptionStatus.key == "ready") {
            this.setState({
                actionStatus: {key: "loading"},
            });

            this.props.api.removeClientFromGroup(
                viewStatus.agencyId,
                groupId,
                viewStatus.client.id,
                (result) => {
                    if (result.success == true) {
                        this.setState(
                            {
                                actionStatus: {key: "ready"},
                                currentActionGroupId: null,
                            },
                            () => this.onUpdateGroups(),
                        );
                    } else {
                        this.props.local.logWarn(
                            `Failed to remove member: ${
                                result.debugMessage || result.message
                            }`,
                        );
                        this.setState({
                            actionStatus: {key: "error", message: result.message},
                        });
                    }
                },
            );
        }
    }

    private canAdd(responseOptions: RespondOptionSelectItem[]) {
        const {viewStatus} = this.props;
        if (
            viewStatus.key == "shown" &&
            viewStatus.groupType == AgencyGroupTypeEnum.messaging
        ) {
            return true;
        }

        const atLeastOneNegative =
            responseOptions.filter(
                (o) =>
                    o.responseOption.type == ResponseOptionTypeEnum.negative &&
                    o.selected == true,
            ).length > 0;
        const atLeastOnePositive =
            responseOptions.filter(
                (o) =>
                    o.responseOption.type == ResponseOptionTypeEnum.positive &&
                    o.selected == true,
            ).length > 0;

        return atLeastOneNegative && atLeastOnePositive;
    }

    private renderModalContent(): JSX.Element | null {
        const {viewStatus} = this.props;
        const {
            canSend,
            canRespond,
            currentActionGroupId,
            actionStatus,
            responseOptionStatus,
        } = this.state;
        if (viewStatus.key == "hidden") {
            return null;
        }

        if (responseOptionStatus.key == "loading") {
            return <Loader active className="dark"/>;
        } else if (responseOptionStatus.key == "error") {
            return <Message negative content={responseOptionStatus.message}/>;
        }

        return (
            <PaginatedTable
                {...this.props}
                uniqueKey="groups"
                ref={(r) => (this.paginatedTable = r)}
                rightItem={
                    <Popup
                        on="click"
                        position="bottom right"
                        trigger={
                            <Button
                                icon="filter"
                                color={
                                    this.state.filter != MembershipStatusFilterEnum.all
                                        ? "orange"
                                        : undefined
                                }
                            />
                        }
                        header={this.props.t(
                            "members.users.editClientGroupsModal.filter.title",
                        )}
                        style={{maxWidth: "none"}}
                        content={
                            <div style={{padding: "30px 10px 15px 10px"}}>
                                <Button.Group>
                                    <Button
                                        toggle
                                        style={{width: "135px"}}
                                        active={this.state.filter == MembershipStatusFilterEnum.all}
                                        onClick={() =>
                                            this.changeFilter(MembershipStatusFilterEnum.all)
                                        }
                                        content={this.props.t(
                                            "members.users.editClientGroupsModal.filter.all",
                                        )}
                                    />
                                    <Button
                                        toggle
                                        style={{width: "135px"}}
                                        active={
                                            this.state.filter == MembershipStatusFilterEnum.inGroup
                                        }
                                        onClick={() =>
                                            this.changeFilter(MembershipStatusFilterEnum.inGroup)
                                        }
                                        content={this.props.t(
                                            "members.users.editClientGroupsModal.filter.inGroup",
                                        )}
                                    />
                                    <Button
                                        toggle
                                        style={{width: "135px"}}
                                        active={
                                            this.state.filter == MembershipStatusFilterEnum.notInGroup
                                        }
                                        onClick={() =>
                                            this.changeFilter(MembershipStatusFilterEnum.notInGroup)
                                        }
                                        content={this.props.t(
                                            "members.users.editClientGroupsModal.filter.notInGroup",
                                        )}
                                    />
                                </Button.Group>
                            </div>
                        }
                    />
                }
                sortable={false}
                loader={{
                    loadId: viewStatus.agencyId,
                    loadItems: this.loadItems.bind(this),
                }}
                headerDataItems={[
                    {
                        i18nKey: "groups.name",
                        headerKey: "name",
                        width: 16,
                    },
                ]}
                renderItem={(membership: GroupMembershipStatus) => {
                    const actionButton =
                        membership.hasGroup != true ? (
                            <Popup
                                position="left center"
                                open={currentActionGroupId == membership.id}
                                header={
                                    <Header as="h1">
                                        {this.props.t("groups.viewMembers.addHeader")}
                                    </Header>
                                }
                                style={{padding: "20px", maxWidth: "none"}}
                                trigger={
                                    <Button
                                        positive
                                        compact
                                        style={{width: "100px"}}
                                        onClick={() => this.onActionClick(membership.id)}
                                        content={this.props.t("groups.viewMembers.add")}
                                    />
                                }
                                content={
                                    <div>
                                        <Form style={{minWidth: "400px"}}>
                                            {viewStatus.key == "shown" &&
                                            viewStatus.groupType == AgencyGroupTypeEnum.dispatch ? (
                                                <SelectResponseOptions
                                                    {...this.props}
                                                    style={{marginBottom: "10px", marginTop: "20px"}}
                                                    responseOptions={responseOptionStatus.responseOptions}
                                                    onSelect={(option) =>
                                                        this.toggleResponseOption(option)
                                                    }
                                                />
                                            ) : undefined}
                                            <SelectPermissions
                                                {...this.props}
                                                style={{marginBottom: 0}}
                                                canSend={canSend}
                                                canRespond={
                                                    viewStatus.key == "shown" &&
                                                    viewStatus.groupType == AgencyGroupTypeEnum.dispatch
                                                        ? canRespond
                                                        : undefined
                                                }
                                                onPermissionClick={(t, v) => {
                                                    if (t == "send") {
                                                        this.setState({
                                                            canSend: v,
                                                        });
                                                    } else {
                                                        this.setState({
                                                            canRespond: v,
                                                        });
                                                    }
                                                }}
                                            />
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Button
                                                    content={this.props.t("general.cancel")}
                                                    disabled={actionStatus.key == "loading"}
                                                    onClick={() => this.closeAction()}
                                                    style={{
                                                        marginTop: "20px",
                                                        marginLeft: "5px",
                                                        width: "85px",
                                                    }}
                                                />
                                                <Button
                                                    positive
                                                    content={this.props.t("groups.viewMembers.add")}
                                                    loading={actionStatus.key == "loading"}
                                                    disabled={
                                                        !this.canAdd(responseOptionStatus.responseOptions)
                                                    }
                                                    onClick={() => this.addGroup(membership.id)}
                                                    style={{
                                                        marginTop: "20px",
                                                        marginRight: "5px",
                                                        width: "85px",
                                                    }}
                                                />
                                            </div>
                                        </Form>
                                        {actionStatus.key == "error" ? (
                                            <Message error content={actionStatus.message}/>
                                        ) : undefined}
                                    </div>
                                }
                            />
                        ) : (
                            <Popup
                                position="left center"
                                open={currentActionGroupId == membership.id}
                                header={
                                    <Header as="h1">
                                        {this.props.t("groups.viewMembers.removeHeader")}
                                    </Header>
                                }
                                style={{padding: "20px", maxWidth: "none"}}
                                trigger={
                                    <Button
                                        negative
                                        compact
                                        style={{width: "100px"}}
                                        onClick={() => this.onActionClick(membership.id)}
                                        content={this.props.t("groups.viewMembers.remove")}
                                    />
                                }
                                content={
                                    <div style={{minWidth: "400px"}}>
                                        <div>
                                            {this.props.t("groups.viewMembers.removeExplain", {
                                                replace: {
                                                    memberName:
                                                        viewStatus.key == "shown"
                                                            ? viewStatus.client.commonName
                                                            : "",
                                                    groupName: membership.groupName,
                                                },
                                            })}
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Button
                                                compact
                                                content={this.props.t("general.cancel")}
                                                disabled={actionStatus.key == "loading"}
                                                onClick={() => this.closeAction()}
                                                style={{marginTop: "20px", marginLeft: "5px"}}
                                            />
                                            <Button
                                                negative
                                                compact
                                                loading={actionStatus.key == "loading"}
                                                onClick={() => this.removeGroup(membership.id)}
                                                content={this.props.t("groups.viewMembers.remove")}
                                                style={{marginTop: "20px", marginRight: "5px"}}
                                            />
                                        </div>
                                        {actionStatus.key == "error" ? (
                                            <Message error content={actionStatus.message}/>
                                        ) : undefined}
                                    </div>
                                }
                            />
                        );

                    return (
                        <Table.Row key={membership.id}>
                            <Table.Cell
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    borderLeft: `10px solid ${membership.groupColor}`,
                                }}
                            >
                                {membership.groupName}
                                <div style={{float: "right"}}>{actionButton}</div>
                            </Table.Cell>
                        </Table.Row>
                    );
                }}
                defaultSorting={{
                    column: "groupName",
                    direction: "asc",
                }}
                style={{height: "100%", display: "flex", flexDirection: "column"}}
            />
        );
    }

    render() {
        const {viewStatus} = this.props;
        const {actionStatus} = this.state;
        return (
            <Modal
                open={viewStatus.key == "shown"}
                onClose={() => this.props.onClose()}
            >
                <Modal.Header>
                    {viewStatus.key == "shown" &&
                    viewStatus.groupType == AgencyGroupTypeEnum.dispatch
                        ? this.props.t("members.users.editClientGroupsModal.dispatchTitle")
                        : this.props.t(
                            "members.users.editClientGroupsModal.messagingTitle",
                        )}
                </Modal.Header>
                <Modal.Content
                    style={{
                        backgroundColor: "#fbfbfb",
                        height: "646px",
                        padding: "50px 60px 10px",
                        overflowY: "auto",
                    }}
                >
                    {this.renderModalContent()}
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content={this.props.t("general.done")}
                        loading={actionStatus.key == "loading"}
                        onClick={() => this.props.onClose()}
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}

export default withContext(EditClientGroupsModal, "api", "local", "i18n");
