import * as React from "react";
import {AuthAgency} from "@bryxinc/lunch/models";
import {RouteComponentProps} from "react-router";
import {Route, Redirect, Switch} from "react-router-dom";
import {Menu} from "semantic-ui-react";
import RouterMenuItem from "../../components/routerMenuItem";
import {GeocodeTesterTab} from "./geocodeTesterTab";

import BryxApi from "@bryxinc/lunch/utils/ManagementApi";

import {
    withContext,
    WithTranslation,
    WithLocal,
    WithApi,
} from "@bryxinc/lunch/context";

export interface LocationServicesProps
    extends RouteComponentProps,
        WithTranslation,
        WithLocal,
        WithApi<BryxApi> {
    selectedAgency: AuthAgency;
}

export class LocationServicesPage extends React.Component<LocationServicesProps> {
    render() {
        return (
            <div
                id="locationServicesContent"
                className="pageContent horizontalNavAndContent"
            >
                <div id="horizontalNav" className="Component_HorizontalNav">
                    <Menu attached="top" tabular>
                        <RouterMenuItem exact to="/location-services/geocode">
                            {this.props.t("locationServices.tabs.geocodeTester")}
                        </RouterMenuItem>
                    </Menu>
                </div>

                <Switch>
                    <Route
                        exact
                        path="/location-services/geocode"
                        render={() => <GeocodeTesterTab {...this.props} />}
                    />

                    <Redirect to="/location-services/geocode"/>
                </Switch>
            </div>
        );
    }
}

export default withContext(LocationServicesPage, "api", "local", "i18n");
