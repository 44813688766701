import * as React from "react";
import {
    Button,
    Checkbox,
    Icon,
    Message,
    Modal,
    Popup,
} from "semantic-ui-react";
import {AuthAgency, AgencyClientType, Client} from "@bryxinc/lunch/models";

import {RouteComponentProps} from "react-router";
import BryxApi from "@bryxinc/lunch/utils/ManagementApi";
import {
    withContext,
    WithTranslation,
    WithLocal,
    WithApi,
} from "@bryxinc/lunch/context";

export type ConfirmRemoveClientModalViewStatus =
    | { key: "hidden" }
    | { key: "shown"; agency: AuthAgency; client: Client };

interface ConfirmRemoveClientModalProps
    extends RouteComponentProps,
        WithTranslation,
        WithLocal,
        WithApi<BryxApi> {
    viewStatus: ConfirmRemoveClientModalViewStatus;
    onConfirmRemove: () => void;
    onClose: () => void;
}

interface ConfirmRemoveClientModalState {
    status:
        | { key: "ready" }
        | { key: "loading" }
        | { key: "success" }
        | { key: "error"; message: string };
    notify: boolean;
}

export class ConfirmRemoveClientModal extends React.Component<ConfirmRemoveClientModalProps,
    ConfirmRemoveClientModalState> {
    constructor(props: ConfirmRemoveClientModalProps) {
        super(props);
        this.state = ConfirmRemoveClientModal.getInitialState();
    }

    private static getInitialState(): ConfirmRemoveClientModalState {
        return {
            status: {key: "ready"},
            notify: true,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: ConfirmRemoveClientModalProps) {
        if (
            this.props.viewStatus.key == "hidden" &&
            nextProps.viewStatus.key == "shown"
        ) {
            this.setState(ConfirmRemoveClientModal.getInitialState());
        }
    }

    private onSubmit() {
        const {status, notify} = this.state;
        const {viewStatus} = this.props;
        if (status.key == "loading" || viewStatus.key != "shown") {
            return;
        }
        this.setState({status: {key: "loading"}});
        this.props.api.removeClientFromAgency(
            viewStatus.agency.id,
            viewStatus.client.id,
            notify,
            (result) => {
                if (result.success == true) {
                    this.props.onConfirmRemove();
                } else {
                    this.props.local.logWarn(
                        `Failed to remove client from agency: ${
                            result.debugMessage || result.message
                        }`,
                    );
                    this.setState({status: {key: "error", message: result.message}});
                }
            },
        );
    }

    private renderModalContent(): JSX.Element | null {
        const {viewStatus} = this.props;
        const {status} = this.state;
        if (viewStatus.key == "hidden") {
            return <Modal.Content/>;
        }

        const clientI18nKey = AgencyClientType[viewStatus.client.type];

        return (
            <Modal.Content>
                <Message
                    warning
                    header={this.props.t(
                        `members.clients.confirmRemoveClientModal.${clientI18nKey}.areYouSure`,
                        {
                            replace: {
                                client: viewStatus.client.commonName,
                                agency: viewStatus.agency.name,
                            },
                        },
                    )}
                    content={this.props.t(
                        "members.clients.confirmRemoveClientModal.accessLoss",
                        {
                            replace: {
                                client: viewStatus.client.commonName,
                            },
                        },
                    )}
                />
                {status.key == "error" ? (
                    <Message error content={status.message}/>
                ) : null}
            </Modal.Content>
        );
    }

    render() {
        const {viewStatus} = this.props;
        const {status} = this.state;
        let clientI18nKey = null;
        if (viewStatus.key == "shown") {
            clientI18nKey = AgencyClientType[viewStatus.client.type];
        }
        return (
            <Modal
                size="small"
                open={viewStatus.key == "shown"}
                onClose={this.props.onClose}
            >
                <Modal.Header>
                    {this.props.t(
                        `members.clients.confirmRemoveClientModal.${clientI18nKey}.title`,
                    )}
                </Modal.Header>
                {this.renderModalContent()}
                <Modal.Actions>
                    {viewStatus.key == "shown" &&
                    viewStatus.client.type == AgencyClientType.user ? (
                        <Checkbox
                            checked={this.state.notify}
                            onChange={(e, d) => this.setState({notify: d.checked || false})}
                            label={
                                <label>
                                    {this.props.t(
                                        "members.clients.confirmRemoveClientModal.user.notifyTitle",
                                    )}
                                    <Popup
                                        trigger={
                                            <Icon name="help circle" style={{marginLeft: "5px"}}/>
                                        }
                                        position="right center"
                                        content={this.props.t(
                                            "members.clients.confirmRemoveClientModal.user.notifyDescription",
                                        )}
                                    />
                                </label>
                            }
                            style={{marginTop: "5px"}}
                        />
                    ) : null}
                    <Button
                        content={this.props.t("general.cancel")}
                        disabled={status.key == "loading"}
                        onClick={this.props.onClose}
                    />
                    <Button
                        negative
                        loading={status.key == "loading"}
                        content={
                            viewStatus.key == "shown" &&
                            viewStatus.client.type == AgencyClientType.user
                                ? this.props.t("general.remove")
                                : this.props.t("general.delete")
                        }
                        onClick={this.onSubmit.bind(this)}
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}

export default withContext(ConfirmRemoveClientModal, "api", "local", "i18n");
