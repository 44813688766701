import * as React from "react";
import {Button, Message, Modal} from "semantic-ui-react";
import {SCUAlert} from "@bryxinc/lunch/models";

import {RouteComponentProps} from "react-router";
import BryxApi from "@bryxinc/lunch/utils/ManagementApi";
import {
    withContext,
    WithTranslation,
    WithLocal,
    WithApi,
} from "@bryxinc/lunch/context";

export type ConfirmDeleteStatusNotificationModalViewStatus =
    | { key: "hidden" }
    | { key: "shown"; agencyId: string; alert: SCUAlert };

interface ConfirmDeleteStatusNotificationModalProps
    extends RouteComponentProps,
        WithTranslation,
        WithLocal,
        WithApi<BryxApi> {
    viewStatus: ConfirmDeleteStatusNotificationModalViewStatus;
    onConfirmRemove: (scuAlertId: string) => void;
    onClose: () => void;
}

interface ConfirmDeleteStatusNotificationModalState {
    status:
        | { key: "ready" }
        | { key: "loading" }
        | { key: "error"; message: string };
}

export class ConfirmDeleteStatusNotificationModal extends React.Component<ConfirmDeleteStatusNotificationModalProps,
    ConfirmDeleteStatusNotificationModalState> {
    constructor(props: ConfirmDeleteStatusNotificationModalProps) {
        super(props);
        this.state = ConfirmDeleteStatusNotificationModal.getInitialState();
    }

    private static getInitialState(): ConfirmDeleteStatusNotificationModalState {
        return {
            status: {key: "ready"},
        };
    }

    UNSAFE_componentWillReceiveProps(
        nextProps: ConfirmDeleteStatusNotificationModalProps,
    ) {
        if (
            this.props.viewStatus.key == "hidden" &&
            nextProps.viewStatus.key == "shown"
        ) {
            this.setState(ConfirmDeleteStatusNotificationModal.getInitialState());
        }
    }

    private onSubmit() {
        const {status} = this.state;
        const {viewStatus} = this.props;
        if (status.key == "loading" || viewStatus.key != "shown") {
            return;
        }
        this.setState({status: {key: "loading"}});
        this.props.api.deleteAgencySCUAlert(
            viewStatus.agencyId,
            viewStatus.alert.id,
            (result) => {
                if (result.success) {
                    this.props.onConfirmRemove(viewStatus.alert.id);
                } else {
                    this.props.local.logWarn(
                        `Failed to delete SCU alert: ${
                            result.debugMessage || result.message
                        }`,
                    );
                    this.setState({status: {key: "error", message: result.message}});
                }
            },
        );
    }

    private renderModalContent(): JSX.Element | null {
        const {viewStatus} = this.props;
        const {status} = this.state;
        if (viewStatus.key == "hidden") {
            return <Modal.Content/>;
        }

        return (
            <Modal.Content>
                <Message
                    warning
                    content={this.props.t(
                        "stationAlerting.statusNotifications.deleteNotification.header",
                        {replace: {ruleName: viewStatus.alert.name}},
                    )}
                />
                {status.key == "error" ? (
                    <Message error content={status.message}/>
                ) : null}
            </Modal.Content>
        );
    }

    render() {
        const {viewStatus} = this.props;
        const {status} = this.state;
        return (
            <Modal
                size="small"
                open={viewStatus.key == "shown"}
                onClose={this.props.onClose}
            >
                <Modal.Header>
                    {this.props.t(
                        "stationAlerting.statusNotifications.deleteNotification.modalHeader",
                    )}
                </Modal.Header>
                {this.renderModalContent()}
                <Modal.Actions>
                    <Button
                        content={this.props.t("general.cancel")}
                        disabled={status.key == "loading"}
                        onClick={this.props.onClose}
                    />
                    <Button
                        negative
                        loading={status.key == "loading"}
                        content={this.props.t("general.remove")}
                        onClick={this.onSubmit.bind(this)}
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}

export default withContext(
    ConfirmDeleteStatusNotificationModal,
    "api",
    "local",
    "i18n",
);
