import * as React from "react";
import {
    Button,
    Form,
    Grid,
    Header,
    Icon,
    Message,
    Modal,
    Popup,
    Search,
} from "semantic-ui-react";
import {AuthAgency, SearchUser} from "@bryxinc/lunch/models";
import {
    Debouncer,
    isValidEmail,
    nullIfBlank,
} from "@bryxinc/lunch/utils/functions";
import {RouteComponentProps} from "react-router";
import BryxApi from "@bryxinc/lunch/utils/ManagementApi";

import {
    withContext,
    WithTranslation,
    WithLocal,
    WithApi,
} from "@bryxinc/lunch/context";

interface AddUserModalProps
    extends RouteComponentProps,
        WithTranslation,
        WithLocal,
        WithApi<BryxApi> {
    open: boolean;
    selectedAgency: AuthAgency;
    onClose: () => void;
    onSelectUser: (userId: string) => void;
}

interface AddUserModalState {
    stage:
        | { key: "searchOrCreate" }
        | { key: "confirmAdd"; user: SearchUser }
        | { key: "create" };

    // searchOrCreate stage
    searchQuery: string | null;
    searchStatus:
        | { key: "empty" }
        | { key: "loading" }
        | { key: "ready"; results: SearchUser[] }
        | { key: "error"; message: string };

    // confirmAdd stage
    confirmStatus:
        | { key: "ready" }
        | { key: "loading" }
        | { key: "error"; message: string };

    // create stage
    createStatus:
        | { key: "ready" }
        | { key: "loading" }
        | { key: "error"; message: string };
    emailStatus:
        | { key: "composing" }
        | { key: "loading" }
        | { key: "ok" }
        | { key: "userExists"; user: SearchUser }
        | { key: "error"; message: string };
    newEmail: string | null;

    setPassword: boolean;
    password: string | null;
    passwordShown: boolean;

    firstName: string | null;
    lastName: string | null;
    commonName: { key: "clean" } | { key: "dirty"; value: string | null };
    phone: string | null;
}

export class AddUserModal extends React.Component<AddUserModalProps,
    AddUserModalState> {
    private searchDebouncer = new Debouncer(this.loadUsers.bind(this));
    private emailDebouncer = new Debouncer(this.checkEmail.bind(this));

    private static readonly searchLimit = 5;
    private static readonly searchMinCharacters = 5;

    constructor(props: AddUserModalProps) {
        super(props);
        this.state = AddUserModal.getDefaultState();
    }

    UNSAFE_componentWillReceiveProps(nextProps: AddUserModalProps) {
        if (!this.props.open && nextProps.open) {
            this.setState(AddUserModal.getDefaultState());
        }
    }

    private static getDefaultState(): AddUserModalState {
        return {
            stage: {key: "searchOrCreate"},
            searchQuery: null,
            searchStatus: {key: "empty"},
            confirmStatus: {key: "ready"},
            createStatus: {key: "ready"},
            emailStatus: {key: "composing"},
            newEmail: null,
            setPassword: false,
            password: null,
            passwordShown: false,
            firstName: null,
            lastName: null,
            commonName: {key: "clean"},
            phone: null,
        };
    }

    private getAgenciesDescription(user: SearchUser): string {
        switch (user.agencies.length) {
            case 0:
                return this.props.t(
                    "members.users.addUserModal.agenciesDescription.noAgencies",
                );
            case 1:
                return user.agencies[0].name;
            case 2:
                return this.props.t(
                    "members.users.addUserModal.agenciesDescription.two",
                    {replace: {firstAgency: user.agencies[0].name}},
                );
            default:
                return this.props.t(
                    "members.users.addUserModal.agenciesDescription.threeOrMore",
                    {
                        replace: {
                            firstAgency: user.agencies[0].name,
                            count: user.agencies.length - 1,
                        },
                    },
                );
        }
    }

    private loadUsers() {
        const {searchQuery} = this.state;
        if (
            searchQuery == null ||
            searchQuery.length < AddUserModal.searchMinCharacters
        ) {
            this.setState({searchStatus: {key: "empty"}});
            return;
        }

        this.props.api.searchUsers(
            searchQuery,
            AddUserModal.searchLimit,
            this.props.selectedAgency.id,
            (result) => {
                if (result.success == true) {
                    this.setState({
                        searchStatus: {
                            key: "ready",
                            results: result.value,
                        },
                    });
                } else {
                    this.props.local.logWarn(
                        `Failed to search for users: ${
                            result.debugMessage || result.message
                        }`,
                    );
                    this.setState({
                        searchStatus: {key: "error", message: result.message},
                    });
                }
            },
        );
    }

    private searchUpdate(value: string | null) {
        this.setState(
            {
                searchQuery: value,
                searchStatus: {key: "loading"},
                confirmStatus: {key: "ready"},
                commonName: {key: "clean"},
                firstName: value != null ? value.split(" ")[0] : "",
                lastName: value != null ? value.split(" ")[1] : "",
            },
            () => this.searchDebouncer.postUpdate(),
        );
    }

    private onSearchResultSelect(userId: string) {
        this.setState((prevState: AddUserModalState, props: AddUserModalProps) => {
            if (prevState.searchStatus.key == "ready") {
                const selectedUser = prevState.searchStatus.results.filter(
                    (r) => r.id == userId,
                )[0];
                if (
                    selectedUser != null &&
                    !selectedUser.hasAgencyWithId(props.selectedAgency.id)
                ) {
                    prevState.confirmStatus = {key: "ready"};
                    prevState.stage = {
                        key: "confirmAdd",
                        user: selectedUser,
                    };
                }
            }
            return prevState;
        });
    }

    private onConfirm() {
        if (this.state.stage.key != "confirmAdd") {
            return;
        }
        const user = this.state.stage.user;
        this.setState({confirmStatus: {key: "loading"}});

        this.props.api.addClientToAgency(
            this.props.selectedAgency.id,
            user.id,
            (result) => {
                if (result.success == true) {
                    this.props.onSelectUser(user.id);
                } else {
                    this.props.local.logWarn(
                        `Failed to add user to agency: ${
                            result.debugMessage || result.message
                        }`,
                    );
                    this.setState({
                        confirmStatus: {key: "error", message: result.message},
                    });
                }
            },
        );
    }

    private onCreate() {
        if (this.state.stage.key != "create") {
            return;
        }

        const {newEmail, firstName, lastName, phone, setPassword, password} =
            this.state;
        const commonName = this.getCommonName();

        if (
            newEmail == null ||
            firstName == null ||
            lastName == null ||
            commonName == null ||
            (setPassword == true && password == null)
        ) {
            return;
        }

        this.setState({createStatus: {key: "loading"}});

        this.props.api.createUserInAgency(
            this.props.selectedAgency.id,
            newEmail,
            commonName,
            firstName,
            lastName,
            phone,
            password,
            (result) => {
                if (result.success == true) {
                    this.props.onSelectUser(result.value.id);
                } else {
                    this.props.local.logWarn(
                        `Failed to add user in agency: ${
                            result.debugMessage || result.message
                        }`,
                    );
                    this.setState({
                        createStatus: {key: "error", message: result.message},
                    });
                }
            },
        );
    }

    private checkEmail() {
        if (
            this.state.newEmail == null ||
            this.state.newEmail.length < AddUserModal.searchMinCharacters ||
            !isValidEmail(this.state.newEmail)
        ) {
            this.setState({emailStatus: {key: "composing"}});
            return;
        }
        this.props.api.searchUsers(
            this.state.newEmail,
            1,
            this.props.selectedAgency.id,
            (result) => {
                if (result.success == true) {
                    if (result.value.length == 0) {
                        this.setState({emailStatus: {key: "ok"}});
                    } else {
                        this.setState({
                            emailStatus: {key: "userExists", user: result.value[0]},
                        });
                    }
                } else {
                    this.props.local.logWarn(
                        `Failed to search for user by email: ${
                            result.debugMessage || result.message
                        }`,
                    );
                    this.setState({
                        emailStatus: {key: "error", message: result.message},
                    });
                }
            },
        );
    }

    private updateEmail(value: string | null) {
        const newEmail = nullIfBlank(value);
        this.setState(
            {
                newEmail: newEmail,
                emailStatus:
                    newEmail == null ? {key: "composing"} : {key: "loading"},
            },
            () => {
                if (this.state.emailStatus.key != "composing") {
                    this.emailDebouncer.postUpdate();
                }
            },
        );
    }

    private getCommonName(): string | null {
        if (this.state.commonName.key == "dirty") {
            return this.state.commonName.value;
        } else if (this.state.firstName != null || this.state.lastName != null) {
            return [this.state.firstName, this.state.lastName]
                .filter((s) => s != null)
                .join(" ");
        } else {
            return null;
        }
    }

    private renderSearchOrCreate(): JSX.Element | null {
        const {stage, searchQuery, searchStatus} = this.state;
        if (stage.key != "searchOrCreate") {
            return null;
        }
        const searchResults =
            searchStatus.key == "ready"
                ? searchStatus.results.map((r) => ({
                    key: r.id,
                    title: r.commonName,
                    description: r.hasAgencyWithId(this.props.selectedAgency.id)
                        ? this.props.t(
                            "members.users.addUserModal.agenciesDescription.alreadyAMember",
                        )
                        : this.getAgenciesDescription(r),
                }))
                : [];

        return (
            <Grid
                centered
                divided
                columns="equal"
                style={{flex: 1, height: "100%"}}
            >
                <Grid.Column
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Header
                        content={
                            <span>
                {this.props.t("members.users.addUserModal.searchForExisting")}
                                <Popup
                                    on="click"
                                    position="right center"
                                    trigger={
                                        <Icon
                                            link
                                            name="help circle"
                                            style={{marginLeft: "3px"}}
                                        />
                                    }
                                    content={this.props.t(
                                        "members.users.addUserModal.searchInfoBody",
                                    )}
                                />
              </span>
                        }
                    />
                    <Search
                        autoFocus
                        fluid
                        placeholder={this.props.t(
                            "members.users.addUserModal.searchPlaceholder",
                        )}
                        showNoResults={searchStatus.key == "ready"}
                        loading={searchStatus.key == "loading"}
                        results={searchResults}
                        value={searchQuery || ""}
                        onSearchChange={(e, d) => this.searchUpdate(nullIfBlank(d.value))}
                        onResultSelect={(e, d) => this.onSearchResultSelect(d.result.key)}
                    />
                    {searchStatus.key == "error" ? (
                        <Message negative content={searchStatus.message}/>
                    ) : null}
                </Grid.Column>
                <Grid.Column
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Header
                        content={
                            <span>
                {this.props.t("members.users.addUserModal.createNewUser")}
              </span>
                        }
                    />
                    <Button
                        primary
                        icon="user"
                        labelPosition="left"
                        content={this.props.t("general.create")}
                        onClick={() =>
                            this.setState({
                                stage: {key: "create"},
                                createStatus: {key: "ready"},
                            })
                        }
                    />
                </Grid.Column>
            </Grid>
        );
    }

    private renderConfirmAdd(): JSX.Element | null {
        const {stage, confirmStatus} = this.state;
        if (stage.key != "confirmAdd") {
            return null;
        }
        return (
            <div style={{flex: 1}}>
                <Message
                    info
                    icon="add user"
                    header={this.props.t(
                        "members.users.addUserModal.addMemberConfirmTitle",
                        {replace: {user: stage.user.commonName}},
                    )}
                    content={this.props.t(
                        "members.users.addUserModal.addMemberConfirmBody",
                        {
                            replace: {
                                user: stage.user.commonName,
                                agency: this.props.selectedAgency.name,
                            },
                        },
                    )}
                />
                {confirmStatus.key == "error" ? (
                    <Message negative content={confirmStatus.message}/>
                ) : null}
            </div>
        );
    }

    private renderUserExistsWarning(): JSX.Element | null {
        const {stage, emailStatus} = this.state;
        if (stage.key != "create" || emailStatus.key != "userExists") {
            return null;
        }
        const secondItem = !emailStatus.user.hasAgencyWithId(
            this.props.selectedAgency.id,
        ) ? (
            <Message.Item>
                <a
                    style={{cursor: "pointer"}}
                    onClick={() =>
                        this.setState((prevState: AddUserModalState) => {
                            if (prevState.emailStatus.key == "userExists") {
                                prevState.confirmStatus = {key: "ready"};
                                prevState.stage = {
                                    key: "confirmAdd",
                                    user: prevState.emailStatus.user,
                                };
                            }
                        })
                    }
                >
                    {this.props.t("members.users.addUserModal.addThisUser", {
                        replace: {agency: this.props.selectedAgency.name},
                    })}
                </a>
            </Message.Item>
        ) : (
            <Message.Item>
                {this.props.t("members.users.addUserModal.userIsAlreadyMember")}
            </Message.Item>
        );
        return (
            <Message warning>
                <Message.Header>
                    {this.props.t("members.users.addUserModal.emailAlreadyExists")}
                </Message.Header>
                <Message.List>
                    <Message.Item>
                        {this.props.t(
                            "members.users.addUserModal.emailExistsUserDescription",
                            {
                                replace: {
                                    user: emailStatus.user.commonName,
                                    agencies: this.getAgenciesDescription(emailStatus.user),
                                },
                            },
                        )}
                    </Message.Item>
                    {secondItem}
                </Message.List>
            </Message>
        );
    }

    private renderCreate(): JSX.Element | null {
        const {
            stage,
            createStatus,
            emailStatus,
            newEmail,
            firstName,
            lastName,
            setPassword,
            password,
            passwordShown,
            phone,
        } = this.state;
        const commonName = this.getCommonName();
        if (stage.key != "create") {
            return null;
        }

        const passwordInput =
            setPassword && emailStatus.key != "userExists" ? (
                <Form.Input
                    autoComplete="new-password"
                    type={passwordShown ? "email" : "password"}
                    label={this.props.t("general.password")}
                    placeholder={this.props.t("general.password")}
                    value={password || ""}
                    icon={
                        <Icon
                            link
                            style={{marginRight: "10px"}}
                            name={passwordShown ? "low vision" : "eye"}
                            onClick={() => this.setState({passwordShown: !passwordShown})}
                        />
                    }
                    onChange={(e, d) => this.setState({password: nullIfBlank(d.value)})}
                />
            ) : undefined;

        return (
            <div style={{flex: 1}}>
                <Form>
                    <Form.Group widths="equal">
                        <Form.Input
                            autoFocus
                            autoComplete="new-password"
                            label={this.props.t("members.users.givenName")}
                            placeholder={this.props.t("members.users.givenName")}
                            value={firstName || ""}
                            onChange={(e, d) =>
                                this.setState({firstName: nullIfBlank(d.value)})
                            }
                        />
                        <Form.Input
                            autoComplete="new-password"
                            label={this.props.t("members.users.surname")}
                            placeholder={this.props.t("members.users.surname")}
                            value={lastName || ""}
                            onChange={(e, d) =>
                                this.setState({lastName: nullIfBlank(d.value)})
                            }
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Input
                            autoComplete="new-password"
                            label={this.props.t("members.users.commonName")}
                            placeholder={this.props.t("members.users.commonName")}
                            value={commonName || ""}
                            onChange={(e, d) =>
                                this.setState({
                                    commonName: {key: "dirty", value: nullIfBlank(d.value)},
                                })
                            }
                        />
                        <Form.Input
                            autoComplete="new-password"
                            label={this.props.t("members.users.phone")}
                            placeholder={this.props.t("members.users.phone")}
                            value={phone || ""}
                            onChange={(e, d) =>
                                this.setState({phone: nullIfBlank(d.value)})
                            }
                        />
                    </Form.Group>
                    <Form.Input
                        autoComplete="new-password"
                        type="email"
                        label={this.props.t("members.users.email")}
                        placeholder={this.props.t("general.email")}
                        value={newEmail || ""}
                        loading={emailStatus.key == "loading"}
                        icon={emailStatus.key == "ok" ? "check" : undefined}
                        onChange={(e, d) => this.updateEmail(nullIfBlank(d.value))}
                    />
                    <Form.Checkbox
                        label={this.props.t("members.users.addUserModal.setPasswordLabel")}
                        checked={setPassword}
                        disabled={emailStatus.key == "userExists"}
                        onClick={() => this.setState({setPassword: !setPassword})}
                    />
                    {passwordInput}
                    {emailStatus.key == "error" ? (
                        <Message negative content={emailStatus.message}/>
                    ) : null}
                    {createStatus.key == "error" ? (
                        <Message negative content={createStatus.message}/>
                    ) : null}
                </Form>
                {this.renderUserExistsWarning()}
            </div>
        );
    }

    render() {
        const {stage, confirmStatus, createStatus} = this.state;
        const backButton =
            stage.key == "confirmAdd" || stage.key == "create" ? (
                <Button
                    content={this.props.t("general.back")}
                    onClick={() => this.setState({stage: {key: "searchOrCreate"}})}
                />
            ) : (
                <Button
                    content={this.props.t("general.cancel")}
                    onClick={this.props.onClose}
                />
            );

        let nextButton = null;
        if (stage.key == "confirmAdd") {
            nextButton = (
                <Button
                    primary
                    content={this.props.t("general.add")}
                    loading={confirmStatus.key == "loading"}
                    onClick={() => this.onConfirm()}
                />
            );
        } else if (stage.key == "create") {
            const commonName = this.getCommonName();
            const canSubmit =
                this.state.newEmail != null &&
                this.state.emailStatus.key == "ok" &&
                (!this.state.setPassword || this.state.password != null) &&
                commonName != null &&
                this.state.firstName != null &&
                this.state.lastName != null;

            nextButton = (
                <Button
                    primary
                    content={this.props.t("general.add")}
                    loading={createStatus.key == "loading"}
                    disabled={!canSubmit}
                    onClick={() => this.onCreate()}
                />
            );
        }

        return (
            <Modal size="small" open={this.props.open} onClose={this.props.onClose}>
                <Modal.Header>
                    {this.props.t("members.users.addUserModal.title")}
                </Modal.Header>
                <Modal.Content
                    style={{height: "430px", display: "flex", paddingBottom: 0}}
                >
                    {this.renderSearchOrCreate()}
                    {this.renderConfirmAdd()}
                    {this.renderCreate()}
                </Modal.Content>
                <Modal.Actions>
                    {backButton}
                    {nextButton}
                </Modal.Actions>
            </Modal>
        );
    }
}

export default withContext(AddUserModal, "api", "local", "i18n");
