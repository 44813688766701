import {
  ApiResult,
  Translation,
  TranslationPhoneme,
  TranslationReplace,
} from "@bryxinc/lunch/models";
import * as React from "react";
import {
  Control,
  Controller,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import {
  AccordionContent,
  AccordionTitle,
  Button,
  Card,
  Dropdown,
  Icon,
  Input,
  Popup,
} from "semantic-ui-react";
import { SemanticLabel, TranslationFormData } from "./translationsTab";
import { PHONEME_EXAMPLES, PHONEME_LITERALS } from "./PhonemeConstants";
import { useState } from "react";
import { PollyResult } from "@bryxinc/lunch/utils/ManagementApi/AgencyMixin";

/// TODO WEDNESDAY: figure out how to represent one key being multiple values

export const scuTTSKeyLabels: {
  key: string;
  text: string;
  value: string[];
}[] = [
  { key: "time", text: "Time", value: ["time"] },
  { key: "dateTime", text: "Date & Time", value: ["dateTime"] },
  { key: "disposition", text: "Disposition", value: ["disposition"] },
  {
    key: "units",
    text: "Units",
    value: ["units", "newUnits", "scuUnits", "newScuUnits", "cadDept"],
  },
  { key: "type", text: "Type", value: ["type"] },
  { key: "typeCode", text: "Type Code", value: ["typeCode"] },
  { key: "typeSection", text: "Type Section", value: ["typeSection"] },
  { key: "typeDesc", text: "Type Description", value: ["typeDesc"] },
  { key: "description", text: "Description", value: ["description"] },
  { key: "synopsis", text: "Synopsis", value: ["synopsis"] },
  { key: "addr", text: "Address", value: ["addr"] },
  {
    key: "locationInfo",
    text: "Location Info",
    value: ["locationInfo"],
  },
  {
    key: "crossStreets",
    text: "Cross Streets",
    value: ["crossStreets"],
  },
  { key: "boxNumber", text: "Box Number", value: ["boxNumber"] },
  { key: "city", text: "City", value: ["city"] },
  { key: "priority", text: "Priority", value: ["priority"] },
  {
    key: "radioChannels",
    text: "Radio Channels",
    value: ["radioChannels"],
  },
];

export type TranslationRowProps = {
  disabled: boolean;
  getPollyTranslation: (
    text: string,
    callback: (result: ApiResult<PollyResult>) => void
  ) => void;
  index: number;
  isDirty: boolean;
  isEditing: boolean | undefined;
  onDelete: () => void;
  onEdit: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  open: boolean;
  RHF: {
    setValue: UseFormSetValue<TranslationFormData>;
    watch: UseFormWatch<TranslationFormData>;
    control: Control<TranslationFormData, unknown>;
  };
  stage: "constant" | "phoneme";
  titleOnClick: () => void;
  translation: Translation;
};

export const TranslationRow = ({
  disabled,
  getPollyTranslation,
  index,
  isDirty,
  isEditing,
  onDelete,
  onEdit,
  onSubmit,
  open,
  RHF,
  stage,
  titleOnClick,
  translation,
}: TranslationRowProps) => {
  const [cursorPosition, setCursorPosition] = useState<number>(0);
  const [pollyRateLimit, setPollyRateLimit] = useState(false);

  return (
    <Card style={{ width: "100%" }}>
      <AccordionTitle
        index={index}
        active={open}
        onClick={titleOnClick}
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingLeft: "1rem",
        }}
        as="selector"
      >
        <div>
          {translation.id === "" ? "New translation" : translation.description}
          {stage == "constant" && (translation as TranslationReplace).regex && (
            <Popup
              content="This translation is managed by Bryx Support and cannot be changed"
              trigger={<Icon name="help" />}
            />
          )}
        </div>
        <Icon name={open ? "angle up" : "angle down"} />
      </AccordionTitle>
      {/* poor man's virtualization */}
      {open && (
        <AccordionContent active={true}>
          <form
            onSubmit={onSubmit}
            style={{ marginLeft: "1rem", marginRight: "1rem" }}
          >
            <div
              style={{
                display: "flex",
                margin: "0.5rem 0 1rem 0",
                columnGap: "1rem",
              }}
            >
              <Controller
                name="description"
                control={RHF.control}
                render={({ field: { value, onChange } }) => (
                  <Input
                    value={disabled ? translation.description ?? "" : value}
                    disabled={disabled}
                    label="Description"
                    placeholder="Enter description..."
                    onChange={onChange}
                    style={{ flex: "5" }}
                  />
                )}
              />

              <div>
                {isEditing ? (
                  <Button
                    color="green"
                    disabled={!isDirty}
                    labelPosition="right"
                    content="Save"
                    type="submit"
                    icon="save outline"
                    style={{ marginRight: 0 }}
                  />
                ) : (
                  <Button
                    color="green"
                    labelPosition="right"
                    content="Edit"
                    type="button"
                    icon="edit outline"
                    style={{ marginRight: 0 }}
                    onClick={onEdit}
                  />
                )}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                margin: "0 0 1rem 0",
                columnGap: "1rem",
                alignItems: "center",
              }}
            >
              <Controller
                name="match"
                control={RHF.control}
                render={({ field: { value, onChange } }) => (
                  <Input
                    value={
                      disabled
                        ? (translation as TranslationReplace).match ?? ""
                        : value
                    }
                    onChange={onChange}
                    disabled={disabled}
                    label="Match"
                    placeholder="Enter text..."
                    style={{ flex: 5 }}
                  />
                )}
              />
              {stage == "constant" && (
                <>
                  <Controller
                    name="replace"
                    control={RHF.control}
                    render={({ field: { value, onChange } }) => (
                      <Input
                        value={
                          disabled
                            ? (translation as TranslationReplace).replace
                            : value
                        }
                        onChange={onChange}
                        disabled={disabled}
                        label="Replace"
                        placeholder="Enter text..."
                        style={{ flex: 5 }}
                      />
                    )}
                  />
                </>
              )}
              {stage == "phoneme" && (
                <Controller
                  name="phoneme"
                  control={RHF.control}
                  render={({ field: { value, onChange } }) => (
                    <Input style={{ flex: 5 }}>
                      <SemanticLabel
                        label="Phoneme"
                        disabled={disabled}
                        style={{ flex: 5 }}
                      >
                        <input
                          onClick={(e) => {
                            const target = e.target as HTMLInputElement;
                            setCursorPosition(target.selectionStart ?? 0);
                          }}
                          onKeyUp={(e) => {
                            const target = e.target as HTMLInputElement;
                            setCursorPosition(target.selectionStart ?? 0);
                          }}
                          value={
                            disabled
                              ? (translation as TranslationPhoneme).phoneme
                              : value
                          }
                          onChange={(e) => {
                            const target = e.target as HTMLInputElement;
                            setCursorPosition(target.selectionStart ?? 0);
                            return onChange(e);
                          }}
                          disabled={disabled}
                          placeholder="Enter text..."
                        />
                      </SemanticLabel>
                    </Input>
                  )}
                />
              )}
            </div>
            {stage == "phoneme" && !disabled && (
              <div
                style={{
                  display: "flex",
                  margin: "0 0 1rem 0",
                  maxWidth: "100%",
                  flexWrap: "wrap",
                  justifyContent: "space-around",
                  rowGap: ".2rem",
                }}
              >
                {PHONEME_LITERALS.split(" ").map((chr, idx) => {
                  const phoneme = RHF.watch("phoneme") ?? "";
                  return (
                    <Popup
                      key={chr}
                      content={PHONEME_EXAMPLES[idx]}
                      trigger={
                        <Button
                          key={chr}
                          type="button"
                          style={{ flexGrow: 10 }}
                          onClick={() => {
                            const newStr =
                              phoneme.slice(0, cursorPosition) +
                              chr +
                              phoneme.slice(cursorPosition, phoneme.length);
                            setCursorPosition(cursorPosition + chr.length);
                            RHF.setValue("phoneme", newStr, {
                              shouldDirty: true,
                            });
                          }}
                        >
                          <div style={{ fontFamily: "DejaVuSans" }}>{chr}</div>
                        </Button>
                      }
                    />
                  );
                })}
              </div>
            )}

            <div
              style={{
                display: "flex",
                margin: "0 0 1rem 0",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <SemanticLabel label="Keys" disabled={disabled}>
                <Controller
                  name="keys"
                  control={RHF.control}
                  render={({ field }) => (
                    <Dropdown
                      {...field}
                      value={disabled ? translation.keys : field.value}
                      onChange={(e, data) => {
                        RHF.setValue("keys", data.value as string[], {
                          shouldDirty: true,
                        });
                      }}
                      multiple
                      selection
                      disabled={disabled}
                      options={scuTTSKeyLabels.map((k) => {
                        return {
                          ...k,
                          value: k.key,
                        };
                      })}
                      style={{
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                        borderLeft: "none",
                        flex: 4,
                      }}
                    />
                  )}
                />
              </SemanticLabel>
              <div>
                {stage == "phoneme" && (
                  <Popup
                    content="You must wait 5 seconds between TTS requests"
                    disabled={!pollyRateLimit}
                    trigger={
                      <span>
                        <Button
                          color="blue"
                          type="button"
                          disabled={disabled || pollyRateLimit}
                          style={{ flex: 5, minWidth: "fit-content" }}
                          onClick={() => {
                            const userInput = RHF.watch("phoneme");
                            if (userInput === undefined) return;
                            const tts = `<speak><phoneme ph="${userInput}">${userInput}</phoneme></speak>`;
                            getPollyTranslation(tts, async (res) => {
                              if (res.success) {
                                try {
                                  new Audio(res.value.pollyUrl).play();
                                  setPollyRateLimit(true);
                                  setTimeout(
                                    () => setPollyRateLimit(false),
                                    5000
                                  );
                                } catch (e) {
                                  console.error("Error processing audio:", e);
                                }
                              }
                            });
                          }}
                        >
                          Test phoneme
                        </Button>
                      </span>
                    }
                  />
                )}
                <Button
                  color="red"
                  labelPosition="right"
                  content="Delete"
                  type="button"
                  icon="trash alternate"
                  onClick={onDelete}
                  style={{
                    marginRight: 0,
                  }}
                />
              </div>
            </div>
          </form>
        </AccordionContent>
      )}
    </Card>
  );
};
