import * as React from "react";
import {SyntheticEvent} from "react";
import {
    Checkbox,
    CheckboxProps,
    Dropdown,
    DropdownProps,
    Header,
    Icon,
    Input,
    Loader,
    Message,
    SemanticWIDTHS,
    Table,
} from "semantic-ui-react";
import {PageResult, SortingConfig, ApiResult} from '@bryxinc/lunch/models';
import {Debouncer, range} from '@bryxinc/lunch/utils/functions';
import {Pagination} from './pagination';
import {withContext, WithTranslation, WithLocal} from '@bryxinc/lunch/context';

type Loader<T> = {
    loadId: string,
    loadItems: (
        limit: number,
        activePage: number,
        searchString: string | null,
        sortConfig: SortingConfig<string>,
        callback: (result: ApiResult<PageResult<T>>) => void,
    ) => void;
};

export interface HeaderDataItem {
    i18nKey?: string;
    width?: SemanticWIDTHS;
    headerKey: string;
}

export interface PaginatedTableProps<T> extends WithTranslation, WithLocal {
    uniqueKey: string;
    rightItem: JSX.Element | null;
    defaultSorting: SortingConfig<string>;
    fixedEntriesPerPage?: number;
    sortable?: boolean;
    leftItem?: JSX.Element | null;
    fixed?: boolean;
    selectable?: boolean;
    footer?: { leftActions: JSX.Element[], rightActions: JSX.Element[] };
    multiselect?: { selectAllAction(value: boolean): void, allSelected: boolean };
    searchable?: boolean;
    headerDataItems: HeaderDataItem[];
    renderItem: (item: T) => JSX.Element;
    zeroStateView?: JSX.Element;
    loader: Loader<T>;
    className?: string;
    style?: React.CSSProperties;
}

export interface PaginatedTableState<T> {
    status: { key: "loading", initial: boolean } |
        { key: "success" } |
        { key: "error", message: string };
    itemCount: number;
    activeItems: T[];
    activePage: number;
    searchString: string | null;
    entriesPerPage: number;
    sorting: SortingConfig<string>;
}

export class PaginatedTable<T> extends React.Component<PaginatedTableProps<T>, PaginatedTableState<T>> {

    private static readonly entriesPerPagePrefKeySuffix = "EntriesPerPage";
    private static readonly sortingPrefKeySuffix = "Sorting";

    private static readonly entriesPerPageOptions = [10, 25, 50, 100];
    private static readonly maxSearchEntries = 100;

    private isComponentMounted: boolean;
    private debouncer = new Debouncer(() => this.loadItems(false, this.props));

    constructor(props: PaginatedTableProps<T>) {
        super(props);

        this.state = this.getInitialState(props);
        this.isComponentMounted = false;
    }

    private getInitialState(props: PaginatedTableProps<T>): PaginatedTableState<T> {
        const entriesPerPage = props.fixedEntriesPerPage != null ?
            props.fixedEntriesPerPage :
            props.local.get<number>(`${props.uniqueKey}${PaginatedTable.entriesPerPagePrefKeySuffix}`) || PaginatedTable.entriesPerPageOptions[0];
        return {
            status: {key: "loading", initial: true},
            itemCount: 0,
            activeItems: [],
            activePage: 1,
            searchString: null,
            entriesPerPage: entriesPerPage,
            sorting: props.local.get<SortingConfig<string>>(`${props.uniqueKey}${PaginatedTable.sortingPrefKeySuffix}`) || props.defaultSorting,
        };
    }

    componentDidMount() {
        this.isComponentMounted = true;
        this.loadItems(true, this.props);
    }

    componentWillUnmount() {
        this.isComponentMounted = false;
    }

    UNSAFE_componentWillReceiveProps(nextProps: Readonly<PaginatedTableProps<T>>) {
        if (nextProps.loader.loadId != this.props.loader.loadId) {
            this.setState(this.getInitialState(nextProps));
            this.loadItems(true, nextProps);
        }
    }

    componentDidUpdate(prevProps: Readonly<PaginatedTableProps<T>>) {
        prevProps.local.set<number>(`${this.props.uniqueKey}${PaginatedTable.entriesPerPagePrefKeySuffix}`, this.state.entriesPerPage);
        prevProps.local.set<SortingConfig<string>>(`${this.props.uniqueKey}${PaginatedTable.sortingPrefKeySuffix}`, this.state.sorting);
    }

    reload() {
        this.loadItems(false, this.props);
    }

    private loadItems(initial: boolean, props: PaginatedTableProps<T>) {
        if (!initial) {
            this.setState({
                status: {key: "loading", initial: false},
            });
        }
        props.loader.loadItems(
            // TODO: [Future] remove maxSearchEntries, we'll always have a count
            this.state.searchString == null ? this.state.entriesPerPage : PaginatedTable.maxSearchEntries,
            this.state.activePage - 1,
            this.state.searchString,
            this.state.sorting,
            (result: ApiResult<PageResult<T>>) => {
                if (!this.isComponentMounted) {
                    return;
                }
                if (result.success == true) {
                    this.setState({
                        status: {
                            key: "success",
                        },
                        itemCount: result.value.count,
                        activeItems: result.value.items,
                    });
                } else {
                    this.props.local.logWarn(`PaginatedTable Failed to Load Data: ${result.debugMessage || result.message}`);
                    this.setState({
                        status: {
                            key: "error",
                            message: result.message,
                        },
                        itemCount: 0,
                        activeItems: [],
                    });
                }
            });
    }

    private setStateAndReload(state: Partial<PaginatedTableState<T>>) {
    this.setState({...this.state, ...state}, () => {
        this.loadItems(false, this.props);
        });
    }

    private onActivePageSelect(selectedPage: number) {
        this.setStateAndReload({activePage: selectedPage});
    }

    resetTable() {
        this.onActivePageSelect(1);
    }

    private onChangeEntriesDropdown(event: SyntheticEvent<HTMLElement>, data: DropdownProps) {
        this.setStateAndReload({
            activePage: 1,
            entriesPerPage: data.value as number,
        });
    }

    private onSearchUpdate(event: KeyboardEvent) {
        const target = event.target as HTMLInputElement;
        this.setState({
            activePage: 1,
            searchString: target.value == "" ? null : target.value,
            status: {key: "loading", initial: false},
        });
        this.debouncer.postUpdate();
    }

    render() {
        const alert = this.state.status.key == "error" ? (
            <Message negative
                     content={this.state.status.message}
                     style={{marginTop: "50px"}}/>
        ) : null;

        const isInitialLoading = this.state.status.key == "loading" && this.state.status.initial;

        const tableData = isInitialLoading ? range(this.state.entriesPerPage).map(n => {
            return (
                <Table.Row key={n}>
                    {range(this.props.headerDataItems.length).map(i => <Table.Cell key={i}>&nbsp;</Table.Cell>)}
                </Table.Row>
            );
        }) : this.state.activeItems.map((user: T) => {
            return this.props.renderItem(user);
        });

        const headerFromItem = (h: HeaderDataItem) => {
            return (
                <Table.HeaderCell key={h.headerKey}
                                  width={h.width}
                                  selectable={h.i18nKey != null}
                                  sorted={this.props.sortable != false && this.state.sorting.column == h.headerKey && h.i18nKey != null ? (this.state.sorting.direction == "asc" ? "ascending" : "descending") : undefined}
                                  onClick={() => {
                                      if (this.props.sortable != false && h.i18nKey != null) {
                                          this.setStateAndReload({
                                              sorting: {
                                                  column: h.headerKey,
                                                  direction: this.state.sorting.column == h.headerKey ? (this.state.sorting.direction == "asc" ? "desc" : "asc") : "asc",
                                              },
                                          });
                                      }
                                  }}>
                    {h.i18nKey != null ? this.props.t(h.i18nKey) : undefined}
                </Table.HeaderCell>
            );
        };

        const footer = this.props.footer != null ? (
            <Table.Footer fullWidth>
                <Table.Row>
                    {this.props.multiselect != null ? (
                        <Table.HeaderCell/>
                    ) : undefined}
                    <Table.HeaderCell colSpan={this.props.headerDataItems.length}>
                        <div style={{float: "right", display: "flex", alignItems: "center"}}>
                            {this.props.footer.rightActions}
                        </div>
                        {this.props.footer.leftActions}
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        ) : undefined;

        const tableInstance = (
            <Table sortable={this.props.sortable == null || this.props.sortable == true}
                   selectable={!isInitialLoading && this.props.selectable == true}
                   fixed={this.props.fixed}
                   striped
                   className="paginatedTable">
                <Table.Header>
                    <Table.Row>
                        {this.props.multiselect != null ? (
                            <Table.HeaderCell style={{textAlign: "center"}}
                                              onClick={() => {
                                                  if (this.props.multiselect != null) {
                                                      this.props.multiselect.selectAllAction(!this.props.multiselect.allSelected);
                                                  }
                                              }}>
                                <Checkbox checked={this.props.multiselect.allSelected}
                                          disabled={this.state.activeItems.length == 0}
                                          onChange={(e, d: CheckboxProps) => {
                                              return this.props.multiselect != null ? this.props.multiselect.selectAllAction(d.checked || false) : undefined;
                                          }}/>
                            </Table.HeaderCell>
                        ) : undefined}
                        {this.props.headerDataItems.map(i => headerFromItem(i))}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {tableData}
                </Table.Body>
                {footer}
            </Table>
        );

        const isError = this.state.status.key == "error";
        // TODO: [Future] Remove searching stipulation
        const isSearching = this.state.searchString != null;
        const pager = (!isInitialLoading && !isSearching && !isError) ? (
            <Pagination
                items={Math.ceil(this.state.itemCount / this.state.entriesPerPage)}
                activePage={this.state.activePage}
                onSelect={this.onActivePageSelect.bind(this)}
                style={{margin: "10px 0"}}
            />
        ) : null;

        const dropdownOptions = PaginatedTable.entriesPerPageOptions.map((option) => ({
            key: option,
            value: option,
            text: this.props.t(`${this.props.uniqueKey}.xEntriesPerPage`, {count: option}),
        }));

        const perPageDropdown = this.props.fixedEntriesPerPage == null ? (
            <Dropdown className="itemsPerPageDropdown"
                      selection
                      style={{marginRight: "10px"}}
                      disabled={this.state.searchString != null}
                      options={dropdownOptions}
                      value={this.state.entriesPerPage}
                      onChange={this.onChangeEntriesDropdown.bind(this)}/>
        ) : null;

        const searchField = this.props.searchable == false ?
            undefined : (
                <Input icon={<Icon link name="search"/>}
                       style={{marginRight: this.props.rightItem != null ? "10px" : undefined}}
                       placeholder={this.props.t("general.search")}
                       loading={this.state.status.key == "loading"}
                       onChange={this.onSearchUpdate.bind(this)}
                       value={this.state.searchString || ""}/>
            );

        const hasZeroState = this.props.zeroStateView != null;
        const showZeroState = this.state.status.key != "loading" && this.state.itemCount == 0 && !isSearching && hasZeroState;
        const hideItems = showZeroState || (isInitialLoading && hasZeroState);

        let leftItem;
        if (hideItems) {
            leftItem = <span/>;
        } else if (this.props.leftItem != null) {
            leftItem = this.props.leftItem;
        } else {
            leftItem = (
                <Header as="h3" style={{marginBottom: 0, marginLeft: "5px"}}>
                    {!(this.state.status.key == "loading" && this.state.status.initial) ? (
                        this.props.t(`${this.props.uniqueKey}.xTotalItems`, {count: this.state.itemCount})
                    ) : null}
                </Header>
            );
        }

        const rightItem = !hideItems ? (
            <div style={{display: "flex", alignItems: "center"}}>
                {perPageDropdown}
                {searchField}
                {this.props.rightItem || <span/>}
            </div>
        ) : <span/>;

        const pagerHtml = !hideItems ? (
            <div className="pagerContainer">
                {pager}
            </div>
        ) : undefined;

        const tableHtml = (
            <div className="tableContainer">
                {tableInstance}
            </div>
        );

        let bodyContent;
        if (showZeroState) {
            bodyContent = this.props.zeroStateView;
        } else if (isInitialLoading && this.props.zeroStateView != null) {
            bodyContent = <Loader active/>;
        } else {
            bodyContent = tableHtml;
        }

        return (
            <div className={this.props.className} style={this.props.style}>
                {alert}
                <div id="tableActionDiv">
                    {leftItem}
                    {rightItem}
                </div>
                {bodyContent}
                {pagerHtml}
            </div>
        );
    }
}

export default withContext(PaginatedTable, 'i18n', 'local');
