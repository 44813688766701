import * as React from "react";
import {Form, Icon, Popup} from "semantic-ui-react";

import {RouteComponentProps} from "react-router";
import BryxApi from "@bryxinc/lunch/utils/ManagementApi";
import {
    withContext,
    WithTranslation,
    WithLocal,
    WithApi,
} from "@bryxinc/lunch/context";

interface SelectPermissionsProps
    extends RouteComponentProps,
        WithTranslation,
        WithLocal,
        WithApi<BryxApi> {
    canSend: boolean;
    canRespond?: boolean;

    onPermissionClick(t: "send" | "respond", v: boolean): void;

    style?: React.CSSProperties;
}

export class SelectPermissions extends React.Component<SelectPermissionsProps> {
    render() {
        const {canSend, canRespond, onPermissionClick, style} = this.props;

        const canRespondCheckbox =
            canRespond != null ? (
                <Form.Checkbox
                    checked={canRespond}
                    onChange={(e, d) => onPermissionClick("respond", d.checked || false)}
                    label={
                        <label>
                            {this.props.t("members.permissions.canRespondTitle")}
                            <Popup
                                trigger={<Icon name={"help circle"}/>}
                                position="right center"
                                content={this.props.t(
                                    "members.permissions.canRespondDescription",
                                )}
                            />
                        </label>
                    }
                />
            ) : undefined;

        return (
            <div>
                <Form.Field style={style}>
                    <label>
                        {this.props.t("members.join-requests.approval.permissions")}
                    </label>
                </Form.Field>
                <Form.Group widths="equal">
                    <Form.Checkbox
                        checked={canSend}
                        onChange={(e, d) => onPermissionClick("send", d.checked || false)}
                        label={
                            <label>
                                {this.props.t("members.permissions.canSendTitle")}
                                <Popup
                                    trigger={<Icon name={"help circle"}/>}
                                    position="right center"
                                    content={this.props.t(
                                        "members.permissions.canSendDescription",
                                    )}
                                />
                            </label>
                        }
                    />
                    {canRespondCheckbox}
                </Form.Group>
            </div>
        );
    }
}

export default withContext(SelectPermissions, "api", "local", "i18n");
