import * as React from "react";
import {
    Button,
    DropdownProps,
    Form,
    Icon,
    Input,
    InputOnChangeData,
    Message,
    Modal,
    Popup,
    Select,
} from "semantic-ui-react";
import {ResponseOption, ResponseOptionTypeEnum} from "@bryxinc/lunch/models";
import BryxApi from "@bryxinc/lunch/utils/ManagementApi";
import {nullIfBlank} from "@bryxinc/lunch/utils/functions";

import {RouteComponentProps} from "react-router";
import {
    withContext,
    WithTranslation,
    WithLocal,
    WithApi,
} from "@bryxinc/lunch/context";

interface ResponseOptionInfoModalProps
    extends RouteComponentProps,
        WithTranslation,
        WithLocal,
        WithApi<BryxApi> {
    agencyId: string;

    onEditComplete(oldResponseId: string, newResponseText: string): void;

    onCreateComplete(): void;

    onCancel(): void;

    viewStatus:
        | { key: "hidden" }
        | { key: "shown"; responseOption?: ResponseOption };
}

interface ResponseOptionInfoModalState {
    responseTextField: string | null;
    responseTypeField: ResponseOptionTypeEnum | null;
    status:
        | { key: "ready" }
        | { key: "loading" }
        | { key: "error"; message: string };
}

export class ResponseOptionInfoModal extends React.Component<ResponseOptionInfoModalProps,
    ResponseOptionInfoModalState> {
    constructor(props: ResponseOptionInfoModalProps) {
        super(props);
        this.state = ResponseOptionInfoModal.getState(props);
    }

    static getState(
        props: ResponseOptionInfoModalProps,
    ): ResponseOptionInfoModalState {
        const response =
            props.viewStatus.key == "shown" ? props.viewStatus.responseOption : null;
        return {
            responseTextField: response != null ? response.text : null,
            responseTypeField:
                response != null ? response.type : ResponseOptionTypeEnum.positive,
            status: {key: "ready"},
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: ResponseOptionInfoModalProps) {
        if (
            this.props.viewStatus.key == "hidden" &&
            nextProps.viewStatus.key == "shown"
        ) {
            this.setState(ResponseOptionInfoModal.getState(nextProps));
        }
    }

    private editResponseOption(oldResponseId: string) {
        const {responseTextField, responseTypeField} = this.state;
        if (responseTextField == null || responseTypeField == null) {
            return;
        }

        this.props.api.editResponseOption(
            this.props.agencyId,
            oldResponseId,
            responseTextField,
            (result) => {
                if (result.success == true) {
                    this.props.onEditComplete(oldResponseId, responseTextField);
                } else {
                    this.setState({
                        status: {key: "error", message: result.message},
                    });
                    this.props.local.logWarn(
                        `Failed to edit response option: ${result.debugMessage}`,
                    );
                }
            },
        );
    }

    private createResponseOption() {
        if (
            this.state.responseTextField == null ||
            this.state.responseTypeField == null
        ) {
            return;
        }

        this.props.api.createResponseOption(
            this.props.agencyId,
            this.state.responseTextField,
            this.state.responseTypeField,
            (result) => {
                if (result.success == true) {
                    this.props.local.logInfo(
                        `Successfully created response option: ${result.value.id}`,
                    );
                    this.props.onCreateComplete();
                } else {
                    this.setState({
                        status: {key: "error", message: result.message},
                    });
                    this.props.local.logWarn(
                        `Failed to create response option: ${result.debugMessage}`,
                    );
                }
            },
        );
    }

    private onChangeResponseText(
        e: React.ChangeEvent<HTMLInputElement>,
        data: InputOnChangeData,
    ) {
        this.setState({responseTextField: nullIfBlank(data.value)});
    }

    private onChangeResponseType(
        e: React.SyntheticEvent<HTMLElement>,
        dropdownProps: DropdownProps,
    ) {
        this.setState({
            responseTypeField: dropdownProps.value as ResponseOptionTypeEnum,
        });
    }

    render() {
        const {onCancel, viewStatus} = this.props;

        const onSubmit = () => {
            this.setState({
                status: {key: "loading"},
            });

            if (viewStatus.key == "shown" && viewStatus.responseOption != null) {
                this.editResponseOption(viewStatus.responseOption.id);
            } else {
                this.createResponseOption();
            }
        };

        const responseTypeOptions = [
            {
                key: "positive",
                text: this.props.t(
                    "agency.responseOptions.responseOptionTypes.positive",
                ),
                value: ResponseOptionTypeEnum.positive,
            },
            {
                key: "negative",
                text: this.props.t(
                    "agency.responseOptions.responseOptionTypes.negative",
                ),
                value: ResponseOptionTypeEnum.negative,
            },
        ];

        return (
            <Modal open={viewStatus.key == "shown"} size="small" onClose={onCancel}>
                <Modal.Header>
                    {this.props.t(
                        `agency.responseOptions.${
                            viewStatus.key == "shown" && viewStatus.responseOption != null
                                ? "editHeader"
                                : "createHeader"
                        }`,
                    )}
                </Modal.Header>
                <Modal.Content>
                    <Form onSubmit={onSubmit}>
                        <Form.Group widths="equal">
                            <Form.Field>
                                <label>
                                    {this.props.t(
                                        "agency.responseOptions.responseOptionInfo.text",
                                    )}
                                </label>
                                <Input
                                    autoFocus
                                    type="text"
                                    value={this.state.responseTextField || ""}
                                    onChange={this.onChangeResponseText.bind(this)}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>
                                    {this.props.t(
                                        "agency.responseOptions.responseOptionInfo.type",
                                    )}
                                    <Popup
                                        content={this.props.t(
                                            "agency.responseOptions.cannotChangeResponseType",
                                        )}
                                        position="right center"
                                        trigger={
                                            <Icon
                                                name="help circle"
                                                link
                                                style={{marginLeft: "5px"}}
                                            />
                                        }
                                    />
                                </label>
                                <Select
                                    options={responseTypeOptions}
                                    disabled={
                                        viewStatus.key == "shown" &&
                                        viewStatus.responseOption != null
                                    }
                                    value={
                                        this.state.responseTypeField ||
                                        ResponseOptionTypeEnum.positive
                                    }
                                    onChange={this.onChangeResponseType.bind(this)}
                                />
                            </Form.Field>
                        </Form.Group>
                    </Form>
                    {this.state.status.key == "error" ? (
                        <Message
                            error
                            style={{marginTop: "10px"}}
                            content={this.state.status.message}
                        />
                    ) : undefined}
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content={this.props.t("general.cancel")}
                        disabled={this.state.status.key == "loading"}
                        onClick={onCancel}
                    />
                    {viewStatus.key == "shown" && viewStatus.responseOption != null ? (
                        <Button
                            primary
                            loading={this.state.status.key == "loading"}
                            disabled={this.state.responseTextField == null}
                            content={this.props.t("general.save")}
                            onClick={onSubmit}
                        />
                    ) : (
                        <Button
                            positive
                            disabled={this.state.responseTextField == null}
                            loading={this.state.status.key == "loading"}
                            content={this.props.t("agency.responseOptions.create")}
                            onClick={onSubmit}
                        />
                    )}
                </Modal.Actions>
            </Modal>
        );
    }
}

export default withContext(ResponseOptionInfoModal, "api", "local", "i18n");
