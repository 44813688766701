import * as React from "react";
import {Button, Form, Message, Modal} from "semantic-ui-react";
import {Apparatus} from "@bryxinc/lunch/models";
import {nullIfBlank} from "@bryxinc/lunch/utils/functions";

import {RouteComponentProps} from "react-router";
import BryxApi from "@bryxinc/lunch/utils/ManagementApi";
import {
    withContext,
    WithTranslation,
    WithLocal,
    WithApi,
} from "@bryxinc/lunch/context";

export type SetApparatusPasswordModalViewStatus =
    | { key: "hidden" }
    | { key: "shown"; apparatus: Apparatus };

export interface SetApparatusPasswordModalProps
    extends RouteComponentProps,
        WithTranslation,
        WithLocal,
        WithApi<BryxApi> {
    viewStatus: SetApparatusPasswordModalViewStatus;
    onDismiss: () => void;
}

interface SetApparatusPasswordModalState {
    status:
        | { key: "ready" }
        | { key: "loading" }
        | { key: "success" }
        | { key: "error"; message: string };
    password: string | null;
    confirmPassword: string | null;
}

type PasswordStatus =
    | { key: "blank" }
    | { key: "mismatch" }
    | { key: "ok"; password: string };

export class SetApparatusPasswordModal extends React.Component<SetApparatusPasswordModalProps,
    SetApparatusPasswordModalState> {
    constructor(props: SetApparatusPasswordModalProps) {
        super(props);
        this.state = SetApparatusPasswordModal.getInitialState();
    }

    private static getInitialState(): SetApparatusPasswordModalState {
        return {
            status: {key: "ready"},
            password: null,
            confirmPassword: null,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: SetApparatusPasswordModalProps) {
        if (
            this.props.viewStatus.key == "hidden" &&
            nextProps.viewStatus.key == "shown"
        ) {
            this.setState(SetApparatusPasswordModal.getInitialState());
        }
    }

    private onSave() {
        const {viewStatus} = this.props;
        const passwordStatus = this.getPasswordStatus();
        if (viewStatus.key != "shown" || passwordStatus.key != "ok") {
            return;
        }
        this.setState({status: {key: "loading"}});
        this.props.api.setApparatusPassword(
            viewStatus.apparatus.id,
            passwordStatus.password,
            (result) => {
                if (result.success == true) {
                    this.props.onDismiss();
                } else {
                    this.props.local.logWarn(
                        `Failed to update apparatus password: ${
                            result.debugMessage || result.message
                        }`,
                    );
                    this.setState({status: {key: "error", message: result.message}});
                }
            },
        );
    }

    private getPasswordStatus(): PasswordStatus {
        const {password, confirmPassword} = this.state;
        if (password == null || confirmPassword == null) {
            return {key: "blank"};
        } else if (password != confirmPassword) {
            return {key: "mismatch"};
        } else {
            return {key: "ok", password: password};
        }
    }

    render() {
        const {viewStatus} = this.props;
        const {status, password, confirmPassword} = this.state;
        const passwordStatus = this.getPasswordStatus();
        return (
            <Modal open={viewStatus.key == "shown"} onClose={this.props.onDismiss}>
                <Modal.Header>
                    {this.props.t("members.apparatus.setPassword.title")}
                </Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Input
                            label={this.props.t("members.apparatus.setPassword.newPassword")}
                            type="password"
                            autoComplete={false}
                            autoCorrect={false}
                            autoCapitalize={false}
                            spellCheck={false}
                            value={password || ""}
                            onChange={(e, d) =>
                                this.setState({password: nullIfBlank(d.value)})
                            }
                        />
                        <Form.Input
                            label={this.props.t(
                                "members.apparatus.setPassword.confirmPassword",
                            )}
                            type="password"
                            autoComplete={false}
                            autoCorrect={false}
                            autoCapitalize={false}
                            spellCheck={false}
                            error={passwordStatus.key == "mismatch"}
                            value={confirmPassword || ""}
                            onChange={(e, d) =>
                                this.setState({confirmPassword: nullIfBlank(d.value)})
                            }
                        />
                    </Form>
                    {status.key == "error" ? (
                        <Message error content={status.message}/>
                    ) : null}
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content={this.props.t("general.cancel")}
                        disabled={status.key == "loading"}
                        onClick={this.props.onDismiss}
                    />
                    <Button
                        primary
                        disabled={passwordStatus.key != "ok"}
                        loading={status.key == "loading"}
                        content={this.props.t("general.save")}
                        onClick={this.onSave.bind(this)}
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}

export default withContext(SetApparatusPasswordModal, "api", "local", "i18n");
