import * as React from "react";
import {
    Button,
    Dropdown,
    Form,
    Header,
    Icon,
    Label,
    Loader,
    Message,
    Popup,
    Table,
} from "semantic-ui-react";
import {ZeroStateView} from "../../components/zeroStateView";
import {
    Agency,
    BasicAgencyGroup,
    AgencyGroupTypeEnum,
    ResponseOption,
    ResponseOptionTypeEnum,
} from "@bryxinc/lunch/models";
import {Debouncer, nullIfBlank} from "@bryxinc/lunch/utils/functions";
import {AgencyProps} from "./agencyPage";
import {DeleteResponseOptionModal} from "./deleteResponseOptionModal";
import {ResponseOptionUsersModal} from "./responseOptionClientMembershipsModal";
import {ResponseOptionInfoModal} from "./responseOptionInfoModal";
import {withContext} from "@bryxinc/lunch/context";

type ResponseOptionsActionStatus =
    | { key: "none" }
    | { key: "info"; responseOption?: ResponseOption }
    | { key: "deleting"; responseOption: ResponseOption }
    | { key: "users"; responseOption: ResponseOption };

type ResponseOptionTypeFilteringOptions = "all" | "positive" | "negative";

interface ResponseOptionsProps extends AgencyProps {
    activeAgency: Agency;
}

interface ResponseOptionsState {
    status:
        | { key: "loading"; initial: boolean }
        | { key: "error"; message: string }
        | { key: "ready" };
    responseOptions: ResponseOption[];
    originalResponseOptions: ResponseOption[];
    dispatchGroupsStatus:
        | { key: "loading" }
        | { key: "ready"; groups: BasicAgencyGroup[] }
        | { key: "error"; message: string };
    actionsStatus: ResponseOptionsActionStatus;
    typesFilter: ResponseOptionTypeFilteringOptions;
    searchString: string | null;
}

export class ResponseOptionsTab extends React.Component<ResponseOptionsProps,
    ResponseOptionsState> {
    private debouncer = new Debouncer(() =>
        this.loadAgencyResponseOptions("filter"),
    );

    constructor(props: ResponseOptionsProps) {
        super(props);

        this.state = {
            status: {key: "loading", initial: true},
            responseOptions: [],
            originalResponseOptions: [],
            actionsStatus: {key: "none"},
            dispatchGroupsStatus: {key: "loading"},
            typesFilter: "all",
            searchString: null,
        };
    }

    componentDidMount() {
        this.loadAgencyResponseOptions("initial");
        this.loadDispatchGroups();
    }

    private loadDispatchGroups() {
        this.props.api.getAllGroupsByType(
            this.props.activeAgency.id,
            AgencyGroupTypeEnum.dispatch,
            (result) => {
                if (result.success == true) {
                    this.setState({
                        dispatchGroupsStatus: {key: "ready", groups: result.value},
                    });
                } else {
                    this.props.local.logWarn(
                        `Error getting all dispatch groups for agency: ${this.props.activeAgency.id}: ${result.debugMessage}`,
                    );
                    this.setState({
                        dispatchGroupsStatus: {
                            key: "error",
                            message: result.message,
                        },
                    });
                }
            },
        );
    }

    private loadAgencyResponseOptions(loadType: "initial" | "full" | "filter") {
        let types = [
            ResponseOptionTypeEnum.positive,
            ResponseOptionTypeEnum.negative,
        ];
        if (this.state.typesFilter == "positive") {
            types = [ResponseOptionTypeEnum.positive];
        } else if (this.state.typesFilter == "negative") {
            types = [ResponseOptionTypeEnum.negative];
        }

        this.setState({
            actionsStatus: {key: "none"},
            status: {key: "loading", initial: loadType == "initial"},
        });

        if (loadType == "full" || loadType == "initial") {
            this.props.api.getAgencyResponseOptions(
                this.props.activeAgency.id,
                null,
                [ResponseOptionTypeEnum.positive, ResponseOptionTypeEnum.negative],
                (result) => {
                    if (result.success == true) {
                        this.setState({
                            originalResponseOptions: result.value.sort(
                                ResponseOption.compare,
                            ),
                        });
                    } else {
                        this.props.local.logWarn(
                            `Error getting agency response options: ${result.debugMessage}`,
                        );
                        this.setState({
                            status: {
                                key: "error",
                                message: result.message,
                            },
                        });
                    }
                },
            );
        }

        this.props.api.getAgencyResponseOptions(
            this.props.activeAgency.id,
            this.state.searchString,
            types,
            (result) => {
                if (result.success == true) {
                    this.setState({
                        status: {
                            key: "ready",
                        },
                        responseOptions: result.value.sort(ResponseOption.compare),
                    });
                } else {
                    this.props.local.logWarn(
                        `Error getting agency response options: ${result.debugMessage}`,
                    );
                    this.setState({
                        status: {
                            key: "error",
                            message: result.message,
                        },
                    });
                }
            },
        );
    }

    private closeModals() {
        this.setState({
            actionsStatus: {key: "none"},
        });
    }

    private onEditSuccess(oldResponseId: string, newResponseText: string) {
        this.props.local.logInfo(
            `Edited response option: ${oldResponseId} -> ${newResponseText}`,
        );
        this.setState({
            actionsStatus: {key: "none"},
        });

        if (this.state.status.key == "ready") {
            this.setState((prevState) => {
                if (prevState.status.key == "ready") {
                    const responseIndex = prevState.responseOptions
                        .map((ro) => ro.id)
                        .indexOf(oldResponseId);
                    prevState.responseOptions[responseIndex].text = newResponseText;
                    prevState.responseOptions.sort(ResponseOption.compare);

                    const originalResponseIndex = prevState.originalResponseOptions
                        .map((ro) => ro.id)
                        .indexOf(oldResponseId);
                    prevState.originalResponseOptions[originalResponseIndex].text =
                        newResponseText;
                    prevState.originalResponseOptions.sort(ResponseOption.compare);
                }
                return prevState;
            });
        }
    }

    private onSearchUpdate(event: KeyboardEvent) {
        const target = event.target as HTMLInputElement;

        this.setState({
            searchString: nullIfBlank(target.value),
            status: {key: "loading", initial: false},
        });

        this.debouncer.postUpdate();
    }

    private onFilterChange(newFilter: ResponseOptionTypeFilteringOptions) {
        this.setState(
            {
                typesFilter: newFilter,
            },
            () => this.loadAgencyResponseOptions("filter"),
        );
    }

    render() {
        const {
            status,
            responseOptions,
            originalResponseOptions,
            actionsStatus,
            dispatchGroupsStatus,
            typesFilter,
            searchString,
        } = this.state;
        if (status.key == "loading" && status.initial) {
            return (
                <div
                    className="underHorizNavContent"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "40px 60px",
                    }}
                >
                    <Loader active/>
                </div>
            );
        }

        if (status.key == "error") {
            return (
                <div
                    className="underHorizNavContent"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "40px 60px",
                    }}
                >
                    <Message negative content={status.message}/>
                </div>
            );
        }

        const responseOptionsTableData = responseOptions.map((responseOption) => {
            return (
                <Table.Row key={responseOption.id}>
                    <Table.Cell>{responseOption.text}</Table.Cell>
                    <Table.Cell>
                        {responseOption.type == ResponseOptionTypeEnum.positive ? (
                            <Label color="green">
                                {this.props.t(
                                    "agency.responseOptions.responseOptionTypes.positive",
                                )}
                            </Label>
                        ) : (
                            <Label color="red">
                                {this.props.t(
                                    "agency.responseOptions.responseOptionTypes.negative",
                                )}
                            </Label>
                        )}
                    </Table.Cell>
                    <Table.Cell
                        textAlign="right"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                        }}
                    >
                        <div
                            id="tableCellButtonActions"
                            style={{marginLeft: ".78571429em"}}
                        >
                            <Dropdown
                                icon={null}
                                style={{margin: "0 10px"}}
                                trigger={<Icon size="large" link name="ellipsis vertical"/>}
                            >
                                <Dropdown.Menu style={{right: 0, left: "auto"}}>
                                    <Dropdown.Item
                                        icon="users"
                                        disabled={dispatchGroupsStatus.key != "ready"}
                                        text={this.props.t(
                                            "agency.responseOptions.actions.viewMembers",
                                        )}
                                        onClick={() =>
                                            this.setState({
                                                actionsStatus: {
                                                    key: "users",
                                                    responseOption: responseOption,
                                                },
                                            })
                                        }
                                    />
                                    <Dropdown.Item
                                        icon="pencil"
                                        text={this.props.t(
                                            "agency.responseOptions.actions.editResponse",
                                        )}
                                        onClick={() =>
                                            this.setState({
                                                actionsStatus: {
                                                    key: "info",
                                                    responseOption: responseOption,
                                                },
                                            })
                                        }
                                    />
                                    <Dropdown.Item
                                        icon="x"
                                        text={this.props.t(
                                            "agency.responseOptions.actions.deleteResponse",
                                        )}
                                        onClick={() =>
                                            this.setState({
                                                actionsStatus: {
                                                    key: "deleting",
                                                    responseOption: responseOption,
                                                },
                                            })
                                        }
                                    />
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Table.Cell>
                </Table.Row>
            );
        });

        const showZeroState =
            status.key == "ready" &&
            responseOptions.length == 0 &&
            typesFilter == "all" &&
            searchString == null;
        const responseOptionsTable = !showZeroState ? (
            <Table striped style={{marginTop: 0, overflowY: "auto"}}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell width="9">
                            {this.props.t("agency.responseOptions.text")}
                        </Table.HeaderCell>
                        <Table.HeaderCell width="3">
                            {this.props.t("agency.responseOptions.type")}
                        </Table.HeaderCell>
                        <Table.HeaderCell width="4"/>
                    </Table.Row>
                </Table.Header>
                <Table.Body>{responseOptionsTableData}</Table.Body>
            </Table>
        ) : (
            <div
                className="flexCenteredContainer"
                style={{height: "100%", width: "100%"}}
            >
                <ZeroStateView
                    header={this.props.t("agency.responseOptions.zeroStateHeader")}
                    subheader={this.props.t("agency.responseOptions.zeroStateSubheader")}
                />
            </div>
        );

        const addButton = (
            <Button
                color="green"
                style={{marginLeft: "10px"}}
                onClick={() =>
                    this.setState({
                        actionsStatus: {key: "info"},
                    })
                }
            >
                {this.props.t("agency.responseOptions.create")}
            </Button>
        );

        const searchField = !showZeroState ? (
            <Form.Input
                icon={<Icon link name="search"/>}
                value={searchString || ""}
                loading={status.key == "loading"}
                placeholder={this.props.t("general.search")}
                onChange={this.onSearchUpdate.bind(this)}
            />
        ) : undefined;

        const typesFilterButton = !showZeroState ? (
            <Popup
                on="click"
                position="bottom right"
                trigger={
                    <Button
                        style={{marginLeft: "10px", marginRight: 0}}
                        color={typesFilter != "all" ? "orange" : undefined}
                        icon="filter"
                    />
                }
                header={this.props.t("agency.responseOptions.typeFilterOptions.header")}
                style={{maxWidth: "none"}}
                content={
                    <div style={{padding: "30px 10px 15px 10px"}}>
                        <Button.Group>
                            <Button
                                toggle
                                style={{width: "135px"}}
                                active={typesFilter == "all"}
                                onClick={() => this.onFilterChange("all")}
                                content={this.props.t(
                                    "agency.responseOptions.typeFilterOptions.all",
                                )}
                            />
                            <Button
                                toggle
                                style={{width: "135px"}}
                                active={typesFilter == "positive"}
                                onClick={() => this.onFilterChange("positive")}
                                content={this.props.t(
                                    "agency.responseOptions.typeFilterOptions.positive",
                                )}
                            />
                            <Button
                                toggle
                                style={{width: "135px"}}
                                active={typesFilter == "negative"}
                                onClick={() => this.onFilterChange("negative")}
                                content={this.props.t(
                                    "agency.responseOptions.typeFilterOptions.negative",
                                )}
                            />
                        </Button.Group>
                    </div>
                }
            />
        ) : undefined;

        return (
            <div className="underHorizNavContent tableUnderNavContent">
                <div id="tableActionDiv">
                    <Header as="h3" style={{marginBottom: 0, marginLeft: "5px"}}>
                        {this.props.t("agency.responseOptions.xTotalItems", {
                            count: responseOptions.length,
                        })}
                    </Header>
                    <div style={{display: "flex"}}>
                        {searchField}
                        {typesFilterButton}
                        {addButton}
                    </div>
                </div>
                <div className="tableContainer" style={{marginBottom: "50px"}}>
                    {responseOptionsTable}
                </div>
                <ResponseOptionUsersModal
                    {...this.props}
                    agencyId={this.props.activeAgency.id}
                    viewStatus={
                        actionsStatus.key == "users" && dispatchGroupsStatus.key == "ready"
                            ? {
                                key: "shown",
                                dispatchGroups: dispatchGroupsStatus.groups,
                                responseOption: actionsStatus.responseOption,
                            }
                            : {
                                key: "hidden",
                            }
                    }
                    onDismiss={() =>
                        this.setState(
                            {
                                actionsStatus: {key: "none"},
                            },
                            () => this.loadAgencyResponseOptions("full"),
                        )
                    }
                />
                <ResponseOptionInfoModal
                    {...this.props}
                    agencyId={this.props.activeAgency.id}
                    onCancel={() => this.closeModals()}
                    onEditComplete={(oldResponseId, newResponseText) =>
                        this.onEditSuccess(oldResponseId, newResponseText)
                    }
                    onCreateComplete={() => this.loadAgencyResponseOptions("full")}
                    viewStatus={
                        actionsStatus.key == "info"
                            ? {
                                key: "shown",
                                responseOption: actionsStatus.responseOption,
                            }
                            : {
                                key: "hidden",
                            }
                    }
                />
                <DeleteResponseOptionModal
                    {...this.props}
                    agencyId={this.props.activeAgency.id}
                    onCancel={() => this.closeModals()}
                    onComplete={() => this.loadAgencyResponseOptions("full")}
                    viewStatus={
                        actionsStatus.key == "deleting" && status.key == "ready"
                            ? {
                                key: "shown",
                                responseOption: originalResponseOptions.filter((r) => {
                                    const selectedResponse =
                                        this.state.actionsStatus.key == "deleting"
                                            ? this.state.actionsStatus.responseOption
                                            : undefined;
                                    return (
                                        selectedResponse != undefined &&
                                        r.id == selectedResponse.id
                                    );
                                })[0],
                                responsesForType: originalResponseOptions.filter((r) => {
                                    const selectedResponse =
                                        this.state.actionsStatus.key == "deleting"
                                            ? this.state.actionsStatus.responseOption
                                            : undefined;
                                    return (
                                        selectedResponse != undefined &&
                                        r.type == selectedResponse.type
                                    );
                                }).length,
                            }
                            : {
                                key: "hidden",
                            }
                    }
                />
            </div>
        );
    }
}

export default withContext(ResponseOptionsTab, "api", "local", "i18n");
