import {FullAgencySCU, RequestUnit, ApiResult} from "@bryxinc/lunch/models";
import * as React from "react";
import {Segment, Form, Table, Checkbox, Message} from "semantic-ui-react";
import {WithApi, WithLocal, WithTranslation} from "@bryxinc/lunch/context";
import BryxApi from "@bryxinc/lunch/utils/ManagementApi";

export type EditSCUAgencyUnitsViewStatus =
    | { key: "hidden" }
    | { key: "shown"; scuId: string; agencyId: string };

interface UnitSettingsProps
    extends WithTranslation,
        WithLocal,
        WithApi<BryxApi> {
    scuId: string;
    agencyId: string;
    viewStatus: EditSCUAgencyUnitsViewStatus;
    onDismiss: (reload: boolean) => void;
}

interface UnitSettingsState {
    unitStatus:
        | { key: "loading" }
        | { key: "ready" }
        | { key: "error"; message: string }
        | { key: "success" };
    allUnits: RequestUnit[];
    scuUnits: RequestUnit[];
    unitSearchQuery: string | null;
    actionStatus:
        | { key: "ready" }
        | { key: "sending" }
        | { key: "error"; message: string };
    // initialLoad: boolean;
}

export class UnitSettings extends React.Component<UnitSettingsProps,
    UnitSettingsState> {
    constructor(props: UnitSettingsProps) {
        super(props);
        this.state = {
            allUnits: [],
            scuUnits: [],
            unitStatus: {key: "loading"},
            actionStatus: {key: "ready"},
            unitSearchQuery: null,
        };
    }

    private onAddUnit(unitId: string) {
        const {viewStatus} = this.props;
        const {scuUnits, allUnits} = this.state;
        if (viewStatus.key != "shown") {
            return;
        }
        this.setState({actionStatus: {key: "sending"}});
        const targetAddedUnit = allUnits.find((u) => u.id === unitId)!;

        this.props.api.addUnitToScu(
            this.props.agencyId,
            this.props.scuId,
            unitId,
            (result: ApiResult<null>) => {
                if (result.success) {
                    this.setState({
                        actionStatus: {key: "ready"},
                        scuUnits: scuUnits.concat([targetAddedUnit]),
                        unitStatus: {key: "success"},
                    });
                } else {
                    this.props.local.logWarn(
                        `Failed to add SCU to unit: ${result.message}`,
                    );
                    this.setState({
                        actionStatus: {key: "error", message: result.message},
                        unitStatus: {key: "error", message: result.message},
                    });
                }
            },
        );
    }

    handleDismiss() {
        if (this.state.unitStatus.key == "success") {
            this.setState({unitStatus: {key: "ready"}});
        }
    }

    private loadUnits() {
        const {viewStatus} = this.props;
        if (viewStatus.key != "shown") {
            return;
        }
        this.props.api.getAllUnits(this.props.agencyId, (result) => {
            if (result.success) {
                let sorted: RequestUnit[] = [];
                if (this.state.scuUnits) {
                    const sorter = (u1: RequestUnit, u2: RequestUnit): number => {
                        const u1Member = this.state.scuUnits.some((cu) => cu.id == u1.id);
                        const u2Member = this.state.scuUnits.some((cu) => cu.id == u2.id);
                        return +u2Member - +u1Member || u1.name.localeCompare(u2.name);
                    };
                    sorted = result.value.sort(sorter);
                }
                this.setState({
                    allUnits: sorted ? sorted : result.value,
                    unitStatus: this.state.scuUnits
                        ? {key: "ready"}
                        : {key: "loading"},
                });
            } else {
                this.setState({
                    actionStatus: {key: "error", message: result.message},
                });
            }
        });

        this.props.api.getAgencySCU(
            this.props.agencyId,
            this.props.scuId,
            (result: ApiResult<FullAgencySCU>) => {
                if (result.success) {
                    let sorted: RequestUnit[] = [];
                    if (this.state.allUnits) {
                        const sorter = (u1: RequestUnit, u2: RequestUnit): number => {
                            const u1Member = result.value.units.some((cu) => cu.id == u1.id);
                            const u2Member = result.value.units.some((cu) => cu.id == u2.id);
                            return +u2Member - +u1Member || u1.name.localeCompare(u2.name);
                        };
                        sorted = this.state.allUnits.sort(sorter);
                    }
                    this.setState({
                        scuUnits: result.value.units,
                        allUnits: sorted,
                        unitStatus: this.state.allUnits
                            ? {key: "ready"}
                            : {key: "loading"},
                    });
                } else {
                    this.setState({
                        unitStatus: {key: "error", message: result.message},
                    });
                }
            },
        );
    }

    componentDidMount() {
        this.loadUnits();
    }

    private onRemoveUnit(unitId: string) {
        const {viewStatus} = this.props;
        if (viewStatus.key != "shown") {
            return;
        }

        const targetScunits = [...this.state.scuUnits].filter(
            (u) => u.id !== unitId,
        );
        this.setState({actionStatus: {key: "sending"}});
        this.props.api.removeUnitToScu(
            this.props.agencyId,
            this.props.scuId,
            unitId,
            (result: ApiResult<null>) => {
                if (result.success) {
                    this.setState({
                        actionStatus: {key: "ready"},
                        scuUnits: targetScunits,
                        unitStatus: {key: "success"},
                    });
                } else {
                    this.props.local.logWarn(
                        `Failed to remove SCU from unit: ${result.message}`,
                    );
                    this.setState({
                        actionStatus: {key: "error", message: result.message},
                        unitStatus: {key: "error", message: result.message},
                    });
                }
            },
        );
    }

    private static getDefaultState(): UnitSettingsState {
        return {
            unitStatus: {key: "loading"},
            unitSearchQuery: null,
            actionStatus: {key: "ready"},
            allUnits: [],
            scuUnits: [],
        };
    }

    componentDidUpdate(nextProps: UnitSettingsProps) {
        if (
            this.props.viewStatus.key == "hidden" &&
            nextProps.viewStatus.key == "shown"
        ) {
            this.setState(UnitSettings.getDefaultState(), () => this.loadUnits());
        }
    }

    render() {
        const {actionStatus, unitSearchQuery, allUnits, unitStatus} = this.state;

        const filteredUnits =
            actionStatus.key == "ready" || actionStatus.key == "sending"
                ? allUnits.filter(
                (u) =>
                    unitSearchQuery == null ||
                    u.name.match(new RegExp(unitSearchQuery, "i")) != null ||
                    u.id.match(new RegExp(unitSearchQuery, "i")) != null,
                )
                : [];

        return (
            <Segment.Group style={{height: "100%"}}>
                <Segment>
                    <Form>
                        <Form.Input
                            value={unitSearchQuery || ""}
                            placeholder={this.props.t(
                                "stationAlerting.configurationModal.units.inputPlaceholder",
                            )}
                            icon={{link: true, name: "search"}}
                            loading={
                                actionStatus.key == "sending" || unitStatus.key == "loading"
                            }
                            onChange={(e, d) => this.setState({unitSearchQuery: d.value})}
                        />
                    </Form>
                    {unitStatus.key == "error" ? (
                        <Message negative content={unitStatus.message}/>
                    ) : null}
                    {actionStatus.key == "error" ? (
                        <Message negative content={actionStatus.message}/>
                    ) : null}
                    {unitStatus.key == "success" ? (
                        <Message
                            onDismiss={this.handleDismiss.bind(this)}
                            positive
                            content={this.props.t(
                                "stationAlerting.configurationModal.saveConfirm",
                            )}
                        />
                    ) : null}

                    <div
                        style={{maxHeight: "500px", margin: "15px 0", overflowY: "auto"}}
                    >
                        <Table>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell width={15}>
                                        {this.props.t(
                                            "stationAlerting.configurationModal.units.unitListLabel",
                                        )}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={1}/>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {filteredUnits.map((u) => (
                                    <Table.Row key={u.id}>
                                        <Table.Cell>{u.name}</Table.Cell>
                                        <Table.Cell>
                                            <Checkbox
                                                toggle
                                                checked={this.state.scuUnits.some(
                                                    (findUnit) => findUnit.id == u.id,
                                                )}
                                                onChange={(e, d) => {
                                                    if (d.checked) {
                                                        this.onAddUnit(u.id);
                                                    } else {
                                                        this.onRemoveUnit(u.id);
                                                    }
                                                }}
                                            />
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    </div>
                </Segment>
            </Segment.Group>
        );
    }
}
