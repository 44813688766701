import * as React from "react";
import {Checkbox, Form, Icon, Popup, Segment} from "semantic-ui-react";
import {ResponseOption, ResponseOptionTypeEnum} from "@bryxinc/lunch/models";
import {RespondOptionSelectItem} from "./respondToJoinRequestModal";

import {RouteComponentProps} from "react-router";
import BryxApi from "@bryxinc/lunch/utils/ManagementApi";
import {
    withContext,
    WithTranslation,
    WithLocal,
    WithApi,
} from "@bryxinc/lunch/context";

interface SelectResponseOptionsProps
    extends RouteComponentProps,
        WithTranslation,
        WithLocal,
        WithApi<BryxApi> {
    responseOptions: RespondOptionSelectItem[];

    onSelect(option: RespondOptionSelectItem): void;

    style?: React.CSSProperties;
}

interface ResponseOptionCardProps {
    onSelect(option: RespondOptionSelectItem): void;

    option: RespondOptionSelectItem;
}

const ResponseOptionCard: React.FunctionComponent<ResponseOptionCardProps> = (
    props,
) => {
    const {onSelect, option} = props;

    return (
        <Segment
            compact
            color={
                option.responseOption.type == ResponseOptionTypeEnum.positive
                    ? "green"
                    : "red"
            }
            style={{cursor: "pointer"}}
            onClick={() => onSelect(option)}
        >
            <div style={{display: "flex", alignItems: "center"}}>
                <Checkbox style={{marginRight: "10px"}} checked={option.selected}/>
                {option.responseOption.text}
            </div>
        </Segment>
    );
};

export class SelectResponseOptions extends React.Component<SelectResponseOptionsProps> {
    public static getWrappedResponseOptions(options: ResponseOption[]) {
        const numPositive = options.filter(
            (r) => r.type == ResponseOptionTypeEnum.positive,
        ).length;
        const numNegative = options.filter(
            (r) => r.type == ResponseOptionTypeEnum.negative,
        ).length;

        return options.map((r) => {
            const selected =
                (r.type == ResponseOptionTypeEnum.positive && numPositive == 1) ||
                (r.type == ResponseOptionTypeEnum.negative && numNegative == 1);

            return {
                responseOption: r,
                selected: selected,
            };
        });
    }

    render() {
        const {onSelect, responseOptions, style} = this.props;

        return (
            <Form.Field style={style}>
                <label>
                    {this.props.t("members.join-requests.approval.responseOptions")}
                    <Popup
                        trigger={<Icon name={"help circle"}/>}
                        position="right center"
                        wide
                        header={this.props.t(
                            "members.join-requests.approval.responseOptionsExplainHeader",
                        )}
                        content={this.props.t(
                            "members.join-requests.approval.responseOptionsExplain",
                        )}
                    />
                </label>
                <div style={{display: "flex", alignItems: "flex-start"}}>
                    <Segment.Group
                        style={{
                            flex: 1,
                            marginRight: "10px",
                            maxHeight: "220px",
                            overflowY: "auto",
                        }}
                    >
                        {responseOptions
                            .filter(
                                (r) => r.responseOption.type == ResponseOptionTypeEnum.positive,
                            )
                            .map((r) => (
                                <ResponseOptionCard
                                    key={r.responseOption.id}
                                    option={r}
                                    onSelect={onSelect}
                                />
                            ))}
                    </Segment.Group>
                    <Segment.Group
                        style={{
                            flex: 1,
                            marginTop: 0,
                            marginLeft: "10px",
                            maxHeight: "220px",
                        }}
                    >
                        {responseOptions
                            .filter(
                                (r) => r.responseOption.type == ResponseOptionTypeEnum.negative,
                            )
                            .map((r) => (
                                <ResponseOptionCard
                                    key={r.responseOption.id}
                                    option={r}
                                    onSelect={onSelect}
                                />
                            ))}
                    </Segment.Group>
                </div>
            </Form.Field>
        );
    }
}

export default withContext(SelectResponseOptions, "api", "local", "i18n");
