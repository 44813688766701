import * as React from "react";
import {RouteComponentProps} from "react-router";
import {withRouter} from "react-router-dom";
import {Menu} from "semantic-ui-react";

export interface RouterMenuItemProps extends RouteComponentProps {
    to: string;
    exact?: boolean;
    children?: React.ReactNode
}

class MenuItemWithoutRouter extends React.Component<RouterMenuItemProps> {
    constructor(props: RouterMenuItemProps) {
        super(props);

        this.handleLink = this.handleLink.bind(this);
    }

    handleLink(path: string) {
        this.props.history.push(path);
    }

    render() {
        const {to, children, exact, location} = this.props;

        let active;
        if (exact == true) {
            active = location.pathname == to;
        } else {
            active = location.pathname.indexOf(to) == 0;
        }

        return (
            <Menu.Item active={active} onClick={() => this.handleLink(to)}>
                {children}
            </Menu.Item>
        );
    }
}

const RouterMenuItem = withRouter(MenuItemWithoutRouter);
export default RouterMenuItem;
