import * as React from "react";
import {Button, Dropdown, Label, Message, Table} from "semantic-ui-react";
import {ZeroStateView} from "../../components/zeroStateView";
import {SCUAlert} from "@bryxinc/lunch/models";
import {range} from "@bryxinc/lunch/utils/functions";
import {ConfirmDeleteStatusNotificationModal} from "./confirmDeleteStatusNotificationModal";
import {CreateEditStatusNotificationModal} from "./createEditStatusNotificationModal";
import {StationAlertingProps} from "./stationAlertingPage";
import {withContext} from "@bryxinc/lunch/context";

export interface StationNotificationsTabState {
    loadStatus:
        | { key: "loading" }
        | { key: "success"; alerts: SCUAlert[] }
        | { key: "error"; message: string };
    overlay:
        | { key: "none" }
        | { key: "create" }
        | { key: "edit"; alert: SCUAlert }
        | { key: "delete"; alert: SCUAlert };
}

export class StatusNotificationsTab extends React.Component<StationAlertingProps,
    StationNotificationsTabState> {
    constructor(props: StationAlertingProps) {
        super(props);
        this.state = StatusNotificationsTab.getInitialState();
    }

    static getInitialState(): StationNotificationsTabState {
        return {
            loadStatus: {key: "loading"},
            overlay: {key: "none"},
        };
    }

    componentDidMount() {
        this.loadAlerts(this.props);
    }

    UNSAFE_componentWillReceiveProps(nextProps: StationAlertingProps) {
        if (nextProps.selectedAgency.id != this.props.selectedAgency.id) {
            this.setState(StatusNotificationsTab.getInitialState(), () =>
                this.loadAlerts(nextProps),
            );
        }
    }

    private loadAlerts(props: StationAlertingProps) {
        this.setState({loadStatus: {key: "loading"}});
        this.props.api.getAgencySCUAlerts(props.selectedAgency.id, (result) => {
            if (result.success) {
                this.setState({loadStatus: {key: "success", alerts: result.value}});
            } else {
                this.props.local.logWarn(
                    `Failed to load agency SCU alerts: ${result.message}`,
                );
                this.setState({
                    loadStatus: {key: "error", message: result.message},
                });
            }
        });
    }

    private renderTableContent(): React.ReactNode {
        const {loadStatus} = this.state;
        if (loadStatus.key == "loading") {
            return (
                <Table.Body>
                    {range(5).map((s) => (
                        <Table.Row key={s} style={{height: "48px"}}>
                            <Table.Cell/>
                            <Table.Cell/>
                            <Table.Cell/>
                            <Table.Cell/>
                        </Table.Row>
                    ))}
                </Table.Body>
            );
        } else if (loadStatus.key == "error") {
            return null;
        }
        return (
            <Table.Body>
                {loadStatus.alerts.map((alert) => (
                    <Table.Row key={alert.id}>
                        <Table.Cell>{alert.name}</Table.Cell>
                        <Table.Cell>
                            <Label.Group>
                                {alert.actions.map((t) => (
                                    <Label
                                        key={t.id}
                                        content={this.props.t(
                                            `stationAlerting.statusNotifications.actionTypes.${t.content.type}`,
                                        )}
                                    />
                                ))}
                            </Label.Group>
                        </Table.Cell>
                        <Table.Cell>
                            <Dropdown
                                pointing="right"
                                icon={{name: "ellipsis vertical", size: "large"}}
                            >
                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        icon="pencil"
                                        text={this.props.t(
                                            "stationAlerting.statusNotifications.editRule",
                                        )}
                                        onClick={() =>
                                            this.setState({overlay: {key: "edit", alert: alert}})
                                        }
                                    />
                                    <Dropdown.Divider/>
                                    <Dropdown.Item
                                        icon="x"
                                        text={this.props.t(
                                            "stationAlerting.statusNotifications.removeRule",
                                        )}
                                        onClick={() =>
                                            this.setState({
                                                overlay: {key: "delete", alert: alert},
                                            })
                                        }
                                    />
                                </Dropdown.Menu>
                            </Dropdown>
                        </Table.Cell>
                    </Table.Row>
                ))}
            </Table.Body>
        );
    }

    render() {
        const {loadStatus, overlay} = this.state;
        return (
            <div className="underHorizNavContent tableUnderNavContent">
                <Message
                    info
                    icon="info circle"
                    header={this.props.t(
                        "stationAlerting.statusNotifications.infoHeader",
                    )}
                    list={[
                        this.props.t("stationAlerting.statusNotifications.infoBody1"),
                        <li key="infoBody2" style={{fontWeight: "bold"}}>
                            {this.props.t("stationAlerting.statusNotifications.infoBody2")}
                        </li>,
                    ]}
                />
                {loadStatus.key == "error" ? (
                    <Message negative content={loadStatus.message}/>
                ) : null}
                <div>
                    <Button
                        positive
                        content={this.props.t("general.create")}
                        style={{float: "right"}}
                        onClick={() => this.setState({overlay: {key: "create"}})}
                    />
                </div>
                {loadStatus.key == "success" && loadStatus.alerts.length == 0 ? (
                    <div
                        className="flexCenteredContainer"
                        style={{width: "100%", height: "100%"}}
                    >
                        <ZeroStateView
                            icon="bullhorn"
                            header={this.props.t(
                                "stationAlerting.statusNotifications.zeroState.header",
                            )}
                            subheader={this.props.t(
                                "stationAlerting.statusNotifications.zeroState.subheader",
                            )}
                        />
                    </div>
                ) : (
                    <Table striped>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell width={7}>
                                    {this.props.t("stationAlerting.statusNotifications.name")}
                                </Table.HeaderCell>
                                <Table.HeaderCell width={8}>
                                    {this.props.t("stationAlerting.statusNotifications.actions")}
                                </Table.HeaderCell>
                                <Table.HeaderCell width={1}/>
                            </Table.Row>
                        </Table.Header>
                        {this.renderTableContent()}
                    </Table>
                )}
                <CreateEditStatusNotificationModal
                    {...this.props}
                    viewStatus={
                        overlay.key == "create"
                            ? {key: "create"}
                            : overlay.key == "edit"
                            ? {key: "edit", alert: overlay.alert}
                            : {key: "hidden"}
                    }
                    selectedAgency={this.props.selectedAgency}
                    onClose={() => this.setState({overlay: {key: "none"}})}
                    onComplete={(result) =>
                        this.setState((prevState: StationNotificationsTabState) => {
                            if (prevState.loadStatus.key == "success") {
                                if (result.key == "onCreate") {
                                    prevState.loadStatus.alerts.push(result.newAlert);
                                } else {
                                    prevState.loadStatus.alerts = prevState.loadStatus.alerts.map(
                                        (a) =>
                                            a.id == result.updatedAlert.id ? result.updatedAlert : a,
                                    );
                                }
                            }
                            prevState.overlay = {key: "none"};
                            return prevState;
                        })
                    }
                />
                <ConfirmDeleteStatusNotificationModal
                    {...this.props}
                    viewStatus={
                        overlay.key == "delete"
                            ? {
                                key: "shown",
                                agencyId: this.props.selectedAgency.id,
                                alert: overlay.alert,
                            }
                            : {key: "hidden"}
                    }
                    onConfirmRemove={(scuAlertId) =>
                        this.setState((prevState: StationNotificationsTabState) => {
                            if (prevState.loadStatus.key == "success") {
                                prevState.loadStatus.alerts =
                                    prevState.loadStatus.alerts.filter((a) => a.id != scuAlertId);
                            }
                            prevState.overlay = {key: "none"};
                            return prevState;
                        })
                    }
                    onClose={() => this.setState({overlay: {key: "none"}})}
                />
            </div>
        );
    }
}

export default withContext(StatusNotificationsTab, "api", "local", "i18n");
