import * as React from "react";
import {Button, Message, Modal} from "semantic-ui-react";

import {FullStationBoard} from "@bryxinc/lunch/models";

import {RouteComponentProps} from "react-router";
import BryxApi from "@bryxinc/lunch/utils/ManagementApi";
import {
    withContext,
    WithTranslation,
    WithLocal,
    WithApi,
} from "@bryxinc/lunch/context";

export type ConfirmDeleteStationBoardModalViewStatus =
    | { key: "hidden" }
    | { key: "shown"; agencyId: string; board: FullStationBoard };

interface ConfirmDeleteStationBoardModalProps
    extends RouteComponentProps,
        WithTranslation,
        WithLocal,
        WithApi<BryxApi> {
    viewStatus: ConfirmDeleteStationBoardModalViewStatus;
    onConfirmRemove: () => void;
    onClose: () => void;
}

interface ConfirmDeleteStationBoardModalState {
    status:
        | { key: "ready" }
        | { key: "loading" }
        | { key: "error"; message: string };
}

export class ConfirmDeleteStationBoardModal extends React.Component<ConfirmDeleteStationBoardModalProps,
    ConfirmDeleteStationBoardModalState> {
    constructor(props: ConfirmDeleteStationBoardModalProps) {
        super(props);
        this.state = ConfirmDeleteStationBoardModal.getInitialState();
    }

    private static getInitialState(): ConfirmDeleteStationBoardModalState {
        return {
            status: {key: "ready"},
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: ConfirmDeleteStationBoardModalProps) {
        if (
            this.props.viewStatus.key == "hidden" &&
            nextProps.viewStatus.key == "shown"
        ) {
            this.setState(ConfirmDeleteStationBoardModal.getInitialState());
        }
    }

    private onSubmit() {
        const {status} = this.state;
        const {viewStatus} = this.props;
        if (status.key == "loading" || viewStatus.key != "shown") {
            return;
        }
        this.setState({status: {key: "loading"}});
        this.props.api.deleteStationBoard(
            viewStatus.agencyId,
            viewStatus.board.id,
            (result) => {
                if (result.success == true) {
                    this.props.onConfirmRemove();
                } else {
                    this.props.local.logWarn(
                        `Failed to delete board: ${result.debugMessage || result.message}`,
                    );
                    this.setState({status: {key: "error", message: result.message}});
                }
            },
        );
    }

    private renderModalContent(): JSX.Element | null {
        const {viewStatus} = this.props;
        const {status} = this.state;
        if (viewStatus.key == "hidden") {
            return <Modal.Content/>;
        }

        return (
            <Modal.Content>
                <Message
                    warning
                    header={this.props.t("stationBoards.delete.header", {
                        replace: {boardName: viewStatus.board.name},
                    })}
                    content={this.props.t("stationBoards.delete.subtext")}
                />
                {status.key == "error" ? (
                    <Message error content={status.message}/>
                ) : null}
            </Modal.Content>
        );
    }

    render() {
        const {viewStatus} = this.props;
        const {status} = this.state;
        return (
            <Modal
                size="small"
                open={viewStatus.key == "shown"}
                onClose={this.props.onClose}
            >
                <Modal.Header>
                    {this.props.t("stationBoards.delete.modalHeader")}
                </Modal.Header>
                {this.renderModalContent()}
                <Modal.Actions>
                    <Button
                        content={this.props.t("general.cancel")}
                        disabled={status.key == "loading"}
                        onClick={this.props.onClose}
                    />
                    <Button
                        negative
                        loading={status.key == "loading"}
                        content={this.props.t("general.remove")}
                        onClick={this.onSubmit.bind(this)}
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}

export default withContext(
    ConfirmDeleteStationBoardModal,
    "api",
    "local",
    "i18n",
);
