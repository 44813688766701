// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ui .disabled{
    opacity: 65% !important;
}

.opaque-disabled-input{
    opacity: 100% !important;
}
`, "",{"version":3,"sources":["webpack://./pages/stationAlerting/translationsTab.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;AAC3B;;AAEA;IACI,wBAAwB;AAC5B","sourcesContent":[".ui .disabled{\n    opacity: 65% !important;\n}\n\n.opaque-disabled-input{\n    opacity: 100% !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
