import * as React from 'react';
import {CSSProperties} from 'react';
import {Icon, SemanticICONS} from 'semantic-ui-react';
import * as colors from '@bryxinc/style/color';

interface ZeroStateViewProps {
    header: React.ReactNode;
    subheader?: React.ReactNode;
    icon?: SemanticICONS;
    keyTooltip?: string;
    maxWidth?: string;
    style?: CSSProperties;
}

export class ZeroStateView extends React.Component<ZeroStateViewProps> {
    render() {
        return (
            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                maxWidth: this.props.maxWidth, ...this.props.style
            }}>
                {
                    this.props.icon != null ? (
                        <Icon circular
                              size="big"
                              name={this.props.icon}
                              style={{color: colors.gray}}/>
                    ) : null
                }
                <span style={{
                    display: "block",
                    color: colors.gray,
                    fontSize: "25px",
                    margin: "15px 10px 12px",
                    textAlign: "center",
                }}>
                    {this.props.header}
                </span>
                {
                    this.props.subheader != null ? (
                        <span style={{
                            display: "block",
                            color: colors.gray,
                            fontSize: "16px",
                            margin: "0 15px",
                            textAlign: "center",
                        }}>
                            {this.props.subheader}
                        </span>
                    ) : null
                }
            </div>
        );
    }
}
