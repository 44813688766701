import * as React from "react";
import { useEffect, useRef, useCallback, useMemo, useState } from "react";
import { useConstCallback } from "powerhooks";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { Form, Header, Loader, Grid, Button } from "semantic-ui-react";
import { LocationSearch, Location } from "./locationSearch";
import { SurveyMap } from "./siteSurveyMap";
import { Polygon, Point } from "geojson";
import { getLocalId, Row, DataRow, DataRowText, DataRowView } from "./dataRows";
import { useAgency } from "./shared";
import {
    AuthAgency,
    Editable,
    SiteSurveyValueType,
    SiteSurveyValueLink,
    SiteSurveyValueFile,
    SiteSurveyValueText,
    SiteSurveyValue,
} from "@bryxinc/lunch/models";
import { useApi, useTranslation } from "@bryxinc/lunch/context";
import { ManagementApi } from "@bryxinc/lunch/utils";
import { ParseResult } from "@bryxinc/lunch";
import { promisify } from "../../utils/promisify";
import { ToolTip } from "../../components/toolTip";

function useQuery<K extends string>(keys: K[]): Partial<Record<K, string>> {
    const { search } = useLocation();

    function isKey(key: string): key is K {
        return (keys as string[]).includes(key);
    }
    const params = useMemo(() => new URLSearchParams(search), [search]);
    const result: Partial<Record<K, string>> = {};
    for (const param of params.keys()) {
        if (isKey(param)) {
            result[param] = params.get(param) ?? undefined;
        }
    }
    return result;
}

function useForwardState<T>(
    defaultValue: (() => T) | T
): [T, (newValue: T) => unknown] {
    const [value, setValue] = useState(defaultValue);
    return [
        value,
        useCallback((newValue: T) => {
            setValue(newValue);
        }, []),
    ];
}

function useLocationData(agencyId: string): {
    location: Location;
    onSearch: () => Promise<unknown>;
    onUpdate: (location: Location) => unknown;
    point: Point | Polygon | undefined;
    setPoint: (point: Point | Polygon) => unknown;
} {
    const [point, setPoint] = useState<Point | Polygon | undefined>(undefined);
    const [location, setLocation] = useState<Location>({
        street: "",
        city: "",
        state: "",
    });
    const onUpdate = useCallback((location: Location) => {
        setLocation(location);
    }, []);

    const { api } = useApi<ManagementApi>();
    // Dumb
    const marker = useRef({});
    const onSearch = useConstCallback(async () => {
        const selfMarker = {};
        marker.current = selfMarker;
        // Do a search
        const geocode = await promisify(
            api,
            api.geocode,
            agencyId,
            location.street,
            location.city,
            location.state
        );
        // Request superceded
        if (marker.current !== selfMarker) {
            return;
        }
        setPoint(geocode.centroid);
    });

    return { onSearch, onUpdate, point, location, setPoint };
}

function intoSurveyValue(row: DataRow): SiteSurveyValue {
    let parseResult: undefined | ParseResult<SiteSurveyValue> = undefined;
    const stem = {
        type: row.type,
        id: row.id || "",
        key: row.key,
        text: row.value,
        expirationTs: null,
    };
    if (row.type == SiteSurveyValueType.link) {
        parseResult = SiteSurveyValueLink.parse({
            ...stem,
            url: row.url,
        });
    } else if (row.type == SiteSurveyValueType.file) {
        parseResult = SiteSurveyValueFile.parse({ ...stem, fileId: row.fileId });
    } else if (row.type == SiteSurveyValueType.text) {
        parseResult = SiteSurveyValueText.parse(stem);
    }
    if (!parseResult?.success) {
        throw new Error("Couldn't build survey value?!");
    }
    return parseResult!.success && parseResult.value;
}

function intoDataRow(
    row: SiteSurveyValueText | SiteSurveyValueLink | SiteSurveyValueFile | null
): DataRow {
    const rowStem = {
        id: row?.id || null,
        key: row?.key || "",
        value: row?.text || "",
        updated: !row,
        deleted: false,
    };
    if (row?.type == SiteSurveyValueType.file) {
        return {
            ...rowStem,
            type: SiteSurveyValueType.file,
            fileId: (row as SiteSurveyValueFile).fileId,
        };
    } else if (row?.type == SiteSurveyValueType.text || row === null) {
        return {
            ...rowStem,
            type: SiteSurveyValueType.text,
        };
    } else if (row?.type == SiteSurveyValueType.link) {
        return {
            ...rowStem,
            type: SiteSurveyValueType.link,
            url: (row as SiteSurveyValueLink).url,
        };
    }
    return null as never;
}

async function updateRow(
    api: ManagementApi,
    agencyId: string,
    surveyId: string,
    valueType: "advisories" | "informationals",
    row: DataRow
) {
    if (row.id) {
        if (row.deleted) {
            await promisify(
                api,
                api.deleteSiteSurveyValue,
                agencyId,
                surveyId,
                row.id,
                valueType
            );
        } else if (row.updated) {
            await promisify(
                api,
                api.editSiteSurveyValue,
                agencyId,
                surveyId,
                row.id,
                valueType,
                row.type == SiteSurveyValueType.link ? row.url : null,
                intoSurveyValue(row)
            );
        }
    } else {
        await promisify(
            api,
            api.addSiteSurveyValue,
            agencyId,
            surveyId,
            valueType,
            intoSurveyValue(row)
        );
    }
}

export function SiteSurveyForm({
                                   selectedAgency,
                               }: {
    selectedAgency: AuthAgency;
}) {
    const agency = useAgency(selectedAgency.id);
    const selectedAgencyId = selectedAgency.id;
    const { surveyId } = useParams<{ surveyId?: string }>();
    const { from: fromType } = useQuery(["from"]);

    useEffect(() => {
        if (surveyId) {
            promisify(api, api.getSiteSurvey, selectedAgencyId, surveyId).then(
                (res) => {
                    if (!res) {
                        console.error("Site survey doesn't exist?!");
                        return;
                    }
                    if (res.location.type == "Point" || res.location.type == "Polygon") {
                        setPoint(res.location);
                    } else if (res.location.type == "MultiPolygon") {
                        setPoint({ type: "Polygon", coordinates: res.location.coordinates[0] });
                    }
                    setCritical(intoDataRow(res.critical) as DataRowText);
                    setAdvisories(res.advisories.map(intoDataRow));
                    setInformational(res.informationals.map(intoDataRow));
                    onUpdate({
                        street: res.address?.road
                            ? res.address.houseNumber + " " + res.address.road
                            : "",
                        city: res.address?.city || "",
                        state: res.address?.state || "",
                    });
                    setLoading(false);
                }
            );
        }
    }, [surveyId]);

    const { location, onSearch, onUpdate, point, setPoint } =
        useLocationData(selectedAgencyId);
    const [advisories, setAdvisories] = useForwardState<DataRow[]>([]);
    const [informational, setInformational] = useForwardState<DataRow[]>(() =>
        fromType == "template"
            ? [
                {
                    id: null,
                    key: "First Hose Run",
                    value: "",
                    type: SiteSurveyValueType.text,
                    updated: true,
                    deleted: false,
                    localId: String(getLocalId()),
                },
                {
                    id: null,
                    key: "Second Hose Run",
                    value: "",
                    type: SiteSurveyValueType.text,
                    updated: true,
                    deleted: false,
                    localId: String(getLocalId()),
                },
                {
                    id: null,
                    key: "First Hydrant Location",
                    value: "",
                    type: SiteSurveyValueType.text,
                    updated: true,
                    deleted: false,
                    localId: String(getLocalId()),
                },
                {
                    id: null,
                    key: "Second Hydrant Location",
                    value: "",
                    type: SiteSurveyValueType.text,
                    updated: true,
                    deleted: false,
                    localId: String(getLocalId()),
                },
                {
                    id: null,
                    key: "Hydrant Size",
                    value: "",
                    type: SiteSurveyValueType.text,
                    updated: true,
                    deleted: false,
                    localId: String(getLocalId()),
                },
                {
                    id: null,
                    key: "Fire Jurisdiction",
                    value: "",
                    type: SiteSurveyValueType.text,
                    updated: true,
                    deleted: false,
                    localId: String(getLocalId()),
                },
                {
                    id: null,
                    key: "EMS Jurisdiction",
                    value: "",
                    type: SiteSurveyValueType.text,
                    updated: true,
                    deleted: false,
                    localId: String(getLocalId()),
                },
                {
                    id: null,
                    key: "Police Jurisdiction",
                    value: "",
                    type: SiteSurveyValueType.text,
                    updated: true,
                    deleted: false,
                    localId: String(getLocalId()),
                },
                {
                    id: null,
                    key: "Owner's Last Name",
                    value: "",
                    type: SiteSurveyValueType.text,
                    updated: true,
                    deleted: false,
                    localId: String(getLocalId()),
                },
                {
                    id: null,
                    key: "Owner's Phone",
                    value: "",
                    type: SiteSurveyValueType.text,
                    updated: true,
                    deleted: false,
                    localId: String(getLocalId()),
                },
                {
                    id: null,
                    key: "Company",
                    value: "",
                    type: SiteSurveyValueType.text,
                    updated: true,
                    deleted: false,
                    localId: String(getLocalId()),
                },
                {
                    id: null,
                    key: "Automatic Alarm Installed",
                    value: "",
                    type: SiteSurveyValueType.text,
                    updated: true,
                    deleted: false,
                    localId: String(getLocalId()),
                },
                {
                    id: null,
                    key: "Permit Number",
                    value: "",
                    type: SiteSurveyValueType.text,
                    updated: true,
                    deleted: false,
                    localId: String(getLocalId()),
                },
                {
                    id: null,
                    key: "Alarm Company",
                    value: "",
                    type: SiteSurveyValueType.text,
                    updated: true,
                    deleted: false,
                    localId: String(getLocalId()),
                },
                {
                    id: null,
                    key: "Panel Location",
                    value: "",
                    type: SiteSurveyValueType.text,
                    updated: true,
                    deleted: false,
                    localId: String(getLocalId()),
                },
                {
                    id: null,
                    key: "Contact #1",
                    value: "",
                    type: SiteSurveyValueType.text,
                    updated: true,
                    deleted: false,
                    localId: String(getLocalId()),
                },
                {
                    id: null,
                    key: "Contact #1 Phone",
                    value: "",
                    type: SiteSurveyValueType.text,
                    updated: true,
                    deleted: false,
                    localId: String(getLocalId()),
                },
                {
                    id: null,
                    key: "Contact #2",
                    value: "",
                    type: SiteSurveyValueType.text,
                    updated: true,
                    deleted: false,
                    localId: String(getLocalId()),
                },
                {
                    id: null,
                    key: "Contact #2 Phone",
                    value: "",
                    type: SiteSurveyValueType.text,
                    updated: true,
                    deleted: false,
                    localId: String(getLocalId()),
                },
                {
                    id: null,
                    key: "Construction Type",
                    value: "",
                    type: SiteSurveyValueType.text,
                    updated: true,
                    deleted: false,
                    localId: String(getLocalId()),
                },
                {
                    id: null,
                    key: "Number of Stories",
                    value: "",
                    type: SiteSurveyValueType.text,
                    updated: true,
                    deleted: false,
                    localId: String(getLocalId()),
                },
                {
                    id: null,
                    key: "Roof Construction",
                    value: "",
                    type: SiteSurveyValueType.text,
                    updated: true,
                    deleted: false,
                    localId: String(getLocalId()),
                },
                {
                    id: null,
                    key: "Electricity Shutoff",
                    value: "",
                    type: SiteSurveyValueType.text,
                    updated: true,
                    deleted: false,
                    localId: String(getLocalId()),
                },
                {
                    id: null,
                    key: "Gas Shutoff",
                    value: "",
                    type: SiteSurveyValueType.text,
                    updated: true,
                    deleted: false,
                    localId: String(getLocalId()),
                },
                {
                    id: null,
                    key: "Water Shutoff",
                    value: "",
                    type: SiteSurveyValueType.text,
                    updated: true,
                    deleted: false,
                    localId: String(getLocalId()),
                },
                {
                    id: null,
                    key: "Sprinkler System",
                    value: "",
                    type: SiteSurveyValueType.text,
                    updated: true,
                    deleted: false,
                    localId: String(getLocalId()),
                },
                {
                    id: null,
                    key: "Sprinkler Connection Location",
                    value: "",
                    type: SiteSurveyValueType.text,
                    updated: true,
                    deleted: false,
                    localId: String(getLocalId()),
                },
            ]
            : []
    );
    const [critical, setCritical] = useForwardState<DataRowText>({
        id: null,
        key: "Critical",
        value: "",
        updated: true,
        type: SiteSurveyValueType.text,
        deleted: false,
    });

    const [loading, setLoading] = useState(!!surveyId);

    const { t } = useTranslation();

    const { api } = useApi<ManagementApi>();
    const history = useHistory();

    const saveInner = useConstCallback(async () => {
        // TODO: Step 1: Validate form
        if (!point) {
            return;
        }
        // Step 2: Action

        const address = location.street
            ? `${location.street}, ${location.city}, ${location.state}`
            : "";

        if (surveyId) {
            // TODO: Edit existing
            await Promise.all([
                ...advisories.map(
                    updateRow.bind(null, api, selectedAgencyId, surveyId, "advisories")
                ),
                ...informational.map(
                    updateRow.bind(
                        null,
                        api,
                        selectedAgencyId,
                        surveyId,
                        "informationals"
                    )
                ),
            ]);
            await promisify(
                api,
                api.editSiteSurvey,
                selectedAgencyId,
                surveyId,
                Editable.set(address),
                null,
                Editable.set(intoSurveyValue(critical)),
                point!
            );
        } else {
            await promisify(
                api,
                api.addSiteSurvey,
                selectedAgencyId,
                address,
                null,
                intoSurveyValue(critical),
                advisories.filter((row) => !row.deleted).map(intoSurveyValue),
                informational.filter((row) => !row.deleted).map(intoSurveyValue),
                point!
            );
        }
    });
    const [saving, setSaving] = useState(false);
    const save = useConstCallback(() => {
        setSaving(true);
        saveInner()
            .then(() => {
                // Send the user to the list
                history.push("/site-surveys/search");
            })
            .catch((e) => {
                console.error(e);
            })
            .finally(() => setSaving(false));
    });

    const deleteInner = useConstCallback(async () => {
        if (!surveyId) {
            return;
        }

        await promisify(
            api,
            api.deleteSiteSurvey,
            selectedAgencyId,
            surveyId,
        );
    });
    const [deleting, setDeleting] = useState(false);
    const deleteSurvey = useConstCallback(() => {
        setDeleting(true);
        deleteInner()
            .then(() => {
                // Send the user to the list
                history.push("/site-surveys/search")
            })
            .catch((e) => {
                console.error(e);
            })
            .finally(() => setDeleting(false));
    });

    const incompleteRows = useMemo(
        () =>
            [...informational, ...advisories].some(
                (item) =>
                    !item.key ||
                    !item.value ||
                    (item.type == "file" && !item.fileId) ||
                    (item.type == "link" && !item.url)
            ),
        [informational, advisories]
    );
    const content = loading ? (
        <Loader />
    ) : (
        <div
            className="underHorizNavContent"
            style={{ padding: "40px 60px", overflowY: "auto", height: "100%" }}
        >
            <Form>
                <Header size="large">
                    {surveyId
                        ? t("siteSurvey.siteSurveyModal.editSurvey")
                        : t("siteSurvey.siteSurveyModal.createSurvey")}
                </Header>
                <Header size="medium">
                    {t("siteSurvey.siteSurveyModal.addressOrRegion")}
                </Header>
                <Grid className="siteSurveyGrid">
                    <Grid.Row columns={2}>
                        <Grid.Column key={0}>
                            {agency && (
                                <SurveyMap
                                    agency={agency}
                                    point={point}
                                    center={point}
                                    setPoint={setPoint}
                                    showHint
                                />
                            )}
                        </Grid.Column>
                        <Grid.Column key={1} verticalAlign="middle">
                            <LocationSearch
                                tall={true}
                                onUpdate={onUpdate}
                                onSearch={onSearch}
                                location={location}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

                <Header size="medium">
                    {t("siteSurvey.siteSurveyModal.critical")}
                    <ToolTip
                        content={t("siteSurvey.siteSurveyModal.criticalHelp")}
                        radius={24}
                    />
                </Header>
                <Row
                    agencyId={selectedAgencyId}
                    type={critical.type}
                    row={critical}
                    onChange={setCritical}
                    canDelete={false}
                    required={false}
                    hideKey={true}
                    textPlaceholder={t("siteSurvey.siteSurveyModal.knownHoarder")}
                />

                <Header size="medium">
                    {t("siteSurvey.siteSurveyModal.advisory")}
                    <ToolTip
                        content={t("siteSurvey.siteSurveyModal.advisoryHelp")}
                        radius={24}
                    />
                </Header>
                <DataRowView
                    agencyId={selectedAgencyId}
                    rows={advisories}
                    onChange={setAdvisories}
                    defaultKey="Advisory"
                    maxRows={3}
                    textPlaceholder={t("siteSurvey.siteSurveyModal.knoxBoxAtLocation")}
                />

                <Header size="medium">
                    {t("siteSurvey.siteSurveyModal.informational")}
                    <ToolTip
                        content={t("siteSurvey.siteSurveyModal.informationalHelp")}
                        radius={24}
                    />
                </Header>
                <DataRowView
                    agencyId={selectedAgencyId}
                    rows={informational}
                    onChange={setInformational}
                    maxRows={Infinity}
                    textPlaceholder=""
                />

                <Button
                    primary
                    onClick={save}
                    loading={saving}
                    disabled={saving || !point || incompleteRows}
                >
                    {t("general.save")}
                </Button>
                <Link to="/site-surveys">
                    <Button>{t("general.cancel")}</Button>
                </Link>
                <Button
                    color="red"
                    floated="right"
                    onClick={deleteSurvey}
                    loading={deleting}
                    disabled={deleting || !point || incompleteRows}
                >
                    {t("general.delete")}
                </Button>
            </Form>
        </div>
    );

    return content;
}
