import * as React from "react";
import {
    Button,
    Form,
    Icon,
    Input,
    Message,
    Modal,
    Popup,
} from "semantic-ui-react";
import {
    Apparatus,
    ApparatusTypeEnum,
    AuthAgency,
} from "@bryxinc/lunch/models";
import {enumNames, nullIfBlank} from "@bryxinc/lunch/utils/functions";

import {RouteComponentProps} from "react-router";
import BryxApi from "@bryxinc/lunch/utils/ManagementApi";
import {
    withContext,
    WithTranslation,
    WithLocal,
    WithApi,
} from "@bryxinc/lunch/context";

export type CreateEditApparatusModalViewStatus =
    | { key: "hidden" }
    | { key: "create" }
    | { key: "edit"; apparatus: Apparatus };

interface CreateEditApparatusModalProps
    extends RouteComponentProps,
        WithTranslation,
        WithLocal,
        WithApi<BryxApi> {
    viewStatus: CreateEditApparatusModalViewStatus;
    selectedAgency: AuthAgency;
    onClose: () => void;
    onComplete: (updatedApparatus: Apparatus) => void;
}

interface CreateEditApparatusModalState {
    actionStatus:
        | { key: "ready" }
        | { key: "loading" }
        | { key: "error"; message: string };
    name: string | null;
    apparatusId: string | null;
    apparatusType: ApparatusTypeEnum;
    password: string | null;
    confirmPassword: string | null;
}

type SubmissionStatus =
    | { key: "incomplete" }
    | { key: "createOk"; name: string; apparatusId: string; password: string }
    | {
    key: "editOk";
    name: string | null;
    apparatusId: string | null;
    apparatusType: ApparatusTypeEnum | null;
};
type PasswordStatus =
    | { key: "blank" }
    | { key: "mismatch" }
    | { key: "ok"; password: string };
type ApparatusIdStatus =
    | { key: "blank" }
    | { key: "tooLong" }
    | { key: "ok"; apparatusId: string };

export class CreateEditApparatusModal extends React.Component<CreateEditApparatusModalProps,
    CreateEditApparatusModalState> {
    static readonly maxApparatusIdLength = 6;

    constructor(props: CreateEditApparatusModalProps) {
        super(props);
        this.state = CreateEditApparatusModal.getDefaultState(props);
    }

    UNSAFE_componentWillReceiveProps(nextProps: CreateEditApparatusModalProps) {
        if (
            this.props.viewStatus.key == "hidden" &&
            nextProps.viewStatus.key != "hidden"
        ) {
            this.setState(CreateEditApparatusModal.getDefaultState(nextProps));
        }
    }

    private static getDefaultState(
        props: CreateEditApparatusModalProps,
    ): CreateEditApparatusModalState {
        if (props.viewStatus.key == "hidden" || props.viewStatus.key == "create") {
            return {
                actionStatus: {key: "ready"},
                name: null,
                apparatusId: null,
                apparatusType: ApparatusTypeEnum.other,
                password: null,
                confirmPassword: null,
            };
        } else {
            const {apparatus} = props.viewStatus;
            return {
                actionStatus: {key: "ready"},
                name: apparatus.name,
                apparatusId: apparatus.apparatusId,
                apparatusType: apparatus.apparatusType,
                password: null,
                confirmPassword: null,
            };
        }
    }

    private onUpdateName(value: string | null) {
        this.setState((prevState: CreateEditApparatusModalState) => {
            prevState.name = value;
            if (value != null) {
                const lowerValue = value.toLowerCase();
                const suggestedTypeName = enumNames(ApparatusTypeEnum).filter(
                    (typeName) => {
                        const localizedTypeName = this.props.t(
                            `members.apparatus.typeNames.${typeName}`,
                        );
                        const lowerLocalizedTypeName = localizedTypeName.toLowerCase();
                        return lowerValue.indexOf(lowerLocalizedTypeName) == 0;
                    },
                )[0];
                if (suggestedTypeName != null) {
                    prevState.apparatusType = ApparatusTypeEnum[suggestedTypeName as keyof typeof ApparatusTypeEnum];
                }
            }
            return prevState;
        });
    }

    private getPasswordStatus(): PasswordStatus {
        const {password, confirmPassword} = this.state;
        if (password == null || confirmPassword == null) {
            return {key: "blank"};
        } else if (password != confirmPassword) {
            return {key: "mismatch"};
        } else {
            return {key: "ok", password: password};
        }
    }

    private getApparatusIdStatus(): ApparatusIdStatus {
        const {apparatusId} = this.state;
        if (apparatusId == null) {
            return {key: "blank"};
        } else if (
            apparatusId.length > CreateEditApparatusModal.maxApparatusIdLength
        ) {
            return {key: "tooLong"};
        } else {
            return {key: "ok", apparatusId: apparatusId};
        }
    }

    private getSubmissionStatus(): SubmissionStatus {
        const {name, apparatusType} = this.state;
        const {viewStatus} = this.props;
        const passwordStatus = this.getPasswordStatus();
        const apparatusIdStatus = this.getApparatusIdStatus();
        if (
            name == null ||
            apparatusIdStatus.key != "ok" ||
            viewStatus.key == "hidden"
        ) {
            return {key: "incomplete"};
        } else if (viewStatus.key == "create") {
            return passwordStatus.key != "ok"
                ? {key: "incomplete"}
                : {
                    key: "createOk",
                    name: name,
                    apparatusId: apparatusIdStatus.apparatusId,
                    password: passwordStatus.password,
                };
        } else {
            const {apparatus} = viewStatus;
            const setName = apparatus.name != name ? name : null;
            const setApparatusId =
                apparatus.apparatusId != apparatusIdStatus.apparatusId
                    ? apparatusIdStatus.apparatusId
                    : null;
            const setApparatusType =
                apparatus.apparatusType != apparatusType ? apparatusType : null;
            const isDirty =
                setName != null || setApparatusId != null || setApparatusType != null;
            return isDirty
                ? {
                    key: "editOk",
                    name: setName,
                    apparatusId: setApparatusId,
                    apparatusType: setApparatusType,
                }
                : {key: "incomplete"};
        }
    }

    private onCreate() {
        const submissionStatus = this.getSubmissionStatus();
        const {viewStatus} = this.props;
        if (viewStatus.key != "create" || submissionStatus.key != "createOk") {
            return;
        }
        const {name, apparatusId, password} = submissionStatus;
        const {apparatusType} = this.state;
        this.setState({actionStatus: {key: "loading"}});
        this.props.api.createApparatusInAgency(
            this.props.selectedAgency.id,
            name,
            apparatusId,
            apparatusType,
            password,
            (result) => {
                if (result.success == true) {
                    this.props.onComplete(result.value);
                } else {
                    this.props.local.logWarn(
                        `Failed to create apparatus in agency: ${
                            result.debugMessage || result.message
                        }`,
                    );
                    this.setState({
                        actionStatus: {key: "error", message: result.message},
                    });
                }
            },
        );
    }

    private onEdit() {
        const submissionStatus = this.getSubmissionStatus();
        const {viewStatus} = this.props;
        if (viewStatus.key != "edit" || submissionStatus.key != "editOk") {
            return;
        }
        const {name, apparatusId, apparatusType} = submissionStatus;
        this.setState({actionStatus: {key: "loading"}});
        this.props.api.updateApparatusInfo(
            viewStatus.apparatus.id,
            name,
            apparatusId,
            apparatusType,
            (result) => {
                if (result.success == true) {
                    this.props.onComplete(result.value);
                } else {
                    this.props.local.logWarn(
                        `Failed to update apparatus info: ${
                            result.debugMessage || result.message
                        }`,
                    );
                    this.setState({
                        actionStatus: {key: "error", message: result.message},
                    });
                }
            },
        );
    }

    private renderCreate(): JSX.Element | null {
        const {
            name,
            apparatusId,
            apparatusType,
            actionStatus,
            password,
            confirmPassword,
        } = this.state;
        const {viewStatus} = this.props;
        return (
            <Form>
                <Form.Input
                    autoFocus={viewStatus.key == "create"}
                    autoComplete="new-password"
                    label={this.props.t("members.apparatus.name")}
                    placeholder={this.props.t("members.apparatus.name")}
                    value={name || ""}
                    onChange={(e, d) => this.onUpdateName(nullIfBlank(d.value))}
                />
                <Form.Field error={this.getApparatusIdStatus().key == "tooLong"}>
                    <label>
                        {this.props.t("members.apparatus.apparatusId")}
                        <Popup
                            position="right center"
                            trigger={
                                <Icon link name="help circle" style={{marginLeft: "3px"}}/>
                            }
                            content={
                                <div>
                                    <p>
                                        {this.props.t(
                                            "members.apparatus.createEditApparatusModal.apparatusIdDescription",
                                            {
                                                replace: {
                                                    maxLength:
                                                    CreateEditApparatusModal.maxApparatusIdLength,
                                                },
                                            },
                                        )}
                                    </p>
                                    <p>
                                        {this.props.t(
                                            "members.apparatus.createEditApparatusModal.apparatusIdExample",
                                        )}
                                    </p>
                                </div>
                            }
                        />
                    </label>
                    <Input
                        autoComplete="new-password"
                        label={`@${this.props.selectedAgency.agencyKey}`}
                        labelPosition="right"
                        placeholder={this.props.t("members.apparatus.apparatusId")}
                        value={apparatusId || ""}
                        onChange={(e, d) =>
                            this.setState({apparatusId: nullIfBlank(d.value)})
                        }
                    />
                </Form.Field>
                <Form.Dropdown
                    placeholder={this.props.t("members.apparatus.apparatusType")}
                    label={this.props.t("members.apparatus.apparatusType")}
                    search
                    selection
                    value={ApparatusTypeEnum[apparatusType]}
                    options={enumNames(ApparatusTypeEnum).map((typeName) => ({
                        key: typeName,
                        value: typeName,
                        text: this.props.t(`members.apparatus.typeNames.${typeName}`),
                    }))}
                    onChange={(e, d) =>
                        this.setState({
                            apparatusType: d.value as ApparatusTypeEnum,
                        })
                    }
                />
                {viewStatus.key == "create" ? (
                    <Form.Input
                        label={this.props.t("members.apparatus.setPassword.setPassword")}
                        type="password"
                        autoComplete={false}
                        autoCorrect={false}
                        autoCapitalize={false}
                        spellCheck={false}
                        value={password || ""}
                        onChange={(e, d) =>
                            this.setState({password: nullIfBlank(d.value)})
                        }
                    />
                ) : null}
                {viewStatus.key == "create" ? (
                    <Form.Input
                        label={this.props.t(
                            "members.apparatus.setPassword.confirmPassword",
                        )}
                        type="password"
                        autoComplete={false}
                        autoCorrect={false}
                        autoCapitalize={false}
                        spellCheck={false}
                        value={confirmPassword || ""}
                        error={this.getPasswordStatus().key == "mismatch"}
                        onChange={(e, d) =>
                            this.setState({confirmPassword: nullIfBlank(d.value)})
                        }
                    />
                ) : null}
                {actionStatus.key == "error" ? (
                    <Message negative content={actionStatus.message}/>
                ) : null}
            </Form>
        );
    }

    render() {
        const {actionStatus} = this.state;
        const {viewStatus, onClose} = this.props;
        let primaryButtonText = null;
        if (viewStatus.key == "create") {
            primaryButtonText = this.props.t("general.create");
        } else if (viewStatus.key == "edit") {
            primaryButtonText = this.props.t("general.update");
        }
        return (
            <Modal
                size="small"
                open={viewStatus.key != "hidden"}
                closeOnEscape={false}
                onClose={onClose}
            >
                <Modal.Header>
                    {this.props.t(
                        `members.apparatus.createEditApparatusModal.${
                            viewStatus.key == "create" ? "createTitle" : "editTitle"
                        }`,
                    )}
                </Modal.Header>
                <Modal.Content>{this.renderCreate()}</Modal.Content>
                <Modal.Actions>
                    <Button
                        content={this.props.t("general.cancel")}
                        disabled={actionStatus.key == "loading"}
                        onClick={onClose}
                    />
                    <Button
                        primary
                        content={primaryButtonText}
                        loading={actionStatus.key == "loading"}
                        disabled={this.getSubmissionStatus().key == "incomplete"}
                        onClick={() => {
                            if (viewStatus.key == "create") {
                                this.onCreate();
                            } else if (viewStatus.key == "edit") {
                                this.onEdit();
                            }
                        }}
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}

export default withContext(CreateEditApparatusModal, "api", "local", "i18n");
