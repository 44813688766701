import * as React from "react";
import {Transition, Segment, Dimmer, TransitionablePortal} from "semantic-ui-react";

export interface SheetProps {
    open: boolean;
    onDismiss: () => void;
    children?: React.ReactNode
}

export class Sheet extends React.Component<SheetProps> {
    render() {
        return (
            <div>
                <Dimmer page
                        active={this.props.open}
                        onClickOutside={this.props.onDismiss}
                        style={{backgroundColor: "rgba(0,0,0,0.25)", animationDuration: 0.2}}/>
                <TransitionablePortal open={this.props.open}
                                      closeOnEscape={false}
                                      closeOnDocumentClick={false}
                                      onClose={this.props.onDismiss}
                                      transition={{animation: "fade left", duration: 200}}>
                    <div style={{
                        height: "100%",
                        width: "calc(50% - 110px)",
                        top: 0,
                        right: 0,
                        position: "fixed",
                        backgroundColor: "white",
                        zIndex: 1000,
                    }}>
                        {this.props.children}
                    </div>
                </TransitionablePortal>
            </div>
        );
    }
}

export interface SheetOverlayProps {
    open: boolean;
    accentColor?: string;
    onDismiss: () => void;
    children?: React.ReactNode
}

export class SheetOverlay extends React.Component<SheetOverlayProps> {
    render() {
        return (
            <div>
                <Transition key="selected-group-transition"
                            visible={this.props.open}
                            animation='fade up'
                            duration={200}>
                    <Segment style={{
                        position: "absolute",
                        bottom: "-2px",
                        left: "4px",
                        width: "calc(100% - 8px)",
                        height: "60%",
                        zIndex: "1001",
                        margin: "0",
                        borderRadius: "6px 6px 0 0",
                        borderTop: this.props.accentColor != null ? `3px solid ${this.props.accentColor}` : undefined,
                        padding: "1.25em",
                    }}>
                        {this.props.children}
                    </Segment>
                </Transition>
                <Dimmer key="selected-group-dimmer"
                        active={this.props.open}
                        onClickOutside={this.props.onDismiss}
                        style={{backgroundColor: "rgba(0,0,0,0.10)", animationDuration: 0.2}}/>
            </div>
        );
    }
}
