import { ApiResult } from "@bryxinc/lunch/models";
import { BryxApi } from "@bryxinc/lunch";

export function promisify<
  Api extends BryxApi,
  Args extends Array<ArgT>,
  ResultType,
  ArgT
>(
  api: Api,
  fn: (...args: [...Args, (result: ApiResult<ResultType>) => void]) => void,
  ...args: Args
): Promise<ResultType> {
  return new Promise((resolve, reject) => {
    fn.apply(api, [
      ...args,
      (result: ApiResult<ResultType>) => {
        if (result.success) {
          resolve(result.value);
        } else {
          reject(result.message);
        }
      },
    ]);
  });
}
