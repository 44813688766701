import { useApi } from "@bryxinc/lunch/context";
import { Polygon, Point } from "geojson";
import { useEffect, useState } from "react";
import {
  Agency,
  AuthAgency,
  PageResult,
  MinimalSiteSurvey,
} from "@bryxinc/lunch/models";
import { ManagementApi } from "@bryxinc/lunch/utils";
import { promisify } from "../../utils/promisify";

export const PageLimitArr = <const>[10, 25, 50, 100];
export type PageLimit = (typeof PageLimitArr)[number];

export type SiteSurveyListProps = {
  limit: PageLimit;
  onSetLimit: (limit: PageLimit) => unknown;
  page: number;
  onSetPage: (page: number) => unknown;
  results: PageResult<MinimalSiteSurvey>;
  center?: Point | Polygon;
};

export function useAgency(selectedAgencyId: AuthAgency["id"]): Agency | null {
  const { api } = useApi<ManagementApi>();
  const [agency, setAgency] = useState<Agency | null>(null);
  useEffect(() => {
    let destructed = false;
    promisify(api, api.getAgency, selectedAgencyId).then((agency) => {
      if (!destructed) {
        setAgency(agency);
      }
    });
    return () => {
      destructed = true;
    };
  }, [selectedAgencyId]);
  return agency;
}
