import * as React from 'react';
import {Input, Icon} from 'semantic-ui-react';
import CopyToClipboard from "react-copy-to-clipboard";

interface CopyFieldProps extends React.HTMLProps<CopyField> {
    title?: string;
    value?: string;
}

interface CopyFieldState {
    showCopied: boolean;
}

export class CopyField extends React.Component<CopyFieldProps, CopyFieldState> {
    private static readonly showCopiedTimeout = 2.0 * 1000;

    constructor(props: CopyFieldProps) {
        super(props);
        this.state = {
            showCopied: false,
        };
    }

    onCopy() {
        this.setState({showCopied: true});
        setTimeout(() => {
            this.setState({showCopied: false});
        }, CopyField.showCopiedTimeout);
    }

    render() {
        return (
            <Input style={this.props.style}
                   label={this.props.title || undefined}
                   value={this.props.value || ""}
                   readOnly
                   disabled={this.props.value == null}
                   icon={(
                       <CopyToClipboard text={this.props.value || ""} onCopy={this.onCopy.bind(this)}>
                           <Icon name={this.state.showCopied ? "check" : "copy"} link/>
                       </CopyToClipboard>
                   )}/>
        );
    }
}
