import * as React from 'react';
import {Popup, Icon} from 'semantic-ui-react';

interface InfoLabelProps {
    title: React.ReactNode;
    content: string;
}

export class InfoLabel extends React.Component<InfoLabelProps, null> {
    render() {
        return (
            <label>
                {this.props.title}
                <Popup trigger={<Icon name="help circle" style={{marginLeft: "5px"}}/>}
                       position="right center"
                       content={this.props.content}/>
            </label>
        );
    }
}
