import * as React from "react";

interface ToolTipProps {
  content: string;
  radius: number;
}

interface ToolTipState {
  hover: boolean;
}

/***
 * Wraps the ToolTip class useful to pass into input labels
 * @params
 * label:string, the primary text always displayed
 * content:string, text displayed in the hidden span
 * radius:number, radius of circle
 */

export class ToolTip extends React.Component<ToolTipProps, ToolTipState> {
  constructor(props: ToolTipProps) {
    super(props);
    this.state = { hover: false };
  }

  render() {
    const fontSize = Math.floor(this.props.radius * 0.7);
    const margin = Math.floor(this.props.radius * 0.2);
    // const offSet = width * -0.5;
    return (
      <div style={{ display: "inline-block", fontSize: "1rem" }}>
        <div
          onMouseEnter={() => this.setState({ hover: true })}
          onMouseLeave={() => this.setState({ hover: false })}
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: this.props.radius,
            width: this.props.radius,
            margin: margin,
            textAlign: "center",
            borderRadius: "50%",
            backgroundColor: "#0E6EB8",
            color: "white",
          }}
        >
          <div style={{ fontSize: fontSize, fontWeight: "bold" }}>{"i"}</div>
          <span
            style={{
              visibility: this.state.hover ? "visible" : "hidden",
              backgroundColor: "#0E6EB8",
              color: "white",
              textAlign: "center",
              borderRadius: "6px",
              padding: "5px 10px",
              position: "absolute",
              zIndex: 1,
              bottom: "100%",
              minWidth: 200,
              // left: "50%",
              // marginLeft: offSet,
            }}
          >
            {this.props.content}
          </span>
        </div>
      </div>
    );
  }
}
