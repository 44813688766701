import * as React from "react";

export const PHONEME_LITERALS =
  "b d d͡ʒ ð f ɡ h j k l m n ŋ p ɹ s ʃ t t͡ʃ θ v w z ʒ ə ɚ æ aɪ aʊ ɑ eɪ ɝ ɛ i ɪ oʊ ɔ ɔɪ u ʊ ʌ ˈ ˌ .";
export const PHONEME_EXAMPLES = [
  <p key="b">
    <b>b</b>ed
  </p>,
  <p key="d">
    <b>d</b>ig
  </p>,
  <p key="d͡ʒ">
    <b>j</b>ump
  </p>,
  <p key="ð">
    <b>th</b>en
  </p>,
  <p key="f">
    <b>f</b>ive
  </p>,
  <p key="ɡ">
    <b>g</b>ame
  </p>,
  <p key="h">
    <b>h</b>ouse
  </p>,
  <p key="j">
    <b>y</b>es
  </p>,
  <p key="k">
    <b>c</b>at
  </p>,
  <p key="l">
    <b>l</b>ay
  </p>,
  <p key="m">
    <b>m</b>ouse
  </p>,
  <p key="n">
    <b>n</b>ap
  </p>,
  <p key="ŋ">
    thi<b>ng</b>
  </p>,
  <p key="p">
    s<b>p</b>eak
  </p>,
  <p key="ɹ">
    <b>r</b>ed
  </p>,
  <p key="s">
    <b>s</b>eem
  </p>,
  <p key="ʃ">
    <b>sh</b>ip
  </p>,
  <p key="t">
    <b>t</b>rap
  </p>,
  <p key="t͡ʃ">
    <b>ch</b>art
  </p>,
  <p key="θ">
    <b>th</b>in
  </p>,
  <p key="v">
    <b>v</b>est
  </p>,
  <p key="w">
    <b>w</b>est
  </p>,
  <p key="z">
    <b>z</b>ero
  </p>,
  <p key="ʒ">
    vi<b>s</b>ion
  </p>,
  <p key="ə">
    <b>a</b>ren<b>a</b>
  </p>,
  <p key="ɚ">
    read<b>er</b>
  </p>,
  <p key="æ">
    tr<b>a</b>p
  </p>,
  <p key="aɪ">
    pr<b>i</b>ce
  </p>,
  <p key="aʊ">
    m<b>ou</b>th
  </p>,
  <p key="ɑ">
    f<b>a</b>ther
  </p>,
  <p key="eɪ">
    f<b>a</b>ce
  </p>,
  <p key="ɝ">
    n<b>ur</b>se
  </p>,
  <p key="ɛ">
    dr<b>e</b>ss
  </p>,
  <p key="i">
    fl<b>ee</b>ce
  </p>,
  <p key="ɪ">
    k<b>i</b>t
  </p>,
  <p key="oʊ">
    g<b>oa</b>t
  </p>,
  <p key="ɔ">
    th<b>ou</b>ght
  </p>,
  <p key="ɔɪ">
    ch<b>oi</b>ce
  </p>,
  <p key="u">
    g<b>oo</b>se
  </p>,
  <p key="ʊ">
    f<b>oo</b>t
  </p>,
  <p key="ʌ">
    str<b>u</b>t
  </p>,
  <p key="primaryStress">primary stress</p>,
  <p key="secondaryStress">secondary stress</p>,
  <p key="syllableBoundary">syllable boundary</p>,
];
