import * as React from 'react';
import {Button, Message, Modal} from 'semantic-ui-react';
import {Station} from '@bryxinc/lunch/models';
import BryxApi from '@bryxinc/lunch/utils/ManagementApi';

import {withContext, WithTranslation, WithLocal, WithApi} from '@bryxinc/lunch/context';

interface ConfirmDeleteStationModalProps extends WithTranslation, WithLocal, WithApi<BryxApi> {
    viewStatus: ConfirmDeleteStationModalViewStatus;
    onConfirmRemove: () => void;
    onClose: () => void;
}

export type ConfirmDeleteStationModalViewStatus =
    { key: "hidden" }
    | { key: "shown", agencyId: string, station: Station };


interface ConfirmDeleteStationModalState {
    status: { key: "ready" } | { key: "loading" } | { key: "error", message: string };
}

export class ConfirmDeleteStationModal extends React.Component<ConfirmDeleteStationModalProps, ConfirmDeleteStationModalState> {
    constructor(props: ConfirmDeleteStationModalProps) {
        super(props);
        this.state = ConfirmDeleteStationModal.getInitialState();
    }

    private static getInitialState(): ConfirmDeleteStationModalState {
        return {
            status: {key: "ready"},
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: ConfirmDeleteStationModalProps) {
        if (this.props.viewStatus.key == "hidden" && nextProps.viewStatus.key == "shown") {
            this.setState(ConfirmDeleteStationModal.getInitialState());
        }
    }

    private onSubmit() {
        const {status} = this.state;
        const {viewStatus} = this.props;
        if (status.key == "loading" || viewStatus.key != "shown") {
            return;
        }
        this.setState({status: {key: "loading"}});
        this.props.api.deleteStation(viewStatus.agencyId, viewStatus.station.id, result => {
            if (result.success == true) {
                this.props.onConfirmRemove();
            } else {
                this.props.local.logWarn(`Failed to delete station: ${result.debugMessage || result.message}`);
                this.setState({status: {key: "error", message: result.message}});
            }
        });
    }

    private renderModalContent(): JSX.Element | null {
        const {viewStatus} = this.props;
        const {status} = this.state;
        if (viewStatus.key == "hidden") {
            return <Modal.Content/>;
        }

        return (
            <Modal.Content>
                <Message warning
                         content={this.props.t("agency.general.deleteStation.header", {replace: {stationName: viewStatus.station.name}})}/>
                {status.key == "error" ? <Message error content={status.message}/> : null}
            </Modal.Content>
        );
    }

    render() {
        const {viewStatus} = this.props;
        const {status} = this.state;
        return (
            <Modal size="small"
                   open={viewStatus.key == "shown"}
                   onClose={this.props.onClose}>
                <Modal.Header>{this.props.t("agency.general.deleteStation.modalHeader")}</Modal.Header>
                {this.renderModalContent()}
                <Modal.Actions>
                    <Button content={this.props.t("general.cancel")}
                            disabled={status.key == "loading"}
                            onClick={this.props.onClose}/>
                    <Button negative
                            loading={status.key == "loading"}
                            content={this.props.t("general.remove")}
                            onClick={this.onSubmit.bind(this)}/>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default withContext(ConfirmDeleteStationModal, 'api', 'local', 'i18n');
