import * as React from "react";
import {Button, Message, Modal} from "semantic-ui-react";
import {FindUser} from "@bryxinc/lunch/models";

import {RouteComponentProps} from "react-router";
import BryxApi from "@bryxinc/lunch/utils/ManagementApi";
import {
    withContext,
    WithTranslation,
    WithLocal,
    WithApi,
} from "@bryxinc/lunch/context";

export type ConfirmRemoveDispatchPermissionModalViewStatus =
    | { key: "hidden" }
    | { key: "shown"; agencyId: string; user: FindUser };

interface ConfirmRemoveDispatchPermissionModalProps
    extends RouteComponentProps,
        WithTranslation,
        WithLocal,
        WithApi<BryxApi> {
    viewStatus: ConfirmRemoveDispatchPermissionModalViewStatus;
    mode: "dispatch" | "scuManager";
    onConfirmRemove: (userId: string) => void;
    onClose: () => void;
}

interface ConfirmRemoveDispatchPermissionModalState {
    status:
        | { key: "ready" }
        | { key: "loading" }
        | { key: "error"; message: string };
}

export class ConfirmRemoveDispatchPermissionModal extends React.Component<ConfirmRemoveDispatchPermissionModalProps,
    ConfirmRemoveDispatchPermissionModalState> {
    constructor(props: ConfirmRemoveDispatchPermissionModalProps) {
        super(props);
        this.state = ConfirmRemoveDispatchPermissionModal.getInitialState();
    }

    private static getInitialState(): ConfirmRemoveDispatchPermissionModalState {
        return {
            status: {key: "ready"},
        };
    }

    UNSAFE_componentWillReceiveProps(
        nextProps: ConfirmRemoveDispatchPermissionModalProps,
    ) {
        if (
            this.props.viewStatus.key == "hidden" &&
            nextProps.viewStatus.key == "shown"
        ) {
            this.setState(ConfirmRemoveDispatchPermissionModal.getInitialState());
        }
    }

    private onSubmit() {
        const {status} = this.state;
        const {viewStatus, onConfirmRemove, mode} = this.props;
        if (status.key == "loading" || viewStatus.key != "shown") {
            return;
        }
        this.setState({status: {key: "loading"}});
        if (mode == "dispatch") {
            this.props.api.revokeUserDispatch(
                viewStatus.agencyId,
                viewStatus.user.id,
                (result) => {
                    if (result.success) {
                        onConfirmRemove(viewStatus.user.id);
                    } else {
                        this.props.local.logWarn(
                            `Failed to revoke dispatcher access: ${
                                result.debugMessage || result.message
                            }`,
                        );
                        this.setState({status: {key: "error", message: result.message}});
                    }
                },
            );
        } else {
            this.props.api.revokeUserSCUManagement(
                viewStatus.agencyId,
                viewStatus.user.id,
                (result) => {
                    if (result.success) {
                        onConfirmRemove(viewStatus.user.id);
                    } else {
                        this.props.local.logWarn(
                            `Failed to revoke scuManager access: ${
                                result.debugMessage || result.message
                            }`,
                        );
                        this.setState({status: {key: "error", message: result.message}});
                    }
                },
            );
        }
    }

    private renderModalContent(): JSX.Element | null {
        const {viewStatus, mode} = this.props;
        const {status} = this.state;
        const i18nKey = mode == "dispatch" ? "monitoring" : "management";
        if (viewStatus.key == "hidden") {
            return <Modal.Content/>;
        }

        return (
            <Modal.Content>
                <Message
                    warning
                    content={this.props.t(
                        `dispatch.${i18nKey}.removeMemberConfirmation`,
                        {
                            replace: {
                                userName:
                                    viewStatus.key == "shown" && viewStatus.user.commonName,
                            },
                        },
                    )}
                />
                {status.key == "error" ? (
                    <Message error content={status.message}/>
                ) : null}
            </Modal.Content>
        );
    }

    render() {
        const {viewStatus, mode} = this.props;
        const {status} = this.state;
        const i18nKey = mode == "dispatch" ? "monitoring" : "management";
        return (
            <Modal
                size="small"
                open={viewStatus.key == "shown"}
                onClose={this.props.onClose}
            >
                <Modal.Header>
                    {this.props.t(`dispatch.${i18nKey}.removeMemberModalTitle`)}
                </Modal.Header>
                {this.renderModalContent()}
                <Modal.Actions>
                    <Button
                        content={this.props.t("general.cancel")}
                        disabled={status.key == "loading"}
                        onClick={this.props.onClose}
                    />
                    <Button
                        negative
                        loading={status.key == "loading"}
                        content={this.props.t("general.remove")}
                        onClick={this.onSubmit.bind(this)}
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}

export default withContext(
    ConfirmRemoveDispatchPermissionModal,
    "api",
    "local",
    "i18n",
);
