import * as React from "react";
import {
    Button,
    Header,
    Icon,
    Image,
    List,
    Loader,
    Message,
    Table,
} from "semantic-ui-react";
import {FindUser} from "@bryxinc/lunch/models";

import {AddMemberModal} from "./addMemberModal";
import {ConfirmRemoveDispatchPermissionModal} from "./confirmRemoveDispatchPermissionModal";
import {StationAlertingProps} from "../stationAlerting/stationAlertingPage";
import {withContext} from "@bryxinc/lunch/context";

interface StationMonitoringTabState {
    loadStatus:
        | { key: "loading" }
        | { key: "ready"; users: FindUser[] }
        | { key: "error"; message: string };
    overlay:
        | { key: "none" }
        | { key: "addMember" }
        | { key: "removeMember"; user: FindUser };
}

export class StationMonitoringTab extends React.Component<StationAlertingProps,
    StationMonitoringTabState> {
    constructor(props: StationAlertingProps) {
        super(props);
        this.state = StationMonitoringTab.getDefaultState();
    }

    private static getDefaultState(): StationMonitoringTabState {
        return {
            loadStatus: {key: "loading"},
            overlay: {key: "none"},
        };
    }

    componentDidMount(): void {
        this.setState(StationMonitoringTab.getDefaultState(), () =>
            this.loadDispatchers(this.props.selectedAgency.id),
        );
    }

    UNSAFE_componentWillReceiveProps(nextProps: Readonly<StationAlertingProps>): void {
        if (nextProps.selectedAgency.id != this.props.selectedAgency.id) {
            this.loadDispatchers(nextProps.selectedAgency.id);
        }
    }

    private loadDispatchers(agencyId: string) {
        this.setState({loadStatus: {key: "loading"}});
        this.props.api.getDispatchers(agencyId, (result) => {
            if (result.success == true) {
                this.setState({loadStatus: {key: "ready", users: result.value}});
            } else {
                this.props.local.logWarn(
                    `Failed to load dispatchers: ${
                        result.debugMessage || result.message
                    }`,
                );
                this.setState({
                    loadStatus: {key: "error", message: result.message},
                });
            }
        });
    }

    private addUser(user: FindUser) {
        this.setState((prevState: StationMonitoringTabState) => {
            if (prevState.loadStatus.key == "ready") {
                return {
                    loadStatus: {
                        key: "ready",
                        users: prevState.loadStatus.users.concat([user]),
                    },
                };
            }
            return {
                loadStatus: {key: "loading"},
            };
        });
    }

    private removeUser(userId: string) {
        this.setState((prevState: StationMonitoringTabState) => {
            if (prevState.loadStatus.key == "ready") {
                return {
                    loadStatus: {
                        key: "ready",
                        users: prevState.loadStatus.users.filter((m) => m.id != userId),
                    },
                };
            }
            return {loadStatus: {key: "loading"}};
        });
    }

    private renderTable(): React.ReactNode {
        const {loadStatus} = this.state;
        return (
            <div>
                <Message
                    info
                    icon="info circle"
                    header={this.props.t("dispatch.monitoring.messageHeader")}
                    list={[
                        <li key={1}>
                            {this.props.t("dispatch.monitoring.messageLines.1")}
                        </li>,
                        <li key={2}>
                            {this.props.t("dispatch.monitoring.messageLines.2")},{" "}
                            <a href={this.props.api.dispatchUrl}>dispatch.bryx911.com</a>
                        </li>,
                        <li key={3}>
                            {this.props.t("dispatch.monitoring.messageLines.3")}
                        </li>,
                    ]}
                />
                <div style={{display: "flex", justifyContent: "flex-end"}}>
                    <Button
                        primary
                        content={this.props.t("general.launch")}
                        href={this.props.api.dispatchUrl}
                        target="_blank"
                    />
                    <Button
                        positive
                        content={this.props.t("general.add")}
                        onClick={() => this.setState({overlay: {key: "addMember"}})}
                    />
                </div>
                <Table striped>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={5}>
                                {this.props.t("dispatch.monitoring.headerTitles.email")}
                            </Table.HeaderCell>
                            <Table.HeaderCell width={9}>
                                {this.props.t(
                                    "dispatch.monitoring.headerTitles.commonName",
                                )}
                            </Table.HeaderCell>
                            <Table.HeaderCell width={2}/>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {loadStatus.key == "ready" &&
                        loadStatus.users.map((m) => (
                            <Table.Row key={m.id} style={{height: "48px"}}>
                                <Table.Cell>{m.email}</Table.Cell>
                                <Table.Cell>{m.commonName}</Table.Cell>
                                <Table.Cell textAlign="right">
                                    <Icon
                                        link
                                        color="red"
                                        size="large"
                                        name="x"
                                        onClick={() =>
                                            this.setState({
                                                overlay: {key: "removeMember", user: m},
                                            })
                                        }
                                    />
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </div>
        );
    }

    private renderZeroState(): React.ReactNode {
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                }}
            >
                <div style={{flex: 3}}>
                    <Image src="/resources/assets/dispatch_promo.png"/>
                </div>
                <div style={{flex: 2, paddingLeft: "50px"}}>
                    <Header as="h2">
                        {this.props.t("dispatch.monitoring.zeroState.header")}
                        <Header.Subheader>
                            {this.props.t("dispatch.monitoring.zeroState.subheader")}
                        </Header.Subheader>
                    </Header>
                    <List ordered>
                        <List.Item>
                            {this.props.t(
                                "dispatch.monitoring.zeroState.messageLines.1",
                            )}
                        </List.Item>
                        <List.Item>
                            {this.props.t(
                                "dispatch.monitoring.zeroState.messageLines.2",
                            )}
                        </List.Item>
                        <List.Item>
                            {this.props.t(
                                "dispatch.monitoring.zeroState.messageLines.3",
                            )}
                        </List.Item>
                    </List>
                    <Button
                        positive
                        content={this.props.t("dispatch.monitoring.addMember")}
                        onClick={() => this.setState({overlay: {key: "addMember"}})}
                    />
                </div>
            </div>
        );
    }

    render() {
        const {loadStatus} = this.state;
        const {selectedAgency} = this.props;
        return (
            <div className="underHorizNavContent tableUnderNavContent">
                {loadStatus.key == "ready" && loadStatus.users.length > 0
                    ? this.renderTable()
                    : null}
                {loadStatus.key == "ready" && loadStatus.users.length == 0
                    ? this.renderZeroState()
                    : null}
                {loadStatus.key == "loading" ? <Loader active/> : null}
                {loadStatus.key == "error" ? (
                    <Message negative content={loadStatus.message}/>
                ) : null}
                <AddMemberModal
                    {...this.props}
                    mode="dispatcher"
                    viewStatus={
                        this.state.overlay.key == "addMember"
                            ? {key: "shown"}
                            : {key: "hidden"}
                    }
                    existingMembers={loadStatus.key == "ready" ? loadStatus.users : []}
                    selectedAgency={this.props.selectedAgency}
                    onAdded={(u) => {
                        this.setState({overlay: {key: "none"}});
                        this.addUser(u);
                    }}
                    onCancel={() => this.setState({overlay: {key: "none"}})}
                />
                <ConfirmRemoveDispatchPermissionModal
                    {...this.props}
                    mode="dispatch"
                    viewStatus={
                        this.state.overlay.key == "removeMember"
                            ? {
                                key: "shown",
                                agencyId: selectedAgency.id,
                                user: this.state.overlay.user,
                            }
                            : {key: "hidden"}
                    }
                    onConfirmRemove={(userId) =>
                        this.setState({overlay: {key: "none"}}, () =>
                            this.removeUser(userId),
                        )
                    }
                    onClose={() => this.setState({overlay: {key: "none"}})}
                />
            </div>
        );
    }
}

export default withContext(StationMonitoringTab, "api", "local", "i18n");
