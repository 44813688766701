import * as React from "react";
import {Button, Message, Modal} from "semantic-ui-react";
import {FullUser} from "@bryxinc/lunch/models";

import {RouteComponentProps} from "react-router";
import BryxApi from "@bryxinc/lunch/utils/ManagementApi";
import {
    withContext,
    WithTranslation,
    WithLocal,
    WithApi,
} from "@bryxinc/lunch/context";

export type PasswordResetEmailModalViewStatus =
    | { key: "hidden" }
    | { key: "shown"; client: FullUser };

interface PasswordResetEmailModalProps
    extends RouteComponentProps,
        WithTranslation,
        WithLocal,
        WithApi<BryxApi> {
    viewStatus: PasswordResetEmailModalViewStatus;
    onClose: () => void;
}

interface PasswordResetEmailModalState {
    status:
        | { key: "loading" }
        | { key: "ready" }
        | { key: "error"; message: string };
}

export class PasswordResetEmailModal extends React.Component<PasswordResetEmailModalProps,
    PasswordResetEmailModalState> {
    constructor(props: PasswordResetEmailModalProps) {
        super(props);

        this.state = PasswordResetEmailModal.getInitialState();
    }

    private static getInitialState(): PasswordResetEmailModalState {
        return {
            status: {key: "ready"},
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: PasswordResetEmailModalProps) {
        if (
            this.props.viewStatus.key == "hidden" &&
            nextProps.viewStatus.key == "shown"
        ) {
            this.setState(PasswordResetEmailModal.getInitialState());
        }
    }

    private sendPasswordResetEmail() {
        const {viewStatus} = this.props;

        if (viewStatus.key == "hidden") {
            return;
        }

        this.setState({
            status: {key: "loading"},
        });

        this.props.api.sendPasswordResetEmail(viewStatus.client.id, (result) => {
            if (result.success == true) {
                this.props.onClose();
            } else {
                this.props.local.logWarn(
                    `Failed to send password reset email: ${result.debugMessage}`,
                );
                this.setState({
                    status: {key: "error", message: result.message},
                });
            }
        });
    }

    render() {
        const {viewStatus} = this.props;
        const {status} = this.state;

        const infoMessage =
            viewStatus.key == "shown" ? (
                <Message
                    info
                    icon="help circle"
                    header={this.props.t(
                        "members.users.passwordResetEmailModal.infoTitle",
                    )}
                    content={this.props.t(
                        "members.users.passwordResetEmailModal.infoBody",
                        {
                            replace: {
                                clientName: viewStatus.client.commonName,
                                clientEmail: viewStatus.client.email,
                            },
                        },
                    )}
                />
            ) : undefined;

        return (
            <Modal
                size="small"
                open={viewStatus.key == "shown"}
                onClose={this.props.onClose}
            >
                <Modal.Header>
                    {this.props.t("members.users.passwordResetEmailModal.title")}
                </Modal.Header>
                <Modal.Content>
                    {infoMessage}
                    {status.key == "error" ? (
                        <Message error content={status.message}/>
                    ) : undefined}
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content={this.props.t("general.cancel")}
                        disabled={status.key == "loading"}
                        onClick={this.props.onClose}
                    />
                    <Button
                        primary
                        content={this.props.t("general.send")}
                        loading={status.key == "loading"}
                        onClick={() => this.sendPasswordResetEmail()}
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}

export default withContext(PasswordResetEmailModal, "api", "local", "i18n");
