import * as React from 'react';
import {Button, Icon, Modal, Table} from "semantic-ui-react";
import {AuthAgency} from '@bryxinc/lunch/models';
import {RouteComponentProps} from "react-router";
import BryxApi from '@bryxinc/lunch/utils/ManagementApi';
import {withContext, WithTranslation, WithLocal, WithApi} from '@bryxinc/lunch/context';

export type SwitchAgencyVisibility = "hidden" | "manual" | "forced";

interface SwitchAgencyProps extends RouteComponentProps, WithTranslation, WithLocal, WithApi<BryxApi> {
    showState: SwitchAgencyVisibility;
    agencyOptions: AuthAgency[];
    selectedAgency: AuthAgency;

    onAgencySelected(agency: AuthAgency | null): void;
}

interface SwitchAgencyState {
    newSelectedAgency: AuthAgency;
}

export class SwitchAgencyModal extends React.Component<SwitchAgencyProps, SwitchAgencyState> {
    constructor(props: SwitchAgencyProps) {
        super(props);

        this.state = SwitchAgencyModal.getInitialState(props);
    }

    static getInitialState(props: SwitchAgencyProps) {
        return {
            newSelectedAgency: props.selectedAgency,
        };
    }

    UNSAFE_componentWillReceiveProps(newProps: SwitchAgencyProps) {
        if (this.props.showState == "hidden" && newProps.showState != "hidden") {
            this.setState(SwitchAgencyModal.getInitialState(newProps));
        }
    }

    private cancelAgencySelect() {
        if (this.props.showState != "forced") {
            this.props.onAgencySelected(null);
        }
    }

    render() {
        const tableData = this.props.agencyOptions.map((d: AuthAgency) => {
            return (
                <Table.Row key={d.id}
                           style={{cursor: "pointer"}}
                           positive={d.id == this.state.newSelectedAgency.id}
                           onClick={() => this.setState({newSelectedAgency: d})}>
                    <Table.Cell style={{paddingLeft: "20px"}}>{d.name}</Table.Cell>
                    <Table.Cell textAlign="center"
                                icon={d.id == this.state.newSelectedAgency.id ? (
                                    <Icon color='green'
                                          name='checkmark'
                                          size='large'/>
                                ) : undefined}/>
                </Table.Row>
            );
        });

        const tableInstance = (
            <Table selectable padded basic="very">
                <Table.Body>
                    {tableData}
                </Table.Body>
            </Table>
        );

        return (
            <Modal open={this.props.showState != "hidden"}
                   onClose={() => this.cancelAgencySelect()}
                   closeOnDimmerClick={this.props.showState != "forced"}>
                <Modal.Header>
                    {this.props.t("switchAgenciesModal.agencySelection")}
                </Modal.Header>
                <Modal.Content style={{padding: 0}}>
                    {tableInstance}
                </Modal.Content>
                <Modal.Actions>
                    <Button content={this.props.t("general.cancel")}
                            disabled={this.props.showState == "forced"}
                            onClick={() => this.cancelAgencySelect()}/>
                    <Button primary
                            content={this.props.t("general.select")}
                            onClick={() => this.props.onAgencySelected(this.state.newSelectedAgency)}/>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default withContext(SwitchAgencyModal, 'api', 'local', 'i18n');
