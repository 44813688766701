import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { AuthAgency } from "@bryxinc/lunch/models";
import { SurveySearch } from "./surveySearch";
import { SiteSurveyForm } from "./siteSurveyForm";

export type SiteSurveysProps = {
  selectedAgency: AuthAgency;
};

export function SiteSurveyPage(props: SiteSurveysProps) {
  return (
    <div className="pageContent">
      <Switch>
        <Route
          exact
          path="/site-surveys/search"
          render={() => <SurveySearch {...props} />}
        />
        <Route
          exact
          path="/site-surveys/create"
          render={() => <SiteSurveyForm {...props} />}
        />
        <Route
          path="/site-surveys/edit/:surveyId"
          render={() => <SiteSurveyForm {...props} />}
        />

        <Redirect to="/site-surveys/search" />
      </Switch>
    </div>
  );
}
