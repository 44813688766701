import * as React from "react";
import {
    Button,
    Form,
    InputOnChangeData,
    Message,
    Modal,
} from "semantic-ui-react";
import {AgencyGroup} from "@bryxinc/lunch/models";

import {nullIfBlank} from "@bryxinc/lunch/utils/functions";

import BryxApi from "@bryxinc/lunch/utils/ManagementApi";
import {RouteComponentProps} from "react-router";
import {
    withContext,
    WithTranslation,
    WithLocal,
    WithApi,
} from "@bryxinc/lunch/context";

export type EditGroupNameModalViewStatus =
    | { key: "hidden" }
    | { key: "shown"; group: AgencyGroup };

interface EditGroupNameModalProps
    extends RouteComponentProps,
        WithTranslation,
        WithLocal,
        WithApi<BryxApi> {
    agencyId: string;

    onDismiss(reload: boolean): void;

    viewStatus: EditGroupNameModalViewStatus;
}

interface EditGroupNameModalState {
    actionStatus:
        | { key: "loading" }
        | { key: "ready" }
        | { key: "error"; message: string };
    nameField: string | null;
}

export class EditGroupNameModal extends React.Component<EditGroupNameModalProps,
    EditGroupNameModalState> {
    constructor(props: EditGroupNameModalProps) {
        super(props);

        this.state = EditGroupNameModal.getInitialState(props);
    }

    static getInitialState(
        props: EditGroupNameModalProps,
    ): EditGroupNameModalState {
        return {
            actionStatus: {key: "ready"},
            nameField:
                props.viewStatus.key == "shown" ? props.viewStatus.group.name : null,
        };
    }

    UNSAFE_componentWillReceiveProps(newProps: EditGroupNameModalProps) {
        if (
            this.props.viewStatus.key == "hidden" &&
            newProps.viewStatus.key == "shown"
        ) {
            // Opening modal
            const newState = EditGroupNameModal.getInitialState(newProps);
            this.setState(newState);
        }
    }

    private editName() {
        const {viewStatus} = this.props;
        const {nameField} = this.state;

        if (viewStatus.key == "shown" && nameField != null) {
            this.setState({
                actionStatus: {key: "loading"},
            });

            this.props.api.editGroupName(
                this.props.agencyId,
                viewStatus.group.id,
                nameField,
                (result) => {
                    if (result.success == true) {
                        this.setState(
                            {
                                actionStatus: {key: "ready"},
                            },
                            () => this.props.onDismiss(true),
                        );
                    } else {
                        this.props.local.logWarn(
                            `Failed to edit messaging group's name: ${result.message}`,
                        );
                        this.setState({
                            actionStatus: {key: "error", message: result.message},
                        });
                    }
                },
            );
        }
    }

    private onChangeNameField(e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) {
        this.setState({nameField: nullIfBlank(data.value)});
    }

    render() {
        const {onDismiss, viewStatus} = this.props;
        const {actionStatus, nameField} = this.state;

        return (
            <Modal
                size="tiny"
                open={viewStatus.key == "shown"}
                onClose={() => onDismiss(false)}
            >
                <Modal.Header>
                    {viewStatus.key == "shown"
                        ? this.props.t("groups.editGroupName.header", {
                            replace: {groupName: viewStatus.group.name},
                        })
                        : null}
                </Modal.Header>
                <Modal.Content style={{backgroundColor: "#fbfbfb"}}>
                    <Form
                        onSubmit={() => {
                            if (nameField != null) {
                                this.editName();
                            }
                        }}
                    >
                        <Form.Input
                            autoFocus
                            value={nameField}
                            label={this.props.t("groups.editGroupName.editInputLabel")}
                            placeholder={
                                viewStatus.key == "shown" ? viewStatus.group.name : undefined
                            }
                            onChange={this.onChangeNameField.bind(this)}
                        />
                    </Form>
                    {actionStatus.key == "error" ? (
                        <Message error content={actionStatus.message}/>
                    ) : undefined}
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content={this.props.t("general.cancel")}
                        disabled={actionStatus.key == "loading"}
                        onClick={() => onDismiss(false)}
                    />
                    <Button
                        primary
                        disabled={nameField == null}
                        content={this.props.t("groups.editGroupName.editName")}
                        loading={actionStatus.key == "loading"}
                        onClick={() => this.editName()}
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}

export default withContext(EditGroupNameModal, "api", "local", "i18n");
