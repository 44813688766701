import "./translationsTab.css";

import * as React from "react";
import {
  Accordion,
  Button,
  Confirm,
  Dropdown,
  Input,
  Message,
  Tab,
  TabPane,
} from "semantic-ui-react";
import { useState, useEffect } from "react";
import {
  ScuTranslation,
  Translation,
  TranslationPhoneme,
  TranslationReplace,
  TranslationType,
} from "@bryxinc/lunch/models/scuTranslations";
import { SubmitHandler, useForm } from "react-hook-form";
import { ApiResult } from "@bryxinc/lunch/models";
import { useDebounce } from "@tsly/hooks";
import { scuTTSKeyLabels, TranslationRow } from "./translationRow";
import { TranslationTest } from "./translationsTest";
import {
  PollyResult,
  TranslateResult,
} from "@bryxinc/lunch/utils/ManagementApi/AgencyMixin";

const constantInfoMessage =
  'This tab allows you to make simple "find and replace" adjustments to specific fields as processed from the CAD system and interpreted by Bryx. Practically, this is where corrections for things like abbreviations and shorthand and common misspellings can be added to the system. For example, correcting "pt" to "patient" in the "synopsis" field.';
const phonemeInfoMessage =
  'This tab allows you to make specific adjustments to the pronunciation of words by the text-to-speech engine on a per-syllable level rather than simple changes in spelling. This is generally useful for localization, such as hard-to-pronounce street names. Phonemes are described using the International Phonetic Alphabet. Hover over each symbol for an example of its usage in a common English word. You can test your pronunciation using the "Test phoneme" button. Chat GPT can be useful to provide a baseline translation, just ask "spell the following in IPA format:" followed by the text you\'d like to have translated.';
const testerInfoMessage =
  'This tab allows you to test the current configuration for your agency. Input the text you\'d like to test against in the input field, and select what CAD data element you are attempting to test. For example, to test an adjustment to the pronunciation of a street name, type an example address in as the input and select "Address" as the key. To test dispatcher shorthand or an abbreviation, type the example into the input and select "Synopsis" as the key.';

export type TranslationFormData = {
  id: string;
  description?: string;
  keys: string[];
  match: string;
  replace?: string;
  phoneme?: string;
};

export const SemanticLabel = ({
  label,
  children,
  disabled,
  style,
}: {
  label: string;
  children: React.ReactNode;
  disabled?: boolean;
  style?: React.CSSProperties;
}) => {
  return (
    <div
      className={`ui${disabled ? " disabled" : ""} labeled input`}
      style={style}
    >
      <div className="ui label label">{label}</div>
      {children}
    </div>
  );
};

const addNewTranslation = (stage: "constant" | "phoneme") => {
  let newTranslation: Translation;
  if (stage === "constant") {
    newTranslation = new TranslationReplace(
      "",
      [],
      0,
      "",
      null,
      "",
      "",
      false,
      false
    );
  } else {
    newTranslation = new TranslationPhoneme("", [], 0, "", null, "", "");
  }
  return newTranslation;
};

const TranslationStage = ({
  stage,
  scuTranslations,
  addTranslation,
  editTranslation,
  deleteTranslation,
  getPollyTranslation,
  refetchTranslations,
}: {
  stage: "constant" | "phoneme";
  scuTranslations: ScuTranslation[];
  addTranslation: (
    scuTranslationId: string,
    stage: string,
    translation: Translation,
    callback: (result: ApiResult<Translation>) => void
  ) => void;
  editTranslation: (
    scuTranslationId: string,
    stage: string,
    translation: Translation,
    callback: (result: ApiResult<Translation>) => void
  ) => void;
  deleteTranslation: (
    scuTranslationId: string,
    stage: string,
    translationId: string,
    callback: (result: ApiResult<null>) => void
  ) => void;
  getPollyTranslation: (
    text: string,
    callback: (result: ApiResult<PollyResult>) => void
  ) => void;
  refetchTranslations: () => void;
}) => {
  const [errorMessage, setErrorMessage] = useState<
    { id: string; message: string } | undefined
  >(undefined);
  const [editingRow, setEditingRow] = useState<string | undefined>(undefined);
  const [translationSystemIdx, setTranslationSystemIdx] = useState(0);
  const [openConfirmModal, setOpenConfirmModal] = useState<string | undefined>(
    undefined
  );
  const [openDeleteModal, setOpenDeleteModal] = useState<string | undefined>(
    undefined
  );
  const [activeRows, setActiveRows] = useState<string[]>([]);
  const [searchString, setSearchString] = useDebounce("", 1000);
  const [searchLoading, setSearchLoading] = useState<string | undefined>();
  const [stateTranslations, setStateTranslations] = useState<Translation[]>(
    scuTranslations[translationSystemIdx].pipeline[`${stage}Stage`]
  );
  const {
    handleSubmit,
    reset,
    setValue,
    watch,
    control,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      id: "",
      description: "",
      keys: [],
      match: "",
      replace: "",
      phoneme: "",
    } as TranslationFormData,
  });

  const translations = React.useMemo(
    () => scuTranslations[translationSystemIdx].pipeline[`${stage}Stage`],
    [translationSystemIdx, stage]
  );

  const onSubmit: SubmitHandler<TranslationFormData> = (data) => {
    const translation = stateTranslations.find((t) => t.id === editingRow);
    let maybeTranslation;

    const keys = data.keys.flatMap((k) => {
      const keyLabel = scuTTSKeyLabels.find((l) => l.key == k);
      if (keyLabel) return keyLabel.value;
      else {
        console.error(`Warning: key '${k}' not accounted for.`);
        return k;
      }
    });

    if (translation!.type === TranslationType.replace) {
      maybeTranslation = TranslationReplace.parse({
        ...data,
        keys,
        type: TranslationType.replace,
        regex: false,
        lock: (translation! as TranslationReplace).lock,
      });
    } else {
      maybeTranslation = TranslationPhoneme.parse({
        ...data,
        keys,
        type: TranslationType.phoneme,
      });
    }
    if (maybeTranslation.success) {
      // ID being the empty string indicates when we're creating a new translation
      if (data.id === "") {
        addTranslation(
          scuTranslations[translationSystemIdx].id,
          stage,
          maybeTranslation.value,
          (res) => {
            if (res.success) {
              refetchTranslations();
            } else {
              setErrorMessage({ id: data.id, message: res.message });
            }
          }
        );
      } else {
        editTranslation(
          scuTranslations[translationSystemIdx].id,
          stage,
          maybeTranslation.value,
          (res) => {
            if (res.success) {
              reset(data);
              setEditingRow(undefined);
              refetchTranslations();
            } else {
              setErrorMessage({ id: data.id, message: res.message });
            }
          }
        );
      }
    } else {
      console.error(
        "Warning: error parsing translation:",
        maybeTranslation.justification
      );
    }
  };

  useEffect(() => {
    if (editingRow === undefined) return;
    const row = stateTranslations.find((t) => t.id == editingRow);
    if (row === undefined) return; // Should never happen, makes types play nice

    const keys = new Set<string>();
    row.keys.forEach((t) => {
      const keyLabel = scuTTSKeyLabels.find((v) =>
        v.value.includes(t as unknown as string)
      );
      if (keyLabel) keys.add(keyLabel.key);
      else console.error(`Warning: key '${t}' not accounted for.`);
    });

    reset({
      id: row.id,
      description: row.description ?? undefined,
      keys: [...keys],
      match: (row as TranslationReplace).match,
      replace:
        row.type === TranslationType.replace
          ? (row as TranslationReplace).replace
          : undefined,
      phoneme:
        row.type === TranslationType.phoneme
          ? (row as TranslationPhoneme).phoneme
          : undefined,
    });
  }, [editingRow]);

  useEffect(() => {
    if (searchString === "") {
      setStateTranslations(translations);
      return;
    }
    const s = searchString.trim().toLowerCase();
    setStateTranslations(
      translations.filter((t) => {
        const asReplace = t as TranslationReplace;
        const asPhoneme = t as TranslationPhoneme;
        return (
          t.description?.toLowerCase().includes(s) ||
          t.keys.map((k) => k.toString().toLowerCase()).includes(s) ||
          asReplace.match.toLowerCase().includes(s) ||
          (t.type === TranslationType.replace &&
            asReplace.replace.toLowerCase().includes(s)) ||
          (t.type === TranslationType.phoneme &&
            asPhoneme.phoneme.toLowerCase().includes(s))
        );
      })
    );
  }, [searchString, stage, translations]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", columnGap: "1rem" }}>
          <SemanticLabel label="Translations">
            <Dropdown
              selection
              options={scuTranslations.map((t, idx) => {
                return {
                  text: t.name,
                  value: idx,
                };
              })}
              value={translationSystemIdx}
              onChange={(e, data) =>
                setTranslationSystemIdx(data.value as number)
              }
              style={{
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                borderLeft: "none",
              }}
            />
          </SemanticLabel>
          <Input
            label="Search"
            placeholder="Enter value..."
            onChange={(e, data) => {
              if (searchLoading) clearTimeout(searchLoading);
              setSearchLoading(
                window
                  .setTimeout(() => setSearchLoading(undefined), 1000)
                  .toString()
              );
              setSearchString(data.value);
            }}
            loading={searchLoading !== undefined}
          />
          <Button onClick={() => setActiveRows([])}>Collapse All</Button>
          <Button
            onClick={() => setActiveRows(stateTranslations.map((t) => t.id))}
          >
            Open All
          </Button>
        </div>
        <Button
          disabled={stateTranslations.some((r) => r.id === "")}
          icon="plus"
          labelPosition="right"
          label="Add translation"
          onClick={() => {
            if (isDirty && editingRow !== undefined) {
              setOpenConfirmModal("");
              return;
            }

            setStateTranslations([
              addNewTranslation(stage),
              ...stateTranslations,
            ]);
            setActiveRows(["", ...activeRows]);
            setEditingRow("");
          }}
        />
      </div>
      <Accordion
        style={{
          width: "100%",
          paddingTop: "1.5rem",
        }}
      >
        {stateTranslations
          .filter(
            (row) =>
              row.type ===
              (stage === "phoneme"
                ? TranslationType.phoneme
                : TranslationType.replace)
          )
          .map((row, index) => {
            // Also disable editing if the row has regex turned on -- only support
            // should are able to modify these translations
            const disabled =
              editingRow != row.id ||
              (row.type === TranslationType.replace &&
                (row as TranslationReplace).regex);
            return (
              <React.Fragment key={row.id}>
                <TranslationRow
                  key={row.id}
                  disabled={disabled}
                  getPollyTranslation={getPollyTranslation}
                  index={index}
                  isDirty={isDirty}
                  isEditing={editingRow == row.id}
                  onDelete={() => setOpenDeleteModal(row.id)}
                  onEdit={(e) => {
                    e.preventDefault();
                    if (editingRow !== undefined && isDirty) {
                      setOpenConfirmModal(row.id);
                      return;
                    }
                    setEditingRow(row.id);
                  }}
                  onSubmit={async (e) => {
                    handleSubmit(onSubmit)();
                    e.preventDefault();
                  }}
                  open={activeRows.includes(row.id)}
                  RHF={{ setValue, control, watch }}
                  stage={stage}
                  titleOnClick={() => {
                    if (isDirty && editingRow == row.id) return;
                    if (editingRow == row.id) {
                      setEditingRow(undefined);
                    }
                    // no idea why it thinks this is an unused expression am I crazy?
                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    activeRows.includes(row.id)
                      ? setActiveRows(activeRows.filter((id) => id != row.id))
                      : setActiveRows([...activeRows, row.id]);
                  }}
                  translation={row}
                />
                {errorMessage !== undefined && row.id == errorMessage.id && (
                  <Message
                    negative
                    header="There was an error"
                    content={errorMessage.message}
                  />
                )}
              </React.Fragment>
            );
          })}
        <Confirm
          open={openConfirmModal !== undefined}
          content="Warning: unsaved changes will be erased. Continue?"
          onConfirm={() => {
            if (openConfirmModal === "") {
              setStateTranslations([
                addNewTranslation(stage),
                ...stateTranslations,
              ]);
              setActiveRows(["", ...activeRows]);
            }
            setEditingRow(openConfirmModal);
            setOpenConfirmModal(undefined);
          }}
          onCancel={() => setOpenConfirmModal(undefined)}
        />
        <Confirm
          open={openDeleteModal !== undefined}
          header="Warning"
          content="This action cannot be undone"
          confirmButton={
            <Button
              style={{ backgroundColor: "rgb(219, 40, 40)" }}
              onClick={() => {
                if (openDeleteModal === "") {
                  setStateTranslations(
                    stateTranslations.filter((r) => r.id !== openDeleteModal)
                  );
                  setOpenDeleteModal(undefined);
                } else {
                  deleteTranslation(
                    scuTranslations[translationSystemIdx].id,
                    stage,
                    openDeleteModal!,
                    (res) => {
                      if (res.success) {
                        refetchTranslations();
                      } else {
                        setErrorMessage({
                          id: openDeleteModal!,
                          message: res.message,
                        });
                      }
                    }
                  );
                }
              }}
            >
              Delete
            </Button>
          }
          onCancel={() => setOpenDeleteModal(undefined)}
        />
      </Accordion>
    </div>
  );
};

export const TranslationsTab = ({
  translations,
  addTranslation,
  editTranslation,
  deleteTranslation,
  getPollyTranslation,
  refetchTranslations,
  fetchTranslationResult,
  testTranslationResult,
}: {
  addTranslation: (
    scuTranslationId: string,
    stage: string,
    translation: Translation,
    callback: (result: ApiResult<Translation>) => void
  ) => void;
  editTranslation: (
    scuTranslationId: string,
    stage: string,
    translation: Translation,
    callback: (result: ApiResult<Translation>) => void
  ) => void;
  deleteTranslation: (
    scuTranslationId: string,
    stage: string,
    translationId: string,
    callback: (result: ApiResult<null>) => void
  ) => void;
  getPollyTranslation: (
    text: string,
    callback: (result: ApiResult<PollyResult>) => void
  ) => void;
  refetchTranslations: () => void;
  translations: ScuTranslation[];
  fetchTranslationResult: (raw: string, key: string) => void;
  testTranslationResult:
    | { key: undefined }
    | { key: "loading" }
    | { key: "success"; result: TranslateResult }
    | { key: "error"; message: string };
}) => {
  const panes = [
    {
      menuItem: "Constants",
      render: () => (
        <TabPane style={{ height: "100%" }}>
          <div style={{ padding: "0 1.5rem" }}>
            <Message info icon="info circle" content={constantInfoMessage} />
            <TranslationStage
              stage="constant"
              scuTranslations={translations}
              editTranslation={editTranslation}
              addTranslation={addTranslation}
              deleteTranslation={deleteTranslation}
              getPollyTranslation={getPollyTranslation}
              refetchTranslations={refetchTranslations}
            />
          </div>
        </TabPane>
      ),
    },
    {
      menuItem: "Phonemes",
      render: () => (
        <TabPane style={{ height: "100%" }}>
          <div style={{ padding: "0 1.5rem" }}>
            <Message info icon="info circle" content={phonemeInfoMessage} />
            <TranslationStage
              stage="phoneme"
              scuTranslations={translations}
              editTranslation={editTranslation}
              addTranslation={addTranslation}
              deleteTranslation={deleteTranslation}
              getPollyTranslation={getPollyTranslation}
              refetchTranslations={refetchTranslations}
            />
          </div>
        </TabPane>
      ),
    },
    {
      menuItem: "Test",
      render: () => (
        <TabPane style={{ height: "100%" }}>
          <div style={{ padding: "0 1.5rem" }}>
            <Message info icon="info circle" content={testerInfoMessage} />
            <TranslationTest
              fetchTranslationResult={fetchTranslationResult}
              testTranslationResult={testTranslationResult}
              getPollyTranslation={getPollyTranslation}
            />
          </div>
        </TabPane>
      ),
    },
  ];
  return <Tab panes={panes} style={{ height: "100%" }}></Tab>;
};
