import * as React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Button, Dropdown, Input, Message, Popup } from "semantic-ui-react";
import { SemanticLabel } from "./translationsTab";
import {
  PollyResult,
  TranslateResult,
} from "@bryxinc/lunch/utils/ManagementApi/AgencyMixin";
import { useState } from "react";
import { scuTTSKeyLabels } from "./translationRow";
import { ApiResult } from "@bryxinc/lunch/models";

type TestFormData = {
  input: string;
  key: string;
};

export const TranslationTest = ({
  fetchTranslationResult,
  getPollyTranslation,
  testTranslationResult,
}: {
  fetchTranslationResult: (raw: string, key: string) => void;
  getPollyTranslation: (
    text: string,
    callback: (result: ApiResult<PollyResult>) => void
  ) => void;
  testTranslationResult:
    | { key: undefined }
    | { key: "loading" }
    | { key: "success"; result: TranslateResult }
    | { key: "error"; message: string };
}) => {
  const [pollyRateLimit, setPollyRateLimit] = useState(false);
  const {
    handleSubmit,
    setValue,
    control,
    formState: { dirtyFields },
  } = useForm<TestFormData>({ defaultValues: { input: "", key: undefined } });

  const onSubmit: SubmitHandler<TestFormData> = (data, e) => {
    e?.preventDefault();
    fetchTranslationResult(data.input, data.key);
  };

  return (
    <div style={{ margin: "auto", width: "45%", minWidth: "20rem" }}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: "flex", flexDirection: "column", rowGap: ".5rem" }}
      >
        <Controller
          name="input"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Input
              value={value}
              label="Input"
              placeholder="Enter text..."
              onChange={onChange}
            />
          )}
        />
        <SemanticLabel label="Keys">
          <Controller
            name="key"
            control={control}
            render={({ field }) => (
              <Dropdown
                {...field}
                value={field.value}
                onChange={(e, data) => {
                  setValue("key", data.value as string, {
                    shouldDirty: true,
                  });
                }}
                selection
                placeholder="Select a key..."
                options={scuTTSKeyLabels.map((k) => {
                  return {
                    ...k,
                    value: k.key,
                  };
                })}
                style={{
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  borderLeft: "none",
                  flex: 4,
                }}
              />
            )}
          />
        </SemanticLabel>
        <Button
          type="submit"
          disabled={Object.values(dirtyFields).length !== 2}
          style={{ marginRight: 0 }}
        >
          Test Translation
        </Button>
        {testTranslationResult.key == "success" && (
          <>
            <Message
              info
              header="Translation Results"
              content={testTranslationResult.result.translation}
            />
            <Popup
              content="You must wait 5 seconds between TTS requests"
              disabled={!pollyRateLimit}
              position="bottom center"
              trigger={
                <span style={{ width: "100%" }}>
                  <Button
                    type="button"
                    disabled={pollyRateLimit}
                    style={{ width: "100%", marginRight: 0 }}
                    onClick={() => {
                      getPollyTranslation(
                        testTranslationResult.result.translation,
                        async (res) => {
                          if (res.success) {
                            try {
                              new Audio(res.value.pollyUrl).play();
                              setPollyRateLimit(true);
                              setTimeout(() => setPollyRateLimit(false), 5000);
                            } catch (e) {
                              console.error("Error processing audio:", e);
                            }
                          }
                        }
                      );
                    }}
                  >
                    Test Polly
                  </Button>
                </span>
              }
            />
          </>
        )}
        {testTranslationResult.key == "error" && (
          <Message
            error
            header="Translation Results"
            content={testTranslationResult.message}
          />
        )}
      </form>
    </div>
  );
};
