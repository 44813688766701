import * as React from 'react';
import {Button, Form, Header, Input, InputProps, Message, Modal} from 'semantic-ui-react';
import Recaptcha from "react-recaptcha";
import {ApiResult} from '@bryxinc/lunch/models';
import BryxApi from '@bryxinc/lunch/utils/ManagementApi';
import { nullIfBlank} from '@bryxinc/lunch/utils/functions';

import {withContext, WithTranslation, WithLocal, WithApi} from '@bryxinc/lunch/context';
import {RouteComponentProps} from "react-router";

interface ForgotPasswordModalProps extends RouteComponentProps, WithTranslation, WithLocal, WithApi<BryxApi> {
    open: boolean;
    email: string | null;
    onEmailChange: (newEmail: string) => void;
    onCancel: () => void;
    onSuccess: () => void;
}

interface ForgotPasswordModalState {
    status: { key: "ready" } | { key: "loading" } | { key: "error", message: string };
    captchaResponse: string | null;
}

export class ForgotPasswordModal extends React.Component<ForgotPasswordModalProps, ForgotPasswordModalState> {
    private static readonly CAPTCHA_SITE_KEY = "6LeX7jQUAAAAAD_MbdOuRz7zCesILqiKLWQOkgus";
    private recaptchaInstance: Recaptcha | null = null;

    constructor(props: ForgotPasswordModalProps) {
        super(props);

        this.state = {
            status: {key: "ready"},
            captchaResponse: null,
        };
    }

    private onEmailChange(_: React.ChangeEvent<HTMLInputElement>, inputProps: InputProps) {
        this.props.onEmailChange(inputProps.value);
    }

    private sendForgotPassword(): void {
        if (this.props.email == null || this.state.captchaResponse == null) {
            return;
        }

        this.setState({
            status: {key: "loading"},
        });

        this.props.api.forgotPassword(this.props.email, this.state.captchaResponse, (result: ApiResult<null>) => {
            if (result.success == true) {
                this.props.local.logInfo("User successfully sent forgot password email");
                this.setState({
                    status: {key: "ready"},
                });
                this.props.onSuccess();
            } else {
                this.props.local.logWarn(`Failed to send forgot password email: ${result.debugMessage}`);
                if (this.recaptchaInstance != null) {
                    this.recaptchaInstance.reset();
                }
                this.setState({
                    status: {
                        key: "error",
                        message: result.message,
                    },
                });
            }
        });
    }

    render() {
        return (
            <Modal size="tiny"
                   open={this.props.open}
                   onClose={this.props.onCancel}>
                <Header icon="question circle" content={this.props.t("login.forgotPassword")}/>

                <Modal.Content>
                    <Form onSubmit={e => {
                        e.preventDefault();
                        this.sendForgotPassword();
                    }}>
                        <Form.Field>
                            <Input autoFocus
                                   type="email"
                                   placeholder={this.props.t("login.email")}
                                   disabled={this.state.status.key == "loading"}
                                   value={this.props.email}
                                   onChange={this.onEmailChange.bind(this)}/>
                        </Form.Field>
                    </Form>
                    <div style={{marginTop: "20px", display: "flex", justifyContent: "center"}}>
                        <Recaptcha ref={e => this.recaptchaInstance = e}
                                   sitekey={ForgotPasswordModal.CAPTCHA_SITE_KEY}
                                   verifyCallback={response => this.setState({
                                       captchaResponse: response,
                                   })}/>
                    </div>
                    {this.state.status.key == "error" && (
                        <Message error>
                            {this.state.status.message}
                        </Message>
                    )}
                </Modal.Content>

                <Modal.Actions>
                    <Button disabled={this.state.status.key == "loading"}
                            type="button"
                            onClick={this.props.onCancel}>
                        {this.props.t("general.cancel")}
                    </Button>
                    <Button primary
                            type="button"
                            onClick={this.sendForgotPassword.bind(this)}
                            disabled={nullIfBlank(this.props.email) == null || this.state.captchaResponse == null}
                            loading={this.state.status.key == "loading"}>
                        {this.props.t("general.submit")}
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default withContext(ForgotPasswordModal, 'api', 'local', 'i18n');
