import BryxApi from "@bryxinc/lunch/utils/ManagementApi";
import {BryxLocalStorage} from "@bryxinc/lunch/utils";
import { ApiResult } from "@bryxinc/lunch/models";

export type BadgeStatus =
    | { key: "loading" }
    | { key: "ready"; count: number }
    | { key: "error"; message: string };

export interface BadgeManagerObserver {
    badgeManagerDidChangeBadgeStatus(): void;
}

export class BadgeManager {
    private observers: BadgeManagerObserver[] = [];

    private api: BryxApi;
    private local: BryxLocalStorage;

    constructor(api: BryxApi, local: BryxLocalStorage) {
        this.local = local;
        this.api = api;
    }

    private timerId: number | null = null;
    private currentAgency: string | null = null;

    public pendingJoinRequestStatus: BadgeStatus = {key: "loading"};
    private readonly joinRequestIntervalLength = 5 * 60 * 1000; // 5 Minutes

    private notifyAll() {
        this.observers.forEach((o) => o.badgeManagerDidChangeBadgeStatus());
    }

    public startupJoinRequestBadger(agencyId: string) {
        if (this.timerId != null) {
            clearInterval(this.timerId);
        }

        this.currentAgency = agencyId;

        this.reloadJoinRequestCount();
        this.timerId = window.setInterval(() => {
            this.reloadJoinRequestCount();
        }, this.joinRequestIntervalLength);
    }

    private reloadJoinRequestCount() {
        if (this.currentAgency != null) {
            this.api.getPendingJoinRequestCount(this.currentAgency, (result: ApiResult<number>) => {
                if (result.success == true) {
                    this.pendingJoinRequestStatus = {key: "ready", count: result.value};
                } else {
                    this.local.logWarn(
                        `Failed to load pending join requests: ${
                            result.debugMessage || result.message
                        }`,
                    );
                    this.pendingJoinRequestStatus = {
                        key: "error",
                        message: result.message,
                    };
                }
                this.notifyAll();
            });
        }
    }

    public updateJoinRequestCount(count: number) {
        this.pendingJoinRequestStatus = {key: "ready", count: count};
        this.notifyAll();
    }

    public registerObserver(observer: BadgeManagerObserver) {
        if (this.observers.filter((o) => o === observer).length == 0) {
            this.observers.push(observer);
        }
    }

    public unregisterObserver(observer: BadgeManagerObserver) {
        const observerIndex = this.observers.indexOf(observer);
        if (observerIndex != -1) {
            this.observers.splice(observerIndex, 1);
        }
    }
}
