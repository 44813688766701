import * as React from "react";
import {Button, List, Message, Modal} from "semantic-ui-react";
import {AuthAgency, FullUser} from "@bryxinc/lunch/models";

import {RouteComponentProps} from "react-router";
import BryxApi from "@bryxinc/lunch/utils/ManagementApi";
import {
    withContext,
    WithTranslation,
    WithLocal,
    WithApi,
} from "@bryxinc/lunch/context";

export type ChangeManagerStatusModalViewStatus =
    | { key: "hidden" }
    | { key: "shown"; agency: AuthAgency; user: FullUser };

interface ChangeManagerStatusModalProps
    extends RouteComponentProps,
        WithTranslation,
        WithLocal,
        WithApi<BryxApi> {
    viewStatus: ChangeManagerStatusModalViewStatus;
    onConfirm: (isManager: boolean) => void;
    onClose: () => void;
}

interface ChangeManagerStatusModalState {
    status:
        | { key: "ready" }
        | { key: "loading" }
        | { key: "success" }
        | { key: "error"; message: string };
}

export class ChangeManagerStatusModal extends React.Component<ChangeManagerStatusModalProps,
    ChangeManagerStatusModalState> {
    constructor(props: ChangeManagerStatusModalProps) {
        super(props);
        this.state = ChangeManagerStatusModal.getInitialState();
    }

    private static getInitialState(): ChangeManagerStatusModalState {
        return {
            status: {key: "ready"},
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: ChangeManagerStatusModalProps) {
        if (
            this.props.viewStatus.key == "hidden" &&
            nextProps.viewStatus.key == "shown"
        ) {
            this.setState(ChangeManagerStatusModal.getInitialState());
        }
    }

    private onSubmit() {
        const {status} = this.state;
        const {viewStatus} = this.props;
        if (status.key == "loading" || viewStatus.key != "shown") {
            return;
        }
        this.setState({status: {key: "loading"}});
        if (viewStatus.user.isManager) {
            this.props.api.demoteManagerToUser(
                viewStatus.agency.id,
                viewStatus.user.id,
                (result) => {
                    if (result.success == true) {
                        this.props.onConfirm(false);
                    } else {
                        this.props.local.logWarn(
                            `Failed to demote manager to user: ${
                                result.debugMessage || result.message
                            }`,
                        );
                        this.setState({
                            status: {key: "error", message: result.message},
                        });
                    }
                },
            );
        } else {
            this.props.api.promoteUserToManager(
                viewStatus.agency.id,
                viewStatus.user.id,
                (result) => {
                    if (result.success == true) {
                        this.props.onConfirm(true);
                    } else {
                        this.props.local.logWarn(
                            `Failed to promote user to manager: ${
                                result.debugMessage || result.message
                            }`,
                        );
                        this.setState({
                            status: {key: "error", message: result.message},
                        });
                    }
                },
            );
        }
    }

    private renderModalContent(): JSX.Element | null {
        const {viewStatus} = this.props;
        const {status} = this.state;
        if (viewStatus.key == "hidden") {
            return <Modal.Content/>;
        }

        const managerAbilityList = (
            <List bulleted>
                <List.Item>
                    {this.props.t(
                        "members.users.changeManagerStatusModal.managerAbilities.users",
                    )}
                </List.Item>
                <List.Item>
                    {this.props.t(
                        "members.users.changeManagerStatusModal.managerAbilities.joinRequests",
                    )}
                </List.Item>
                <List.Item>
                    {this.props.t(
                        "members.users.changeManagerStatusModal.managerAbilities.groups",
                    )}
                </List.Item>
                <List.Item>
                    {this.props.t(
                        "members.users.changeManagerStatusModal.managerAbilities.agencySettings",
                    )}
                </List.Item>
                <List.Item>
                    {this.props.t(
                        "members.users.changeManagerStatusModal.managerAbilities.editManagers",
                    )}
                </List.Item>
                <List.Item>
                    {this.props.t(
                        "members.users.changeManagerStatusModal.managerAbilities.createJobs",
                    )}
                </List.Item>
            </List>
        );

        const isPromote = !viewStatus.user.isManager;
        return (
            <Modal.Content>
                <Message info={isPromote} warning={!isPromote}>
                    <Message.Content>
                        <Message.Header style={{marginBottom: "10px"}}>
                            {isPromote
                                ? this.props.t(
                                    "members.users.changeManagerStatusModal.promoteAreYouSure",
                                    {replace: {user: viewStatus.user.commonName}},
                                )
                                : this.props.t(
                                    "members.users.changeManagerStatusModal.demoteAreYouSure",
                                    {replace: {user: viewStatus.user.commonName}},
                                )}
                        </Message.Header>
                        <span>
              {isPromote
                  ? this.props.t(
                      "members.users.changeManagerStatusModal.promoteAbility",
                      {replace: {user: viewStatus.user.commonName}},
                  )
                  : this.props.t(
                      "members.users.changeManagerStatusModal.demoteAbility",
                      {replace: {user: viewStatus.user.commonName}},
                  )}
            </span>
                        {managerAbilityList}
                    </Message.Content>
                </Message>
                {status.key == "error" ? (
                    <Message error content={status.message}/>
                ) : null}
            </Modal.Content>
        );
    }

    private renderHeader(): JSX.Element | null {
        const {viewStatus} = this.props;
        if (viewStatus.key == "hidden") {
            return <Modal.Header/>;
        }

        return (
            <Modal.Header>
                {this.props.t(
                    `members.users.changeManagerStatusModal.${
                        viewStatus.user.isManager ? "titleDemote" : "titlePromote"
                    }`,
                )}
            </Modal.Header>
        );
    }

    private renderButtonContent(): string | null {
        const {viewStatus} = this.props;
        if (viewStatus.key == "hidden") {
            return null;
        }

        return this.props.t(
            `members.users.changeManagerStatusModal.${
                viewStatus.user.isManager ? "actionDemote" : "actionPromote"
            }`,
        );
    }

    render() {
        const {viewStatus} = this.props;
        const {status} = this.state;
        return (
            <Modal
                size="small"
                open={viewStatus.key == "shown"}
                onClose={this.props.onClose}
            >
                {this.renderHeader()}
                {this.renderModalContent()}
                <Modal.Actions>
                    <Button
                        content={this.props.t("general.cancel")}
                        disabled={status.key == "loading"}
                        onClick={this.props.onClose}
                    />
                    <Button
                        primary={viewStatus.key == "shown" && !viewStatus.user.isManager}
                        negative={viewStatus.key == "shown" && viewStatus.user.isManager}
                        loading={status.key == "loading"}
                        content={this.renderButtonContent()}
                        onClick={this.onSubmit.bind(this)}
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}

export default withContext(ChangeManagerStatusModal, "api", "local", "i18n");
