import * as React from "react";
import { useConstCallback } from "powerhooks";
import { useRef, useState } from "react";
import { Form } from "semantic-ui-react";
import { useTranslation } from "@bryxinc/lunch/context";

export type Location = {
  street: string;
  city: string;
  state: string;
};

export type LocationSearchProps = {
  onSearch: () => Promise<unknown>;
  tall: boolean;
} & SearchOptionals;
type SearchKnown = {
  onUpdate: (location: Location) => unknown;
  location: Location;
};
type SearchOptionals =
  | SearchKnown
  | { onUpdate: undefined; location: undefined };

function buildState({ location, onUpdate }: SearchOptionals): SearchKnown {
  if (!onUpdate) {
    const [location, setLocation] = useState({
      street: "",
      city: "",
      state: "",
    });
    const onUpdate = useConstCallback((location) => {
      setLocation(location);
    });
    return { location, onUpdate };
  } else {
    return { location, onUpdate };
  }
}

export function LocationSearch({
  onSearch,
  onUpdate: onUpdateDummy,
  location: locationDummy,
  tall,
}: LocationSearchProps) {
  const { t } = useTranslation();
  // This prop should never ever change or we'll get a renderer crash!
  const { location, onUpdate } = buildState(
    // This is so dumb, typescript why don't you infer this!
    locationDummy
      ? {
        location: locationDummy,
        onUpdate: onUpdateDummy,
      }
      : { location: undefined, onUpdate: undefined }
  );
  const [loading, setLoading] = useState(false);
  const marker = useRef({});
  const broadStrokes = (
    <>
      <Form.Input
        width={tall ? 10 : 7}
        label={t("siteSurvey.siteSurveySearch.city")}
        value={location.city}
        onChange={useConstCallback((_event, { value }) => {
          onUpdate({ ...location, city: value });
        })}
      />
      <Form.Input
        width={tall ? 4 : 2}
        label={t("siteSurvey.siteSurveySearch.state")}
        value={location.state}
        onChange={useConstCallback((_event, { value }) => {
          onUpdate({ ...location, state: value });
        })}
      />
      <Form.Button
        label={"\u{a0}"}
        width={tall ? 1 : undefined}
        icon="search"
        onClick={useConstCallback(() => {
          marker.current = {};
          const ourMarker = marker.current;
          setLoading(true);
          onSearch()
            .then(() => {
              if (marker.current === ourMarker) {
                setLoading(false);
              }
            })
            .catch(() => {
              if (marker.current === ourMarker) {
                setLoading(false);
              }
            });
        })}
        disabled={!location.state || !location.city || !location.street}
        loading={loading}
      />
    </>
  );
  const street = (
    <Form.Input
      width={tall ? 16 : 7}
      label={t("siteSurvey.siteSurveySearch.street")}
      value={location.street}
      onChange={useConstCallback((_event, { value }) => {
        onUpdate({ ...location, street: value });
      })}
    />
  );

  if (tall) {
    return (
      <div>
        {street}
        <Form.Group style={{ display: "flex" }}>{broadStrokes}</Form.Group>
      </div>
    );
  } else {
    return (
      <Form.Group style={{ display: "flex" }}>
        {street}
        {broadStrokes}
      </Form.Group>
    );
  }
}
