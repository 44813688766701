import * as React from "react";
import {Button, Checkbox, Header, Popup, Table} from "semantic-ui-react";
import {PaginatedTable} from "../../components/paginatedTable";
import {ZeroStateView} from "../../components/zeroStateView";
import {
    ApiResult,
    AuthAgency,
    PageResult,
    SortingConfig,
    JoinRequest,
    JoinRequestSortingOptions,
    JoinRequestStatusEnum,
} from "@bryxinc/lunch/models";

import {badgeManager} from "../../index";

import * as colors from "@bryxinc/style/color";
import {
    RespondToJoinRequestModal,
    RespondToJoinRequestViewStatus,
} from "./respondToJoinRequestModal";

import {RouteComponentProps} from "react-router";
import BryxApi from "@bryxinc/lunch/utils/ManagementApi";
import {
    withContext,
    WithTranslation,
    WithLocal,
    WithApi,
} from "@bryxinc/lunch/context";

export interface JoinRequestItemStatus {
    joinRequest: JoinRequest;
    selected: boolean;
}

interface JoinRequestsTabState {
    items: JoinRequestItemStatus[];
    respondModalStatus: RespondToJoinRequestViewStatus;
}

export interface JoinRequestsTabProps
    extends RouteComponentProps,
        WithTranslation,
        WithLocal,
        WithApi<BryxApi> {
    selectedAgency: AuthAgency;
}

export class JoinRequestsTab extends React.Component<JoinRequestsTabProps,
    JoinRequestsTabState> {
    private paginatedTable: PaginatedTable<JoinRequest> | null = null;

    private static readonly maxMessageLength = 100;

    constructor(props: JoinRequestsTabProps) {
        super(props);

        this.state = {
            items: [],
            respondModalStatus: {key: "hidden"},
        };
    }

    private loadItems(
        limit: number,
        activePage: number,
        searchString: string | null,
        sortConfig: SortingConfig<JoinRequestSortingOptions>,
        callback: (result: ApiResult<PageResult<JoinRequest>>) => void,
    ) {
        const wrappedCallback = (result: ApiResult<PageResult<JoinRequest>>) => {
            if (result.success == true) {
                const currentlySelected = this.state.items
                    .filter((i) => i.selected)
                    .map((i) => i.joinRequest.id);
                const newItems = result.value.items.map((request: JoinRequest) => {
                    return {
                        joinRequest: request,
                        selected: request.id in currentlySelected,
                    };
                });

                badgeManager.updateJoinRequestCount(result.value.count);

                this.setState({
                    items: newItems,
                });
            }
            callback(result);
        };

        this.props.api.getJoinRequests(
            this.props.selectedAgency.id,
            [JoinRequestStatusEnum.pending],
            limit,
            activePage,
            searchString,
            sortConfig,
            wrappedCallback,
        );
    }

    private closeModals() {
        this.setState({
            respondModalStatus: {key: "hidden"},
        });
    }

    private onClickJoinRequest(request: JoinRequest) {
        this.setState((prevState) => {
            const selectedItem = this.state.items.filter(
                (i) => i.joinRequest.id == request.id,
            )[0];

            if (selectedItem != undefined) {
                selectedItem.selected = !selectedItem.selected;
            }

            return prevState;
        });
    }

    private respondOnClick(type: "approve" | "deny") {
        const selectedItems = this.state.items.filter((i) => i.selected);
        this.setState({
            respondModalStatus: {
                key: "shown",
                type: type,
                joinRequests: selectedItems
                    .sort((r1, r2) => JoinRequest.compare(r1.joinRequest, r2.joinRequest))
                    .map((r) => r.joinRequest),
            },
        });
    }

    render() {
        const {items} = this.state;
        const selectedItems = items.filter((i) => i.selected);

        const requestsTable = (
            <PaginatedTable
                {...this.props}
                ref={(r) => (this.paginatedTable = r)}
                className="underHorizNavContent tableUnderNavContent"
                uniqueKey="members.join-requests"
                rightItem={null}
                selectable
                fixed
                footer={{
                    leftActions: [],
                    rightActions: [
                        <Header
                            key="join-requests.selected"
                            as="h4"
                            disabled={selectedItems.length == 0}
                            style={{marginBottom: 0, marginRight: "10px"}}
                        >
                            {this.props.t(`members.join-requests.xSelectedRequests`, {
                                count: selectedItems.length,
                            })}
                        </Header>,
                        <Button
                            key="join-requests.approve"
                            primary
                            style={{marginRight: "5px", width: "100px"}}
                            disabled={selectedItems.length == 0}
                            content={this.props.t("members.join-requests.approve")}
                            onClick={() => this.respondOnClick("approve")}
                        />,
                        <Button
                            key="join-requests.deny"
                            negative
                            style={{marginLeft: "5px", width: "100px"}}
                            disabled={selectedItems.length == 0}
                            content={this.props.t("members.join-requests.deny")}
                            onClick={() => this.respondOnClick("deny")}
                        />,
                    ],
                }}
                multiselect={{
                    selectAllAction: (value: boolean) => {
                        this.setState((prevState) => {
                            prevState.items.forEach((i) => (i.selected = value));
                            return prevState;
                        });
                    },
                    allSelected:
                        items.length > 0 && items.every((i) => i.selected == true),
                }}
                loader={{
                    loadId: this.props.selectedAgency.id,
                    loadItems: this.loadItems.bind(this),
                }}
                headerDataItems={[
                    {
                        i18nKey: `members.join-requests.sender`,
                        width: "4",
                        headerKey: "sender",
                    },
                    {
                        i18nKey: `members.join-requests.message`,
                        width: "4",
                        headerKey: "message",
                    },
                    {
                        i18nKey: `members.join-requests.groupName`,
                        width: "4",
                        headerKey: "groupName",
                    },
                    {
                        i18nKey: `members.join-requests.created`,
                        width: "3",
                        headerKey: "created",
                    },
                ]}
                zeroStateView={
                    <div className="flexCenteredContainer">
                        <ZeroStateView
                            header={this.props.t("members.join-requests.zeroStateHeader")}
                            subheader={this.props.t(
                                "members.join-requests.zeroStateSubheader",
                            )}
                        />
                    </div>
                }
                renderItem={(request: JoinRequest) => {
                    const requestItemStatus = items.filter(
                        (i) => i.joinRequest.id == request.id,
                    );

                    const messageItem =
                        request.message.length > JoinRequestsTab.maxMessageLength ? (
                            <Popup
                                on="hover"
                                inverted
                                position="bottom left"
                                trigger={
                                    <span>
                    {request.message.slice(0, JoinRequestsTab.maxMessageLength)}
                                        ...
                  </span>
                                }
                                content={request.message}
                            />
                        ) : (
                            request.message
                        );

                    return (
                        <Table.Row
                            key={request.id}
                            style={{cursor: "pointer", height: "80px"}}
                            onClick={() => this.onClickJoinRequest(request)}
                        >
                            <Table.Cell style={{textAlign: "center"}} collapsing>
                                <Checkbox
                                    checked={
                                        requestItemStatus.length > 0
                                            ? requestItemStatus[0].selected
                                            : false
                                    }
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <div>
                                    <div style={{marginBottom: "3px", fontWeight: 600}}>
                                        {request.requestingClient.commonName}
                                    </div>
                                    <div style={{color: colors.gray}}>
                                        {request.requestingClient.email}
                                    </div>
                                </div>
                            </Table.Cell>
                            <Table.Cell>{messageItem}</Table.Cell>
                            <Table.Cell singleLine>{request.unit.name}</Table.Cell>
                            <Table.Cell>
                                <Popup
                                    on="hover"
                                    inverted
                                    position="bottom left"
                                    trigger={<span>{request.creationTs.toLocaleString()}</span>}
                                    content={
                                        <div>
                                            <div style={{marginBottom: "5px"}}>
                                                {this.props.t("members.join-requests.creationTsPopup", {
                                                    replace: {
                                                        time: request.creationTs.toLocaleString(),
                                                    },
                                                })}
                                            </div>
                                            <div>
                                                {this.props.t(
                                                    "members.join-requests.expirationTsPopup",
                                                    {
                                                        replace: {
                                                            time: request.expirationTs.toLocaleString(),
                                                        },
                                                    },
                                                )}
                                            </div>
                                        </div>
                                    }
                                />
                            </Table.Cell>
                        </Table.Row>
                    );
                }}
                defaultSorting={{
                    column: "creation",
                    direction: "desc",
                }}
            />
        );

        return (
            <div style={{display: "flex", flex: 1}}>
                {requestsTable}
                <RespondToJoinRequestModal
                    {...this.props}
                    agencyId={this.props.selectedAgency.id}
                    viewStatus={this.state.respondModalStatus}
                    onComplete={() => {
                        this.closeModals();
                        if (this.paginatedTable != null) {
                            this.paginatedTable.reload();
                        }
                        badgeManager.startupJoinRequestBadger(this.props.selectedAgency.id);
                    }}
                    onCancel={() => this.closeModals()}
                />
            </div>
        );
    }
}

export default withContext(JoinRequestsTab, "api", "local", "i18n");
