import {Button, Header, Icon, List} from "semantic-ui-react";
import * as React from "react";
import {WithTranslation} from "@bryxinc/lunch/context";

export const StationAlertingZeroState = ({ t }: WithTranslation) => (
    <div
        className="flexCenteredContainer"
        style={{width: "100%", height: "100%"}}
    >
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            <Header as="h2" icon>
                <Icon name="server"/>
                {t("stationAlerting.controlUnits.zeroState.header")}
                <Header.Subheader>
                    {t(
                        "stationAlerting.controlUnits.zeroState.subheader",
                    )}
                </Header.Subheader>
            </Header>
            <List bulleted>
                <List.Item>
                    {t(
                        "stationAlerting.controlUnits.zeroState.productBullets.1",
                    )}
                </List.Item>
                <List.Item>
                    {t(
                        "stationAlerting.controlUnits.zeroState.productBullets.2",
                    )}
                </List.Item>
                <List.Item>
                    {t(
                        "stationAlerting.controlUnits.zeroState.productBullets.3",
                    )}
                </List.Item>
                <List.Item>
                    {t(
                        "stationAlerting.controlUnits.zeroState.productBullets.4",
                    )}
                </List.Item>
                <List.Item>
                    {t(
                        "stationAlerting.controlUnits.zeroState.productBullets.5",
                    )}
                </List.Item>
            </List>
            <Button
                positive
                fluid
                target="_blank"
                content={t(
                    "stationAlerting.controlUnits.zeroState.learnMore",
                )}
                href="https://bryx.com/products/station-alerting/"
            />
        </div>
    </div>
);