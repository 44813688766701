import * as React from "react";
import {
    Button,
    Form,
    InputOnChangeData,
    List,
    Message,
    Modal,
} from "semantic-ui-react";
import {AgencyGroup} from "@bryxinc/lunch/models";
import {nullIfBlank} from "@bryxinc/lunch/utils/functions";
import BryxApi from "@bryxinc/lunch/utils/ManagementApi";
import {RouteComponentProps} from "react-router";
import {
    withContext,
    WithTranslation,
    WithLocal,
    WithApi,
} from "@bryxinc/lunch/context";

export type CreateGroupModalViewStatus = { key: "hidden" } | { key: "shown" };
export type CreateGroupAfterAction = "none" | "addAllMembers" | "viewMembers";

interface CreateGroupModalProps
    extends RouteComponentProps,
        WithTranslation,
        WithLocal,
        WithApi<BryxApi> {
    agencyId: string;

    onDismiss(): void;

    onSuccessfulCreate(
        afterAction: CreateGroupAfterAction,
        group: AgencyGroup,
    ): void;

    viewStatus: CreateGroupModalViewStatus;
}

interface CreateGroupModalState {
    actionStatus:
        | { key: "loading" }
        | { key: "ready" }
        | { key: "error"; message: string }
        | { key: "success"; group: AgencyGroup };
    nameField: string | null;
}

export class CreateGroupModal extends React.Component<CreateGroupModalProps,
    CreateGroupModalState> {
    constructor(props: CreateGroupModalProps) {
        super(props);

        this.state = CreateGroupModal.getInitialState();
    }

    static getInitialState(): CreateGroupModalState {
        return {
            actionStatus: {key: "ready"},
            nameField: null,
        };
    }

    UNSAFE_componentWillReceiveProps(newProps: CreateGroupModalProps) {
        if (
            this.props.viewStatus.key == "hidden" &&
            newProps.viewStatus.key == "shown"
        ) {
            // Opening modal
            this.setState(CreateGroupModal.getInitialState());
        }
    }

    private createGroup() {
        const {viewStatus} = this.props;
        const {nameField} = this.state;

        if (viewStatus.key == "shown" && nameField != null) {
            this.setState({
                actionStatus: {key: "loading"},
            });

            this.props.api.createMessagingGroup(
                this.props.agencyId,
                nameField,
                (result) => {
                    if (result.success == true) {
                        this.setState({
                            actionStatus: {key: "success", group: result.value},
                        });
                    } else {
                        this.props.local.logWarn(
                            `Failed to create messaging group: ${result.message}`,
                        );
                        this.setState({
                            actionStatus: {key: "error", message: result.message},
                        });
                    }
                },
            );
        }
    }

    private onCreate() {
        if (this.state.nameField != null) {
            this.createGroup();
        }
    }

    private onChangeNameField(e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) {
        this.setState({nameField: nullIfBlank(data.value)});
    }

    render() {
        const {onDismiss, viewStatus} = this.props;
        const {actionStatus, nameField} = this.state;

        const content =
            actionStatus.key == "success" ? (
                <Message success>
                    <Message.Header>
                        {this.props.t("groups.createGroup.success.header", {
                            replace: {groupName: actionStatus.group.name},
                        })}
                    </Message.Header>
                    <Message.Content style={{marginTop: "5px"}}>
                        {this.props.t("groups.createGroup.success.explain")}
                        <List bulleted style={{marginTop: "10px"}}>
                            <List.Item>
                                <a
                                    onClick={() =>
                                        this.props.onSuccessfulCreate(
                                            "addAllMembers",
                                            actionStatus.group,
                                        )
                                    }
                                >
                                    {this.props.t("groups.createGroup.success.addAll")}
                                </a>
                            </List.Item>
                            <List.Item>
                                <a
                                    onClick={() =>
                                        this.props.onSuccessfulCreate(
                                            "viewMembers",
                                            actionStatus.group,
                                        )
                                    }
                                >
                                    {this.props.t("groups.createGroup.success.addSpecific")}
                                </a>
                            </List.Item>
                        </List>
                    </Message.Content>
                </Message>
            ) : (
                <div>
                    <Form onSubmit={this.onCreate.bind(this)}>
                        <Form.Input
                            autoFocus
                            loading={actionStatus.key == "loading"}
                            content={this.state.nameField}
                            label={this.props.t("groups.createGroup.nameInputLabel")}
                            placeholder={this.props.t("groups.createGroup.fieldPlaceholder")}
                            onChange={this.onChangeNameField.bind(this)}
                        />
                    </Form>
                    {actionStatus.key == "error" ? (
                        <Message error content={actionStatus.message}/>
                    ) : undefined}
                </div>
            );

        const addButton =
            actionStatus.key != "success" ? (
                <Button
                    positive
                    disabled={nameField == null}
                    content={this.props.t("groups.createGroup.create")}
                    onClick={this.onCreate.bind(this)}
                />
            ) : undefined;

        const closeButton =
            actionStatus.key != "success" ? (
                <Button content={this.props.t("general.cancel")} onClick={onDismiss}/>
            ) : (
                <Button
                    content={this.props.t("general.close")}
                    onClick={() =>
                        this.props.onSuccessfulCreate("none", actionStatus.group)
                    }
                />
            );

        return (
            <Modal
                size="tiny"
                open={viewStatus.key == "shown"}
                onClose={
                    actionStatus.key != "success"
                        ? onDismiss
                        : () => this.props.onSuccessfulCreate("none", actionStatus.group)
                }
            >
                <Modal.Header>{this.props.t("groups.createGroup.header")}</Modal.Header>
                <Modal.Content style={{backgroundColor: "#fbfbfb"}}>
                    {content}
                </Modal.Content>
                <Modal.Actions>
                    {closeButton}
                    {addButton}
                </Modal.Actions>
            </Modal>
        );
    }
}

export default withContext(CreateGroupModal, "api", "local", "i18n");
