import * as React from 'react';
import {CSSProperties} from 'react';
import {NavLink} from "react-router-dom";
import {Icon, Label, SemanticICONS} from "semantic-ui-react";

class NavHeader extends React.Component<{children: React.ReactNode}, null> {
    render() {
        return (
            <div id="Nav_SiteName">
                {this.props.children}
            </div>
        );
    }
}

export type NavBadgeOption = { type: "badge" } | { type: "count", count: number };

export interface NavLinkItemProps {
    to: string;
    icon: { type: "img", src: string } | { type: "icon", name: SemanticICONS, badge?: NavBadgeOption };
    content: string;
}

class NavLinkItem extends React.Component<NavLinkItemProps> {
    render() {
        const {icon} = this.props;
        let iconNode;
        if (icon.type == "img") {
            iconNode = <img src={icon.src}/>;
        } else {
            iconNode = icon.badge != null && icon.badge.type == "badge" ? (
                <Icon.Group style={{marginRight: "10px", width: "22px"}}>
                    <Icon name={icon.name}
                          style={{fontSize: "1.2em"}}/>
                    {icon.badge.type == "badge" ?
                        <Icon corner name="circle" color="orange"/>
                        : undefined}
                </Icon.Group>
            ) : (
                <Icon name={icon.name}
                      style={{marginRight: "10px", fontSize: "1.2em", width: "22px"}}/>
            );
        }

        return (
            <NavLink to={this.props.to}
                     style={{display: "flex", justifyContent: "space-between"}}
                     activeClassName="Nav_SelectedLink">
                <div>
                    {iconNode}
                    {this.props.content}
                </div>
                {icon.type != "img" && icon.badge != null && icon.badge.type == "count" ?
                    <Label color="red" size="small" style={{
                        marginRight: "15px",
                        fontSize: "10px",
                        textAlign: "center"
                    }}>{icon.badge.count}</Label> :
                    undefined}
            </NavLink>
        );
    }
}

export interface NavLinkSectionProps {
    content: string;
    style?: CSSProperties;
}

class NavLinkSectionHeader extends React.Component<NavLinkSectionProps> {
    render() {
        return <div className="Nav_LinkSection" style={this.props.style}>{this.props.content}</div>;
    }
}

class NavLinkItemList extends React.Component<{children: React.ReactNode}, null> {
    render() {
        return (
            <div id="Nav_LinkList">
                {this.props.children}
            </div>
        );
    }
}

export interface NavExternalLinkItemProps {
    href: string;
    title: string;
}

class NavExternalLinkItem extends React.Component<NavExternalLinkItemProps> {
    render() {
        return <a target="_blank" href={this.props.href} rel="noreferrer">{this.props.title}</a>;
    }
}

class NavExternalLinkList extends React.Component<{children: React.ReactNode}, null> {
    render() {
        return (
            <div id="Nav_LinkOptions">
                <div id="Nav_Links">
                    {this.props.children}
                </div>
            </div>
        );
    }
}

class NavBody extends React.Component<{children: React.ReactNode}, null> {
    render() {
        return (
            <div id="Nav_Options">
                {this.props.children}
            </div>
        );
    }
}

export class Nav extends React.Component<{children: React.ReactNode}, null> {
    public static Header = NavHeader;
    public static Body = NavBody;
    public static SectionHeader = NavLinkSectionHeader;
    public static ItemList = NavLinkItemList;
    public static Item = NavLinkItem;
    public static ExternalLinkList = NavExternalLinkList;
    public static ExternalLinkItem = NavExternalLinkItem;

    render() {
        return (
            <div id="Nav_SignedIn">
                {this.props.children}
            </div>
        );
    }
}
